export const lineFormatter = (
  selected: HTMLElement,
  target: HTMLElement,
  isResizing: boolean,
): void => {
  if (!selected) return;
  const width = selected.getBoundingClientRect().width;
  const height = selected.getBoundingClientRect().height;
  const left = selected.getBoundingClientRect().left + window.pageXOffset;
  const top = selected.getBoundingClientRect().top + window.pageYOffset;
  target.style.width = `${width}px`;
  target.style.height = `${height}px`;
  target.style.left = `${left}px`;
  target.style.top = `${top}px`;
  target.style.borderColor = isResizing ? "#fff" : "#0089BF";
  target.style.transform = "none";
};
