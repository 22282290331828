/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

const disclaimer = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    display: block;
  }
`;

const copyright = css`
  font-size: 14px;
  color: white;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 2em;

  @media screen and (max-width: 900px) {
    font-size: 12px;
    padding: 0 0 10px 0;
    margin: 0;
  }
`;

const Disclaimer: React.FC = (): JSX.Element => (
  <div css={disclaimer}>
    <p css={copyright}>© myvisiondirectory 2019</p>
  </div>
);

export default Disclaimer;
