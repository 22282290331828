/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";

import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";

import disclaimerStyles from "styles/disclaimers";

// tslint:disable: strict-type-predicates
// tslint:disable: strict-comparisons
// tslint:disable no-trailing-whitespace
// tslint:disable: max-line-length

const PrivacyPolicy = (): JSX.Element => (
  <Fragment>
    <Header />
    <div css={disclaimerStyles.container}>
      <div css={disclaimerStyles.intro}>
        <h1>Privacy Policy</h1>
        <h1>Effective Date: December 1, 2019</h1>
        <p>
          My Vision Directory is an online appointment booking service located at
          myvisiondirectory.com (the &ldquo;Site&rdquo;) which helps you find and book an
          appointment with an optometrist (together with the Site, the &ldquo;Services&rdquo;). In
          this Privacy Policy, we&rsquo;ll refer to the optometrists as &ldquo;Eye Doctors.&rdquo;
        </p>
        <p>
          Your use of the Services is subject to this Privacy Policy and the{" "}
          <a rel="noopener noreferrer" target="_blank" href="/disclaimer/terms-of-use">
            Terms of Use
          </a>
          . Our use of any individually identifiable health information you provide is subject to
          the requirements of the Health Insurance Portability and Accountability Act of 1996
          (&ldquo;HIPAA&rdquo;). In addition to this Privacy Policy, our{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="/disclaimer/notice-of-privacy-practices"
          >
            {" "}
            Notice of Privacy Practices
          </a>{" "}
          will apply to such information.
        </p>
        <p>
          By using the Services, you agree to the collection, use, disclosure, and procedures that
          this Privacy Policy describes. Please note that this Privacy Policy does not apply to
          third party websites (&ldquo;Third Party Services&rdquo;) that we do not own or control,
          even if you access them through our Services. We try to work only with Third Party
          Services that share our respect for your privacy, but we cannot take responsibility for
          their practices, content, or privacy policies. We encourage you to carefully review the
          privacy policies of any Third Party Services you access so that you know how they will
          collect, use, and share your information.
        </p>
        <p>
          If you have any questions, please reach out to us at{" "}
          <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>.
        </p>
      </div>
      <div css={disclaimerStyles.numbered}>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>1. What Information does My Vision Directory Collect?</h2>
          <div css={disclaimerStyles.subSection}>
            <h3>Information you provide us:</h3>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>
                  <strong>When you book an appointment or otherwise use the Services.</strong> If
                  you book an appointment using the Services, we request information from you to
                  facilitate the transaction. This information may include (i) contact information
                  such as your name, email address, phone number, and address, (ii) demographic
                  information such as your gender, date of birth, and zip code, (iii) insurance
                  information, including the name of your insurance carrier, insurance plan, and
                  Member, Group, or Plan ID numbers, (iv) medical data such as the name of the Eye
                  Doctor(s) you have visited, your reasons for visiting, the dates of those visits
                  and any other medical and Health Information you choose to share with us,
                  including your medical history, (v) your billing information, and (vi) any other
                  Personal Information you choose to provide us including without limitation any
                  Personal Information in any messages, emails, or texts you send us.
                </p>
                <p>
                  "Health Information" is information that relates to your past and present health
                  or vision conditions (including medications, ailments, and prescriptions) that is
                  derived from your use of the Services. Some Health Information may be subject to
                  laws and regulations, including HIPAA. You represent that any Health Information
                  that you disclose to us is your Health Information that you are authorized to
                  disclose to us. &ldquo;Personal Information&rdquo; is information about you,
                  including your first name, last name, and demographic information, derived from
                  your use of the Services.&nbsp;
                </p>
              </li>
            </ul>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>
                  <strong>When you contact us.</strong> We do everything possible to make sure you
                  have an exceptional exam booking experience. If you contact us in person, by
                  phone (calling or texting), or email, we may keep a record of your contact
                  information and the content of the communication for later reference. When we
                  send you emails, we may track whether you open them to learn how to deliver a
                  better customer experience and improve our Services. We receive responses to any
                  survey that you choose to complete for us.
                </p>
              </li>
            </ul>
          </div>
          <div css={disclaimerStyles.subSection}>
            <h3>Information we collect from third parties:</h3>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>
                  <strong>Information from your Eye Doctor. </strong> Your Eye Doctor may share
                  your prescription information and other medical information with us in order for
                  us to provide Services.
                </p>
              </li>
            </ul>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>
                  <strong>Information from third parties. </strong> We may collect information
                  about you from other third parties, including demographic information, and
                  combine this information with other information we collect about you.
                </p>
              </li>
            </ul>
          </div>
          <div css={disclaimerStyles.subSection}>
            <h3>Information we automatically collect:</h3>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>
                  <strong>Web logs and usage information. </strong> We record certain information
                  and store it in log files when you interact with our Services. This information
                  may include device and browser information, internet protocol (IP) address, URLs
                  of referring/exit pages, and search terms.
                </p>
              </li>
              <li>
                <p>
                  <strong>Information from cookies and other technologies.</strong> Like most
                  websites, the Services may incorporate technology such as &ldquo;pixel
                  tags&rdquo;, &ldquo;web beacons&rdquo;, and &ldquo;cookies&rdquo; that allow us
                  and our service providers to collect information about your actions on our
                  Services. Our service providers may collect information about your online
                  activities over time and across different online services. Cookies (not to be
                  confused with the sugary morsel) are small files that web servers may place on
                  your computer when you visit a website. You may be able to set your web browser
                  to reject cookies entirely or require that it request permission from you before
                  accepting each new cookie. You can also delete cookies from your web browser once
                  you leave a website.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>2. How We Use Your Information</h2>
          <p>
            <strong>Generally</strong>. We primarily use your information to provide and enhance
            our Services and your experience with My Vision Directory. Some ways we may use your
            information include:
          </p>
          <ul css={disclaimerStyles.unordered}>
            <li>
              <p>to provide, personalize, and improve the Services</p>
            </li>
            <li>
              <p>to facilitate the booking of your eye exam&nbsp;</p>
            </li>
            <li>
              <p>
                to communicate with you, including to respond to your comments or questions, and to
                send you updates about your appointments, including to reschedule, confirm or
                remind you of an upcoming appointment
              </p>
            </li>
            <li>
              <p>
                to understand your use of the Services to help us improve the customer experience
              </p>
            </li>
            <li>
              <p>
                to provide you with information, recommendations, and marketing materials about the
                Services
              </p>
            </li>
            <li>
              <p>for our other internal business purposes</p>
            </li>
          </ul>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>3. How We Share Your Information</h2>
          <p>If we share your information, we do so only as described below.</p>
          <div css={disclaimerStyles.subSection}>
            <h3>With vendors</h3>
            <p>
              We&rsquo;ve figured out ways to do a lot of things on our own, but we haven&rsquo;t
              quite figured out how to do all of it. We may provide your information to our
              contractors, agents, and service providers as necessary to enable them to perform
              certain services for us, including but not limited to:
            </p>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>payment processing</p>
              </li>
              <li>
                <p>vision insurance claim processing</p>
              </li>
              <li>
                <p>account registration</p>
              </li>
              <li>
                <p>website-related services, such as web hosting</p>
              </li>
              <li>
                <p>improvement of website-related services and features</p>
              </li>
              <li>
                <p>maintenance services</p>
              </li>
              <li>
                <p>marketing services</p>
              </li>
              <li>
                <p>data analytics</p>
              </li>
            </ul>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>With Warby Parker</h3>
            <p>
              We will share certain information with JAND, Inc. d/b/a Warby Parker (&ldquo;Warby
              Parker&rdquo;) so that Warby Parker can perform the below activities on our behalf,
              as well as provide other administrative assistance to us.
            </p>
            <ul css={disclaimerStyles.unordered}>
              <li>
                <p>Send you exam confirmations, reminders, and follow-up communication</p>
              </li>
              <li>
                <p>Answer questions you may have about the Services</p>
              </li>
              <li>
                <p>Assist with appointment rescheduling requests</p>
              </li>
            </ul>
            <p>
              Additionally, with your consent, we may add the information you share with us to your{" "}
              <a rel="noopener noreferrer" target="_blank" href="http://www.warbyparker.com">
                www.warbyparker.com
              </a>{" "}
              customer account, which you can then access at any time by logging into your account
              at{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.warbyparker.com/login"
              >
                https://www.warbyparker.com/login
              </a>
              .
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>With marketing and advertising partners</h3>
            <p>
              One of the third party services that we use to track your activity on our Services is
              Google Analytics. If you do not want Google Analytics to collect and use information
              about your use of our Services, then you can install an opt-out in your web browser
              (https://tools.google.com/dlpage/gaoptout/). You also may opt-out from Google
              Analytics for Display Advertising or the Google Display Network by using
              Google&rsquo;s Ads Settings located at www.google.com/settings/ads. You can also
              visit your device&rsquo;s settings or install the AppChoices app to learn more about
              how you may opt out of receiving personalized ads in mobile apps.
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>With Eye Doctors</h3>
            <p>
              In connection with the Services, we will share your Health Information and Personal
              Information with the Eye Doctor who performs your eye exam. Eye Doctors may be any of
              the following: Warby Parker employees, Warby Parker independent contractors,
              employees of a professional corporation, or independent contractors of a professional
              corporation. In all cases, Eye Doctors will be under a contractual obligation to keep
              your Health Information and Personal Information confidential.
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>With your vision insurance carrier</h3>
            <p>
              We may share your information with your vision insurance carrier in order to process
              your claim and so that your Eye Doctor receives payment.&nbsp;
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>On an aggregated or de-identified basis</h3>
            <p>
              We may share aggregated or de-identified information (which cannot reasonably be used
              to identify you) with service providers or other third parties. These service
              providers and third parties may also share with us aggregated or de-identified
              information that they have independently developed or acquired.
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>With business partners we do not control</h3>
            <p>
              We may work with other businesses from time to time. These business partners may sell
              items or provide promotions to you through our Services or theirs. We may also
              provide products or services to you jointly with our business partners. When we do
              this, we will clearly show you that a business partner is associated with your
              transaction, and we will only share information with them that is related to your
              transaction in accordance with your direction to share with that partner .
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>In connection with business transfers</h3>
            <p>
              If the ownership of all or substantially all of our business changes, or we otherwise
              transfer assets relating to our business or any of the Services to a third party
              (such as by merger, acquisition, bankruptcy proceeding, or other change of control
              transaction), we may transfer or sell your information to the new owner. In such a
              case, unless permitted otherwise by applicable law, your information would remain
              subject to this Privacy Policy unless we notify you otherwise.&nbsp;
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>To protect us and others</h3>
            <p>
              We reserve the right to access, read, preserve, and disclose any information that we
              reasonably believe is necessary to comply with any applicable law, court order,
              subpoena, legal process, or enforceable governmental request; cooperate with law
              enforcement; enforce or apply this Privacy Policy, our{" "}
              <a rel="noopener noreferrer" target="_blank" href="/disclaimer/terms-of-use">
                Terms of Use
              </a>
              , and other agreements; detect, prevent, or otherwise address fraud, security or
              technical issues ; or protect the rights, property, or safety of My Vision Directory,
              our employees, our users, or others.
            </p>
          </div>
          <div css={disclaimerStyles.italicizedSubSection}>
            <h3>With your consent</h3>
            <p>
              Except as we&rsquo;ve stated above, we will notify you before we share your
              identifiable information with any third parties.
            </p>
          </div>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>4. California Privacy Rights</h2>
          <p>
            If you are a California resident, you can ask us for a notice identifying the
            categories of personal information, as defined by California Civil Code Section
            1798.83, that we share with our affiliates and/or third parties for their direct
            marketing purposes and the contact information for such affiliates and/or third parties
            (under California Civil Code Sections 1798.83-1798.84). If you are a California
            resident and would like a copy of this notice, please submit a written request to{" "}
            <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>.
          </p>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>5. We are Committed to Protecting Your Information</h2>
          <p>
            We take the protection of your information very seriously. We understand the
            sensitivity of (for example) the prescription information you may give us. Therefore,
            we take many security measures (physical, electronic, and procedural) to help keep your
            information safe. For example, we limit access to authorized employees and third
            parties who are under an obligation to keep all such information confidential. Also, we
            only allow parties to access your identifiable information for permitted business
            purposes. Unfortunately, even though we use commercially reasonable efforts to protect
            your information, we cannot guarantee complete security. Any information that you
            provide to us is done so entirely at your own risk.
          </p>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>6. Children&rsquo;s Information</h2>
          <p>
            We are especially sensitive about children&rsquo;s information. We do not knowingly
            allow individuals under the age of 18 to use our Services and we do not knowingly
            collect personal information from children under the age of 13, in accordance with the
            Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;). If you are a
            parent or legal guardian of a minor child, you may use our Services on behalf of such
            minor child. If you have questions concerning our information practices with respect to
            children, or if you believe a child under the age of 13 has provided us with personal
            information, please email us at{" "}
            <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>.
          </p>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>
            7. Not Intended for EU Residents; Information Stored in the United States
          </h2>
          <p>
            The Services are not intended for European Union (EU) residents. Accordingly, you
            understand that your information may be stored in the United States or Canada.
            Information may be transferred outside of the country where you reside or are located,
            including to countries that may not or do not provide the same level of protection as
            the EU. By using and accessing our Services, users who reside or are located in
            countries outside of the United States agree and consent to the transfer and processing
            of personal information on servers located outside of the country where they reside,
            and acknowledge that the protection of such information may be different than required
            under the laws of their residence or location.
          </p>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>8. Changes to this Privacy Policy</h2>
          <p>
            We occasionally review this Privacy Policy to make sure it complies with applicable
            laws and conforms to changes in our business. We may need to update this Privacy
            Policy, and we reserve the right to do so at any time. If we do revise this Privacy
            Policy, we will update the &ldquo;Effective Date&rdquo; at the top of this page so that
            you can tell if it has changed since your last visit. Your continued use of our
            Services constitutes your acceptance of the terms of the Privacy Policy as amended or
            revised by us from time to time.
          </p>
        </div>
        <div css={disclaimerStyles.section}>
          <h2 css={disclaimerStyles.h2}>9. Contact Us</h2>
          <p>
            If you have any questions about viewing or updating your information, this Privacy
            Policy, or our privacy practices, please contact us at{" "}
            <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
);

export default PrivacyPolicy;
