/** @jsx jsx */
import { jsx } from "@emotion/core";
import { IOfficeElement, ITempObj, IUSOfficesProps } from "types";

import Office from "./Office";
import officesStyles from "styles/offices";

// tslint:disable: no-unsafe-any
const UnitedStatesOffices = ({ officesUS }: IUSOfficesProps): JSX.Element => {
  const data = officesUS.sort((a: IOfficeElement, b: IOfficeElement) =>
    a.state > b.state ? 1 : -1,
  );
  const groups = Object.create(null);
  let result;

  data.forEach((office: IOfficeElement): void => {
    groups[office.state] = groups[office.state] || [];
    groups[office.state].push(office);
  });

  result = Object.keys(groups).map(
    (k: string): ITempObj => {
      const temp = ({} as unknown) as ITempObj;
      temp[k] = groups[k];
      return temp;
    },
  );

  return (
    <div css={officesStyles.countryContainer}>
      <h1 css={officesStyles.countryHeader}>Locations in the U.S.</h1>
      <div css={officesStyles.stateContainer}>
        {result.map((x: ITempObj, i: number) => {
          const state = Object.keys(x)[0];
          const officesInThisState = officesUS.filter(
            (office: IOfficeElement) => office.state === state,
          );
          return (
            <div css={officesStyles.stateSection} key={i}>
              <h4 css={officesStyles.stateHeader}>{state}</h4>
              {officesInThisState.map((office: IOfficeElement, k: number) => {
                const officeName = office.name;
                const address = office.address;
                const city = office.city;
                const shortName = office.location_short_name;

                return (
                  <Office
                    key={k}
                    officeName={officeName}
                    address={address}
                    city={city}
                    shortName={shortName}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UnitedStatesOffices;
