/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";

import Logo from "./icons/logo.svg";

const logoContainer = css`
  height: 70px;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 900px) {
    height: 55px;
  }

  img {
    width: 260px;
    display: block;
  }

  img {
    @media screen and (max-width: 900px) {
      width: 230px;
      text-align: center;
    }
  }
`;

const Header: React.FC = (): JSX.Element => (
  <div css={logoContainer}>
    <Link to="/">
      <img src={Logo} alt="logo" />
    </Link>
  </div>
);

export default Header;
