import { format, parse } from "date-fns";
import React from "react";
import DayPicker, { DayModifiers } from "react-day-picker";

import { ICalendarProps, ITimeSlotBool } from "types";

const Calendar = ({ dispatch, openSlots, selectedDate }: ICalendarProps): JSX.Element => {
  const today = new Date();
  const calendarDaysLimit = 59;
  const calendarMonthLimit = 30;

  const handleClick = (day: Date, modifiers: DayModifiers): void => {
    if (modifiers.disabled) {
      return;
    }
    dispatch({ payload: format(day, "YYYY-MM-DD"), type: "date" });
    dispatch({ payload: true, type: "calendar-hidden" });
  };

  const parsedDate = new Date(`${selectedDate} 00:00`);
  const parsedDates = openSlots
    .filter((openSlot: ITimeSlotBool) => !openSlot.time_slots)
    .map((openSlot: ITimeSlotBool) => parse(openSlot.date));

  const currentDate = new Date();
  const futureDate = new Date(currentDate.setDate(currentDate.getDate() + calendarDaysLimit));

  const currentMonth = new Date();
  const futureMonth = new Date(currentMonth.setDate(currentMonth.getDate() + calendarMonthLimit));

  return (
    <div>
      <DayPicker
        disabledDays={[...(parsedDates as Date[]), { before: today, after: futureDate }]}
        onDayClick={handleClick}
        selectedDays={parsedDate}
        fromMonth={new Date()}
        toMonth={futureMonth}
      />
    </div>
  );
};

export default Calendar;
