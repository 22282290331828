import { format } from "date-fns";

import {
  IAction,
  IAppointment,
  ICustomerInfoErrors,
  ICustomerState,
  IExamPayload,
} from "../../types";
import {
  CANCEL_APPOINTMENT_ERROR,
  CANCEL_APPOINTMENT_PENDING,
  CANCEL_APPOINTMENT_SUCCESS,
  CLEAR_OFFICE_DETAILS_FETCHES,
  CREATE_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT_PENDING,
  CREATE_APPOINTMENT_SUCCESS,
} from "../actions/action-creators";

const initState: ICustomerState = {
  appointment: {
    data: {
      allow_overlapping: false,
      appt_is_break: false,
      base_recurring_appointment: null,
      billing_notes: [],
      billing_provider: null,
      billing_status: "",
      cancel_signature: "",
      clinical_note: null,
      cloned_from: null,
      color: "",
      created_at: "",
      custom_fields: [],
      custom_vitals: [],
      deleted_flag: false,
      doctor: 0,
      duration: 0,
      exam_room: 0,
      extended_updated_at: "",
      first_billed_date: "",
      icd10_codes: [],
      icd9_codes: [],
      id: 0,
      is_walk_in: false,
      last_billed_date: "",
      notes: "",
      office: 0,
      patient: 0,
      primary_insurance_id_number: "",
      primary_insurer_name: "",
      primary_insurer_payer_id: "",
      profile: 0,
      reason: "",
      recurring_appointment: false,
      reminders: [],
      scheduled_time: "",
      secondary_insurance_id_number: "",
      secondary_insurer_name: "",
      secondary_insurer_payer_id: "",
      status: "",
      supervising_provider: null,
      updated_at: "",
      vitals: {
        blood_pressure_1: null,
        blood_pressure_2: null,
        bmi: null,
        head_circumference: null,
        head_circumference_units: "",
        height: null,
        height_units: "",
        oxygen_saturation: null,
        pain: null,
        pulse: null,
        respiratory_rate: null,
        smoking_status: "",
        temperature: null,
        temperature_units: "",
        weight: null,
        weight_units: "",
      },
    },
    error: "",
    pending: false,
  },
  contactWearer: false,
  dilation: false,
  error: {
    dob: false,
    email: false,
    firstName: false,
    lastName: false,
    phone: false,
  },
  exam: {
    appointmentProfileID: 0,
    examDuration: 0,
    examType: null,
  },
  examTime: {
    date: format(new Date(), "YYYY-MM-DD"),
    doctor: null,
    examRoom: null,
    time: "",
  },
  personalInfo: {
    dob: {
      day: "",
      month: "",
      year: "",
    },
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  },
};

// tslint:disable-next-line: cyclomatic-complexity
export const customerReducer = (
  state: ICustomerState = initState,
  action: IAction,
): ICustomerState => {
  const { type, payload } = action;
  switch (type) {
    case "exam":
      return { ...state, exam: payload as IExamPayload };
    case "contact-wearer":
      return { ...state, contactWearer: payload as boolean };
    case "dilation":
      return { ...state, dilation: payload as boolean };
    case "date":
      return { ...state, examTime: { ...state.examTime, date: payload as string } };
    case "time":
      return { ...state, examTime: { ...state.examTime, time: payload as string } };
    case "exam-room":
      return { ...state, examTime: { ...state.examTime, examRoom: payload as number } };
    case "doctor-id":
      return { ...state, examTime: { ...state.examTime, doctor: payload as number } };
    case "first-name":
      return { ...state, personalInfo: { ...state.personalInfo, firstName: payload as string } };
    case "last-name":
      return { ...state, personalInfo: { ...state.personalInfo, lastName: payload as string } };
    case "email":
      return { ...state, personalInfo: { ...state.personalInfo, email: payload as string } };
    case "dob-month":
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          dob: { ...state.personalInfo.dob, month: payload as string },
        },
      };
    case "dob-day":
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          dob: { ...state.personalInfo.dob, day: payload as string },
        },
      };
    case "dob-year":
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          dob: { ...state.personalInfo.dob, year: payload as string },
        },
      };
    case "phone":
      return { ...state, personalInfo: { ...state.personalInfo, phone: payload as string } };
    case "error":
      return { ...state, error: payload as ICustomerInfoErrors };
    case "clear-error":
      return { ...state, error: { ...initState.error } };
    case CANCEL_APPOINTMENT_PENDING:
    case CREATE_APPOINTMENT_PENDING:
      return {
        ...state,
        appointment: {
          ...state.appointment,
          pending: true,
        },
      };
    case CANCEL_APPOINTMENT_SUCCESS:
    case CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointment: {
          ...state.appointment,
          data: payload as IAppointment,
          error: "",
          pending: false,
        },
      };
    case CANCEL_APPOINTMENT_ERROR:
    case CREATE_APPOINTMENT_ERROR:
      return {
        ...state,
        appointment: {
          ...state.appointment,
          error: payload as string,
          pending: false,
        },
      };
    case CLEAR_OFFICE_DETAILS_FETCHES:
      return initState;
    default:
      return state;
  }
};
