import { css } from "@emotion/core";

// offices index
const officesContainer = css`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

// offices Container
const clickable = css`
  font-weight: 600;
  color: #00a2e1;
`;

const officeContainer = css`
  padding: 20px 0 0 0;
  p,
  a {
    padding: 0;
    margin: 0;
    font-size: 18px;
  }
`;

// US/CA office specific
const countryContainer = css`
  margin: 20px 0;
  padding: 20px;
  @media screen and (max-width: 900px) {
    padding: 40px;
  }
`;

const stateContainer = css`
  display: block;
  column-count: 3;
  column-gap: 100px;
  max-width: 1040px;
  @media screen and (max-width: 900px) {
    column-count: 1;
  }
`;

const countryHeader = css`
  text-align: center;
  margin: 5px 0 20px;
  @media screen and (max-width: 900px) {
    text-align: left;
  }
`;

const stateHeader = css`
  &:first-of-type {
    margin: 0;
  }
  margin: 30px 0 0 0;
  border-bottom: #d8d8d8 1px solid;
  display: inline-block;
  width: auto;
  font-size: 16px;
  color: #004a75;
  letter-spacing: 0.5px;
  font-weight: 400;
`;

const stateSection = css`
  display: inline-block;
  width: 100%;
  margin-top: 36px;
`;

export default {
  clickable,
  officeContainer,
  officesContainer,
  countryContainer,
  stateContainer,
  countryHeader,
  stateHeader,
  stateSection,
};
