import React from "react";

import API from "service-clients/pmp";
import {
  IAction,
  IPostPayload,
  IIntakeFormResponse,
  IIntakeFormRulesArr,
  IIntakeFormQueries,
  IIntakeFormSubmitPayload,
} from "types";

import { actionCreators } from "./action-creators";
import { CustomException } from "service-clients/response-parser";

// Roger: How to keep track of FE bugs = App Health / Logger
// tslint:disable: no-unsafe-any

export const actions = {
  async completeIntakeForm(
    dispatch: React.Dispatch<IAction>,
    userResponses: IIntakeFormResponse,
  ): Promise<void> {
    dispatch(actionCreators.completeIntakeForm(userResponses));
  },
  async triggerPreviousQuestion(dispatch: React.Dispatch<IAction>): Promise<void> {
    dispatch(actionCreators.triggerPreviousQuestion());
  },
  async startIntakeForm(
    dispatch: React.Dispatch<IAction>,
    userResponses: IIntakeFormResponse,
  ): Promise<void> {
    dispatch(actionCreators.startIntakeForm(userResponses));
  },
  async triggerNextQuestion(
    dispatch: React.Dispatch<IAction>,
    userResponses: IIntakeFormResponse,
    questionRules: IIntakeFormRulesArr,
  ): Promise<void> {
    dispatch(actionCreators.triggerNextQuestion(userResponses, questionRules));
  },
  async fetchAllOffices(dispatch: React.Dispatch<IAction>): Promise<void> {
    dispatch(actionCreators.fetchAllOfficesPending());
    const officesRes = await API.getAllOffices().catch((e: Error): void => {
      dispatch(actionCreators.fetchAllOfficesError(e.message));
      return;
    });
    if (officesRes) {
      dispatch(actionCreators.fetchAllOfficesSuccess(officesRes.offices));
    }
  },
  async fetchDoctors(dispatch: React.Dispatch<IAction>, officeID: any): Promise<void> {
    dispatch(actionCreators.fetchDoctorsPending());
    const doctorsRes = await API.getDoctors({officeID}).catch((e: Error): void => {
      dispatch(actionCreators.fetchDoctorsError(e.message));
      return;
    });
    if (doctorsRes) {
      dispatch(actionCreators.fetchDoctorsSuccess(doctorsRes.doctors));
    }
  },
  async fetchAllPaymentMethods(dispatch: React.Dispatch<IAction>): Promise<void> {
    dispatch(actionCreators.fetchAllPaymentMethodsPending());
    const paymentPlanRes = await API.getAllPaymentMethods().catch((e: Error): void => {
      dispatch(actionCreators.fetchAllPaymentMethodsError(e.message));
      return;
    });
    if (paymentPlanRes) {
      dispatch(actionCreators.fetchAllPaymentMethodsSuccess(paymentPlanRes));
    }
  },
  async fetchAllInsurancePlans(dispatch: React.Dispatch<IAction>): Promise<void> {
    dispatch(actionCreators.fetchAllInsurancePlansPending());
    const insurancePlanRes = await API.getAllInsurancePlans().catch((e: Error): void => {
      dispatch(actionCreators.fetchAllInsurancePlansError(e.message));
      return;
    });
    if (insurancePlanRes) {
      dispatch(actionCreators.fetchAllInsurancePlansSuccess(insurancePlanRes));
    }
  },
  async fetchOfficeDetails(
    dispatch: React.Dispatch<IAction>,
    officeShortName: string,
  ): Promise<void> {
    dispatch(actionCreators.fetchOfficeDetailsPending());
    const officeDetailsRes = await API.getOfficeDetails({ officeShortName }).catch(
      (e: Error): void => {
        dispatch(actionCreators.fetchOfficeDetailsError(e.message));
        return;
      },
    );
    if (officeDetailsRes) {
      dispatch(actionCreators.fetchOfficeDetailsSuccess(officeDetailsRes));
    }
  },
  async fetchPracticeDetails(
    dispatch: React.Dispatch<IAction>,
    practiceGroupID: number,
  ): Promise<void> {
    dispatch(actionCreators.fetchPracticeDetailsPending());
    const officeDetailsRes = await API.getPracticeDetails({ practiceGroupID }).catch(
      (e: Error): void => {
        dispatch(actionCreators.fetchPracticeDetailsError(e.message));
        return;
      },
    );
    if (officeDetailsRes) {
      dispatch(actionCreators.fetchPracticeDetailsSuccess(officeDetailsRes));
    }
  },
  async fetchAppointmentProfiles(
    dispatch: React.Dispatch<IAction>,
    practiceGroupID: number,
  ): Promise<void> {
    dispatch(actionCreators.fetchAppointmentProfilesPending());
    const appointmentProfilesRes = await API.getAppointmentProfiles({ practiceGroupID }).catch(
      (e: Error): void => {
        dispatch(actionCreators.fetchAppointmentProfilesError(e.message));
        return;
      },
    );
    if (appointmentProfilesRes) {
      dispatch(actionCreators.fetchAppointmentProfilesSuccess(appointmentProfilesRes));
    }
  },
  async fetchTimeSlots(
    dispatch: React.Dispatch<IAction>,
    selectedDate: string,
    examDuration: number,
    officeID: number,
    dateLimit: number,
  ): Promise<void> {
    dispatch(actionCreators.fetchTimeSlotsPending());
    const timeSlotsRes = await API.getTimeSlots({
      examDuration,
      officeID,
      selectedDate,
      dateLimit,
    }).catch((e: Error): void => {
      dispatch(actionCreators.fetchTimeSlotsError(e.message));
      return;
    });
    if (timeSlotsRes) {
      dispatch(actionCreators.fetchTimeSlotsSuccess(timeSlotsRes.open_slots));
    }
  },
  async fetchTimeSlotsBool(
    dispatch: React.Dispatch<IAction>,
    selectedDate: string,
    examDuration: number,
    officeID: number,
    dateLimit: number,
  ): Promise<void> {
    dispatch(actionCreators.fetchTimeSlotsBoolPending());
    const timeSlotsBoolRes = await API.getTimeSlotsBool({
      examDuration,
      officeID,
      selectedDate,
      dateLimit,
    }).catch((e: Error): void => {
      dispatch(actionCreators.fetchTimeSlotsBoolError(e.message));
      return;
    });
    if (timeSlotsBoolRes) {
      dispatch(actionCreators.fetchTimeSlotsBoolSuccess(timeSlotsBoolRes.open_slots));
    }
  },
  async createAppointment(
    dispatch: React.Dispatch<IAction>,
    officeID: number,
    doctorID: number | null,
    postPayload: IPostPayload,
  ): Promise<void> {
    dispatch(actionCreators.createAppointmentPending());
    const createdAppointmentRes = await API.createAppointment({
      officeID,
      doctorID,
      postPayload,
    }).catch((e: Error): void => {
      dispatch(actionCreators.createAppointmentError(e.message));
      return;
    });
    if (createdAppointmentRes) {
      dispatch(actionCreators.createAppointmentSuccess(createdAppointmentRes as any));
    }
  },
  async cancelAppointment(
    dispatch: React.Dispatch<IAction>,
    doctorID: number | null,
    appointmentID: number,
    hash: string,
  ): Promise<void> {
    dispatch(actionCreators.cancelAppointmentPending());
    const cancelledAppointmentRes = await API.cancelAppointment({
      appointmentID,
      doctorID,
      hash,
    }).catch((e: Error): void => {
      dispatch(actionCreators.cancelAppointmentError(e.message));
      return;
    });
    if (cancelledAppointmentRes) {
      dispatch(actionCreators.cancelAppointmentSuccess(cancelledAppointmentRes as any));
    }
  },
  async fetchIntakeForm(
    dispatch: React.Dispatch<IAction>,
    docid: string,
    apptid: string,
    hash: string,
  ): Promise<void> {
    dispatch(actionCreators.fetchIntakeFormPending());
    const intakeFormRes = await API.getIntakeForm({
      docid,
      apptid,
      hash,
    }).catch((e: CustomException): void => {
      if (e.statusCode === 409) {
        dispatch(actionCreators.fetchIntakeFormCancelled(e.message));
      } else {
        dispatch(actionCreators.fetchIntakeFormError(e.message));
      }
      return;
    });
    if (intakeFormRes) {
      dispatch(actionCreators.fetchIntakeFormSuccess(intakeFormRes));
    }
  },
  async submitIntakeForm(
    dispatch: React.Dispatch<IAction>,
    queries: IIntakeFormQueries,
    payload: IIntakeFormSubmitPayload,
  ): Promise<void> {
    dispatch(actionCreators.submitIntakeFormPending());
    const submitIntakeFormRes = await API.submitIntakeForm(queries, payload).catch(
      (e: Error): void => {
        dispatch(actionCreators.submitIntakeFormError(e.message));
        return;
      },
    );
    if (submitIntakeFormRes) {
      dispatch(actionCreators.submitIntakeFormSuccess(submitIntakeFormRes as any));
    }
  },
};
