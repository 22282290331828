import { css } from "@emotion/core";

const textContainer = css`
  margin: 20px 0;
  @media screen and (min-width: 900px) {
    width: 500px;
  }
`;

const buttonContainer = css`
  margin: 40px 0;
`;

const button = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  padding: 15px 5%;
  margin-right: 15px;
  font-size: 16px;
  :hover {
    border: 1px solid #004a75;
  }
  :active {
    background-color: #00a2e1;
    color: #fff;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px 0;
  }
`;

export default {
  textContainer,
  buttonContainer,
  button,
};
