/** @jsx jsx */
import { jsx } from "@emotion/core";
import { IOpenEndedQuestionProps } from "types";
import intakeFormStyles from "styles/intakeForm";

const OpenEndedQuestion = ({
  loggedResForQuestionType,
  updateResponses,
}: IOpenEndedQuestionProps): JSX.Element => {
  return (
    <div css={intakeFormStyles.optionsContainer}>
      <div css={intakeFormStyles.optionTextContainer}>
        <textarea onChange={e => updateResponses(e.target.value)}>
          {loggedResForQuestionType || ""}
        </textarea>
      </div>
    </div>
  );
};

export default OpenEndedQuestion;
