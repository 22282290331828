/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

const blankFooter = css`
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #004a75;
  margin-top: auto;
  padding: 20px;
  color: white;
  flex: 0 0 50px;
  text-align: center;
  height: 150px;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: 315px;
    text-align: left;
  }
`;

const FooterBlank: React.FC = (): JSX.Element => <footer css={blankFooter} />;

export default FooterBlank;
