const formatPhoneNumber = (phoneNumber: string): null | string => {
  const cleaned = ` ${phoneNumber}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

const phoneFormatter = (): void => {
  const currentPhoneNumber = (document.getElementById("phoneNumber") as HTMLInputElement).value;
  if (currentPhoneNumber.length > 11) {
    return;
  }
  (document.getElementById("phoneNumber") as HTMLInputElement).value =
    formatPhoneNumber(currentPhoneNumber) || currentPhoneNumber;
};

const divFocus = (): void => {
  const dobDiv = document.querySelector(".date-input-container");
  (dobDiv as HTMLDivElement).style.border = "1px solid #676F78";
};

const divBlur = (): void => {
  const dobDiv = document.querySelector(".date-input-container");
  (dobDiv as HTMLDivElement).style.border = "1px solid #d1dcda";
};

const moveToNext = (selector: string, nextSelector: string): void => {
  const ele = document.querySelector(selector);
  (ele as HTMLInputElement).addEventListener("input", function(): void {
    const nextEle = document.querySelector(nextSelector) as HTMLInputElement;
    if (this.value.length >= 2) {
      // Date has been entered, move
      nextEle.focus();
    }
  });
};

export const format = {
  divBlur,
  divFocus,
  moveToNext,
  phoneFormatter,
};
