/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";

import SingleSelectQuestion from "./question-types/SingleSelectQuestion";
import OpenEndedQuestion from "./question-types/OpenEndedQuestion";
import YesNoQuestion from "./question-types/YesNoQuestion";
import MultipleSelectQuestion from "./question-types/MultipleSelectQuestion";
import AcknowledgementQuestion from "./question-types/AcknowledgementQuestion";

import BackArrow from "shared-ui/icons/intake-back-arrow.svg";
import ProgressBar from "shared-ui/ProgressBar";
import IntakeFormHeader from "shared-ui/IntakeFormHeader";

import { actions } from "redux/actions/actions";

import { QUESTION_TYPES, OPTIONS } from "redux/reducers/intakeFormReducer";

import {
  IIntakeFormQuestionProps,
  IIntakeFormResponse,
  IMultiSelectionState,
  IIntakeFormOption,
  IMultiSelectionResponsesArr,
} from "types";

import intakeFormStyles from "styles/intakeForm";

const IntakeQuestionPage = ({
  currentIndex,
  question,
  loggedResponses,
  prevMultiSelectState,
  showPrevPrompt,
  dispatch,
  numberOfQuestions,
}: IIntakeFormQuestionProps): JSX.Element => {
  const [responses, updateResponses] = useState<null | string>(null);
  const [multiSelectionState, updateMultiSelectionState] = useState<IMultiSelectionState>({});
  const [otherResponse, updateOtherResponse] = useState("");
  const [showPrompt, togglePrompt] = useState(false);
  const [currentOption, setCurrentOption] = useState<null | IIntakeFormOption>(null);

  const isYesNoQuestion = question.type === QUESTION_TYPES.YES_NO;
  const isLastQuestion = question.type === QUESTION_TYPES.ACKNOWLEDGEMENT;
  const isMultiSelectQuestion = question.type === QUESTION_TYPES.MULTIPLE_SELECT;
  const isOpenEndedQuestion = question.type === QUESTION_TYPES.OPEN_ENDED;
  const isSingleSelectQuestion = question.type === QUESTION_TYPES.SINGLE_SELECT;

  const questionOptions = question.options;
  const prompt = question.YES;
  const questionName = question.question;
  const prevResponse = loggedResponses.find(
    (res: IIntakeFormResponse) => res.question === questionName,
  );
  const loggedResForQuestionType = prevResponse ? prevResponse.response : null;
  const buttonText = isLastQuestion ? "Submit" : "Next";

  useEffect(() => {
    let updateStateArgs = {};
    let togglePrompArgs = showPrevPrompt;
    if (prevMultiSelectState && isMultiSelectQuestion) {
      updateStateArgs = prevMultiSelectState;
    }
    updateMultiSelectionState(updateStateArgs);
    togglePrompt(togglePrompArgs);
  }, [currentIndex]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const findAndReplaceOtherValue = (loggedResForQuestionType: string[]) => {
    const multiSelectionResponses = Object.keys(multiSelectionState).filter(
      response => multiSelectionState[response] === true,
    );
    const prevOtherResponse = multiSelectionResponses.find(response =>
      response.includes(OPTIONS.OTHER),
    );
    const otherIndex = multiSelectionResponses.indexOf(prevOtherResponse as string);
    if (otherIndex !== -1) {
      if (loggedResForQuestionType) {
        const prevOtherTextRes = loggedResForQuestionType.find((res: string) =>
          res.includes(OPTIONS.OTHER),
        );
        (multiSelectionResponses as IMultiSelectionResponsesArr)[otherIndex] =
          prevOtherTextRes || "";
      } else {
        (multiSelectionResponses as IMultiSelectionResponsesArr)[otherIndex] = otherResponse;
      }
    }
    return multiSelectionResponses;
  };

  const clearResponses = () => {
    updateResponses(null);
    updateMultiSelectionState({});
    togglePrompt(false);
    scrollToTop();
    updateOtherResponse("");
  };

  const multipleSelectResponsesChecker = (multiSelectionResponses: string[]): boolean => {
    if (multiSelectionResponses.length === 0) return true;
    const otherRes = multiSelectionResponses.find((res: string) => res.includes(OPTIONS.OTHER));
    if (otherRes) {
      if (!otherResponse) return true;
      // otherResponse can be "OPTION: "
      // "OPTION: ".length === 7
      return otherResponse.length <= 7;
    }
    return false;
  };

  const buttonDisablingHandler = () => {
    const multiSelectionResponses = Object.keys(multiSelectionState).filter(
      response => multiSelectionState[response] === true,
    );
    const didNotSelectResponse = isMultiSelectQuestion
      ? multipleSelectResponsesChecker(multiSelectionResponses)
      : !responses;

    if (isLastQuestion || prevResponse) {
      return false;
    } else if (didNotSelectResponse) {
      return true;
    } else if (isYesNoQuestion && prompt && responses) {
      // button should be disabled if user has not filled out prompt
      // Example: "Do you have any alleries?"
      // Response should be "YES: STRINGALLERGY LIST" or "NO"
      const acceptableStringLengths = responses.length > 5 || responses.length === 2;
      return !acceptableStringLengths;
    }
    return !prevResponse && didNotSelectResponse;
  };

  const onClickHander = () => {
    const responseValue = isMultiSelectQuestion
      ? findAndReplaceOtherValue(loggedResForQuestionType)
      : responses || loggedResForQuestionType;
    const questionRules = question.rules || null;
    if (isLastQuestion) {
      actions.completeIntakeForm(dispatch, { question: question.question, response: "OK" });
    } else {
      actions.triggerNextQuestion(
        dispatch,
        {
          question: questionName,
          response: responseValue,
        },
        questionRules,
      );
    }
    clearResponses();
  };

  const renderQuestionType = () => {
    if (isOpenEndedQuestion) {
      return (
        <OpenEndedQuestion
          loggedResForQuestionType={loggedResForQuestionType}
          updateResponses={updateResponses}
        />
      );
    }
    if (isYesNoQuestion) {
      return (
        <YesNoQuestion
          prompt={prompt}
          updateResponses={updateResponses}
          updateOtherResponse={updateOtherResponse}
          responses={responses || ""}
          showPrompt={showPrompt}
          togglePrompt={togglePrompt}
          loggedResForQuestionType={loggedResForQuestionType}
        />
      );
    }
    if (isSingleSelectQuestion) {
      return (
        <SingleSelectQuestion
          updateResponses={updateResponses}
          responses={responses || ""}
          questionOptions={questionOptions}
          loggedResForQuestionType={loggedResForQuestionType}
        />
      );
    }
    if (isMultiSelectQuestion) {
      return (
        <MultipleSelectQuestion
          showPrompt={showPrompt}
          togglePrompt={togglePrompt}
          questionOptions={questionOptions}
          currentOption={currentOption}
          setCurrentOption={setCurrentOption}
          updateOtherResponse={updateOtherResponse}
          multiSelectionState={multiSelectionState}
          updateMultiSelectionState={updateMultiSelectionState}
          loggedResForQuestionType={loggedResForQuestionType}
        />
      );
    }
    if (isLastQuestion) {
      return (
        <AcknowledgementQuestion
          documentUrl={question.document_url}
          imgUrl={question.document_image_url}
          footerText={question.footer_text}
          documentMouseOverText={question.document_mouse_over_text}
        />
      );
    }
    return false;
  };

  return (
    <div css={intakeFormStyles.quesBlock}>
      <IntakeFormHeader />
      <ProgressBar progress={`${(currentIndex / numberOfQuestions) * 100}%`} />
      <div css={intakeFormStyles.quesContainer}>
        <div
          tabIndex={0}
          role="button"
          css={intakeFormStyles.arrowContainer}
          onClick={() => actions.triggerPreviousQuestion(dispatch)}
          onKeyDown={() => actions.triggerPreviousQuestion(dispatch)}
        >
          <img css={intakeFormStyles.arrow} src={BackArrow} alt="left-arrow" />
        </div>
        {/* After discussing with designs, the acknowledgement page
        will be the only page that'll be centered in the flow */}
        <h1 css={isLastQuestion && intakeFormStyles.centeredH1}>{question.text}</h1>
        {renderQuestionType()}
        <div css={intakeFormStyles.quesBlueButtonContainer}>
          <button
            disabled={buttonDisablingHandler()}
            onClick={onClickHander}
            value={question.name}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntakeQuestionPage;
