/** @jsx jsx */
import { jsx } from "@emotion/core";
import { IMultipleSelectQuestionProps, IIntakeFormOption } from "types";
import { PUNCTUATION, OPTIONS } from "redux/reducers/intakeFormReducer";
import intakeFormStyles from "styles/intakeForm";

const MultipleSelectQuestion = ({
  showPrompt,
  togglePrompt,
  questionOptions,
  currentOption,
  setCurrentOption,
  updateOtherResponse,
  multiSelectionState,
  updateMultiSelectionState,
  loggedResForQuestionType,
}: IMultipleSelectQuestionProps): JSX.Element => {
  const prevOtherTextRes =
    loggedResForQuestionType &&
    loggedResForQuestionType.find((res: string) => res.includes(OPTIONS.OTHER));
  return (
    <div css={intakeFormStyles.optionsContainer}>
      {questionOptions.map(
        (option: IIntakeFormOption): JSX.Element => (
          <div key={option.name} css={intakeFormStyles.optionContainer}>
            <button
              css={
                multiSelectionState[option.name]
                  ? intakeFormStyles.optionActive
                  : intakeFormStyles.optionInactive
              }
              type="button"
              onClick={() => {
                updateMultiSelectionState({
                  ...multiSelectionState,
                  [option.name]: multiSelectionState[option.name] !== true,
                });
                if (option.name === OPTIONS.OTHER) {
                  togglePrompt(!showPrompt);
                  setCurrentOption(option);
                }
              }}
              value={option.name}
            >
              {option.text}
            </button>
          </div>
        ),
      )}
      {showPrompt && currentOption && (
        <div css={intakeFormStyles.optionTextContainer}>
          <span>{currentOption.prompt}</span>
          <textarea
            onChange={e => {
              updateOtherResponse(`${currentOption.name}: ${e.target.value}`);
            }}
          >
            {prevOtherTextRes ? prevOtherTextRes.split(PUNCTUATION)[1] : ""}
          </textarea>
        </div>
      )}
    </div>
  );
};

export default MultipleSelectQuestion;
