/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";

import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";
import disclaimerStyles from "styles/disclaimers";

// tslint:disable: strict-type-predicates
// tslint:disable: strict-comparisons
const TermsOfUse = (): JSX.Element => (
  <Fragment>
    <Header />
    <div css={disclaimerStyles.container}>
      <div css={disclaimerStyles.intro}>
        <h1>Terms of Use</h1>
        <h1>Effective Date: December 1, 2019</h1>
        <p>
          Hello, and thanks for visiting!
          <br />
          <br />
          My Vision Directory is an online appointment booking service located at
          myvisiondirectory.com (the &ldquo;Site&rdquo;) which helps you find and book an
          appointment with an optometrist (together with the Site, the &ldquo;Services&rdquo;). In
          these Terms of Use, we&rsquo;ll refer to the optometrists as &ldquo;Eye Doctors.&rdquo;
        </p>
        <p>
          Before using the Services, please read these Terms of Use (these &ldquo;Terms&rdquo;),
          our{" "}
          <a rel="noopener noreferrer" target="_blank" href="/disclaimer/privacy-policy">
            Privacy Policy
          </a>
          , and any other policies and notices on the Site, or in any eye exam locations.
          Collectively, these materials contain terms, rules, and other guidelines related to your
          use of the Services. As a condition to your access to or use of the Services, you consent
          to be bound by these Terms, so please do not use the Services if you do not agree with
          all of these Terms. Some parts of the Services may have other terms, guidelines, or
          rules, and if you use those parts, those additional terms will also apply. Your use of
          the Services, and our provision of the Services to you, constitutes an agreement between
          you and My Vision Directory to be bound by each of the terms, guidelines, and rules
          incorporated into these Terms. Because these Terms are legally binding, we want to make
          sure you understand them (like really understand them), so if you have any questions,
          contact us at <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>.
        </p>
        <p>
          THESE TERMS PROVIDE THAT ALL DISPUTES BETWEEN YOU AND MY VISION DIRECTORY RELATING TO
          THESE TERMS OR YOUR USE OF THE SERVICES WILL BE RESOLVED BY BINDING ARBITRATION. YOU
          AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS UNDER THESE
          TERMS, EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT. YOUR RIGHTS WILL BE
          DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY, AND YOUR CLAIMS CANNOT BE
          BROUGHT AS A CLASS ACTION. PLEASE REVIEW SECTION 13 (&ldquo;DISPUTE RESOLUTION AND
          ARBITRATION&rdquo;) FOR THE DETAILS REGARDING YOUR AGREEMENT TO ARBITRATE ANY DISPUTES
          WITH MY VISION DIRECTORY.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>
          1. We Reserve the Right to Update and Revise These Terms of Use at Any Time
        </h2>
        <p>
          We will sometimes review these Terms to make sure they accurately reflect developments in
          current law and our business operations. We reserve the right to update and revise these
          Terms at any time upon 7 days&rsquo; notice. We&rsquo;ll provide notice by changing the
          &ldquo;Effective Date&rdquo; at the top of this page so you can tell if these Terms have
          changed since your last visit. Please review these Terms regularly because 7 days after
          we post any changes, your continued use of the Services constitutes your acceptance of
          the revised Terms.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>2. Eligibility</h2>
        <p>
          We only permit individuals who are at least 18 years old and can form legally binding
          contracts to use the Services. However, even non-adults deserve great eye health. You can
          use the Services if you are under 18, but only if you are at least 13 years old and only
          if a parent or legal guardian who is at least 18 years old supervises you. In all cases,
          the adult will be the user and is responsible for any and all activity. If you are
          younger than 13 years old, you may not use the Services (although your parents can still
          book an eye exam for you).
        </p>
        <p>
          Your use of the Services means you represent and warrant that you meet all eligibility
          requirements we outline in these Terms. However, we may refuse to let certain people
          access or use the Services. We may also change our eligibility criteria.
        </p>
        <p>
          We offer the Services only for personal, noncommercial use, and not for the use or
          benefit of any third party (unless you are a parent or legal guardian using the Services
          for your minor child).
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>3. Privacy</h2>
        <p>
          Our{" "}
          <a rel="noopener noreferrer" target="_blank" href="/disclaimer/privacy-policy">
            Privacy Policy
          </a>{" "}
          details how we collect and use your information. Please review it if you would like to
          know more about how we collect and use your information.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>4. Making An Eye Exam Appointment</h2>
        <ol type="a">
          <li>
            <p>
              Booking An Eye Exam. My Vision Directory allows you to find Eye Doctors near you and
              book an appointment at their offices for an eye exam. To make an appointment, please
              select your desired eye exam location. Once you&rsquo;ve chosen a location, click the
              &ldquo;Book Exam&rdquo; button to select the type of appointment you want to book and
              book your appointment on one of the available dates and times.&nbsp;&nbsp;
            </p>
          </li>
          <li>
            <p>
              Patient Information. We&rsquo;ll need your name, email address, telephone number, and
              date of birth to book your appointment and send you an appointment confirmation and
              reminders.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Changing or Cancelling an Appointment. To change or cancel an appointment, you can
              click on the cancellation link in the confirmation or reminder emails, or reach out
              to the eye doctor directly.&nbsp; The eye doctor&rsquo;s phone number can be found in
              the appointment confirmation and reminder emails and also on the webpage for the eye
              exam location, which can be found here.
            </p>
          </li>
          <li>
            <p>Language. Presently, our booking process only supports the English language.</p>
          </li>
        </ol>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>5. Communicating with My Vision Directory</h2>
        <ol type="a">
          <li>
            <p>
              Phone Calls and Emails.&nbsp; By using the Services, you agree to receive appointment
              confirmations, appointment reminders, and other related communication by phone call
              and email.
            </p>
          </li>
          <li>
            <p>
              Text Messages. By opting-in to receive text messages from us or by sending us an
              initial text message, you agree to receive text messages (a.k.a. SMS messages) from
              My Vision Directory, some of which may be considered marketing and may be sent using
              an autodialer. You also represent that you are the owner or authorized user of the
              mobile device you use to subscribe for the mobile service and that you are authorized
              to approve the applicable charges. You&rsquo;ll be responsible for all messaging and
              data charges that may apply. You can opt-out of receiving text messages from us at
              any time by texting &ldquo;STOP&rdquo; from the mobile device receiving the messages.
              For additional help, you can contact us at{" "}
              <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>. And
              don&rsquo;t worry - you can book an appointment without agreeing to receive texts
              from us.
            </p>
          </li>
          <li>
            <p>
              Security. We take your privacy very seriously, but the transmission of data over the
              Internet and mobile networks isn&rsquo;t 100% secure. Text messages and emails you
              send to or receive from My Vision Directory are not encrypted, which means that
              they&rsquo;re vulnerable to interception by third parties. If you choose to send or
              receive financial information, information about your health (including your
              prescription information), your insurance identification number, or any other
              sensitive information by text or email, you do so at your own risk. By texting us or
              requesting that we text you, you consent to receiving unencrypted text messages from
              My Vision Directory. Likewise, by emailing us or requesting that we email you, you
              consent to receiving unencrypted emails messages from us. Worried about texting or
              sending anything sensitive? Just email us at{" "}
              <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a> and
              we&rsquo;ll schedule a time to give you a call to discuss.
            </p>
          </li>
        </ol>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>6. Intellectual Property</h2>
        <p>
          Imitation may be the sincerest form of flattery, but that doesn&rsquo;t mean you can take
          any of our intellectual property. All of the content that appears on the Services,
          including all designs, illustrations, icons, photographs, video clips and written
          materials, as well as the compilation of the website, Services, and other materials, are
          the exclusive property of My Vision Directory or our licensors and is protected by United
          States and international copyright laws. All trademarks, service marks, and trade names
          that appear on the Services and the overall &ldquo;look and feel&rdquo; of the Site and
          the Services (collectively the &ldquo;Marks&rdquo;) are proprietary to My Vision
          Directory, or the respective owners of such Marks. You may not display, reproduce, or
          otherwise use the content or materials on the Services, including the Marks, without
          first receiving written consent from us. You may not remove or otherwise modify any legal
          or trademark notices from any content we make available through the Services. Any
          unauthorized use of any content or materials on the Services is strictly prohibited and
          violates copyright, trademark, and/or other intellectual property laws, and/or the laws
          of privacy, publicity, and/or communications regulations and statutes.
        </p>
        <p>
          If you would like to request authorization to use the materials or content on the
          Services, please contact us at{" "}
          <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>7. Right to Use the Services</h2>
        <p>
          Subject to your complete and ongoing compliance with these Terms, you have the right to
          access and use the Services solely for your personal, non-commercial use. This right will
          allow you to use and enjoy the benefit of the Services as we provide them, in the manner
          we permit through these Terms. We don&rsquo;t think you would need to do much else.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>8. Restrictions on Your Use of the Services</h2>
        <p>
          Just so we&rsquo;re clear, however, these rights do not allow you to do any of the
          following:
        </p>
        <ul css={disclaimerStyles.unordered}>
          <li>
            <p>
              interfere or attempt to interfere with the proper working of the Services or any
              activities conducted on the Services.
            </p>
          </li>
          <li>
            <p>
              take any action that imposes or may impose (as determined by us in our sole
              discretion) an unreasonable or disproportionately large load on our or our third
              party providers&rsquo; infrastructure.
            </p>
          </li>
          <li>
            <p>resell or make any commercial use of the Services or any of the Service content.</p>
          </li>
          <li>
            <p>
              modify, adapt, translate, reverse engineer, decompile, disassemble, or convert into
              human readable form any of the Service content not intended to be so read, including
              using or directly viewing the underlying HTML or other code from the Services except
              as interpreted and displayed in a web browser.
            </p>
          </li>
          <li>
            <p>
              copy, imitate, mirror, reproduce, distribute, publish, download, display, perform,
              post, store, or transmit any of the Service content, including any Marks, in any form
              or by any means, including electronic, mechanical, photocopying, recording or
              otherwise.
            </p>
          </li>
          <li>
            <p>
              use any manual or automated software devices or other processes (including data
              mining, bots, spiders, automated tools or similar data gathering and extraction
              methods) to &ldquo;crawl&rdquo; or &ldquo;spider&rdquo; any page of the Services or
              to collect any information from the Services or any user of the Services.
            </p>
          </li>
          <li>
            <p>
              harvest or scrape any content from the Services, or using other automated or manual
              means to take our content without our prior consent.
            </p>
          </li>
          <li>
            <p>
              bypass, circumvent, or attempt to bypass or circumvent any feature of the Services or
              any measures we may use to prevent or restrict access to the Services, including
              other accounts, computer systems or networks connected to the Services.
            </p>
          </li>
          <li>
            <p>run any form of auto-responder or &ldquo;spam&rdquo; on the Services.</p>
          </li>
          <li>
            <p>
              otherwise take any action in violation of these Terms or our{" "}
              <a rel="noopener noreferrer" target="_blank" href="/disclaimer/privacy-policy">
                Privacy Policy
              </a>
              .
            </p>
          </li>
        </ul>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>WARRANTY DISCLAIMER</h2>
        <ol type="a">
          <li>
            <p>
              You understand and agree that we have no control over, and no duty to take any action
              regarding:
              <ul css={disclaimerStyles.unordered}>
                <li>
                  <p>which users access the Services.</p>
                </li>
                <li>
                  <p>what content you access via the Services.</p>
                </li>
                <li>
                  <p>what effects the content may have on you.</p>
                </li>
                <li>
                  <p>how you may interpret or use the content.</p>
                </li>
                <li>
                  <p>what actions you may take as a result of your exposure to the content.</p>
                </li>
              </ul>
            </p>
          </li>
          <li>
            <p>
              The Services may contain, or direct you to websites containing, information that some
              people may find offensive or inappropriate. We make no representations concerning any
              content contained in or accessed through the Services, and, to the fullest extent
              permitted by law, we will not be responsible or liable for the accuracy, copyright
              compliance, legality, or decency of material contained in or accessed through the
              Services. Your interactions with organizations and/or individuals found on or through
              the Services, including payment and delivery of goods or services, and any other
              terms, conditions, warranties, or representations associated with such dealings, are
              solely between you and such organizations and/or individuals.
            </p>
          </li>
          <li>
            <p>
              THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND WITHOUT WARRANTY OF ANY KIND. TO
              THE MAXIMUM EXTENT PERMITTED BY LAW, MY VISION DIRECTORY DISCLAIMS ALL
              REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE SERVICES OR ANY
              CONTENT ON THE SERVICES, WHETHER PROVIDED OR OWNED BY MY VISION DIRECTORY OR BY ANY
              THIRD PARTY, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND ANY
              IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN
              TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. IN ADDITION, MY VISION DIRECTORY DOES
              NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA THE SERVICES IS ACCURATE,
              COMPLETE, AVAILABLE, CURRENT, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
              THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS.
            </p>
          </li>
        </ol>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>10. LIMITATION OF LIABILITY</h2>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MY VISION DIRECTORY
          OR ANYONE REPRESENTING MY VISION DIRECTORY BE LIABLE TO YOU UNDER CONTRACT, TORT, STRICT
          LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY, FOR (A) ANY LOST PROFITS,
          DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND
          WHATSOEVER RESULTING FROM: (I) YOUR ACCESS TO, USE OF, OR RELIANCE ON ANY CONTENT
          PROVIDED THROUGH THE SERVICES OR ANY ERRORS OR OMISSIONS IN ANY CONTENT; (II) ANY
          UNAUTHORIZED ACCESS TO OR USE OF THE SERVICES OR MY VISION DIRECTORY&rsquo;S SECURE
          SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN; (III) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES; OR
          (IV) ANY BUGS, VIRUSES, TROJAN HORSES OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH
          OUR SERVICES BY ANY THIRD PARTY (REGARDLESS OF THE SOURCE OF ORIGINATION) OR (B) ANY
          DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE GREATER OF (I) FEES PAID TO US FOR
          THE APPLICABLE PRODUCTS; OR (II) $500.00.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THESE LIMITATIONS APPLY REGARDLESS OF
          LEGAL THEORY, WHETHER BASED ON TORT, STRICT LIABILITY, BREACH OF CONTRACT, BREACH OF
          WARRANTY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT MY VISION DIRECTORY WAS ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>11. Termination</h2>
        <p>
          We really hope we won&rsquo;t ever have to do this, but we reserve the right to terminate
          your license to use the Services or block or prevent your access to the Services, without
          providing you with notice or reason. In the event of termination, your obligations under
          these Terms will still continue.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>12. Governing Law</h2>
        <p>
          No matter where you&rsquo;re located, the laws of the State of New York will govern these
          Terms and the relationship between you and My Vision Directory as if you signed or
          otherwise agreed to these Terms in New York, without regard to New York&rsquo;s conflicts
          of laws rules. If any provisions of these Terms are inconsistent with any applicable law,
          those provisions will be superseded and/or modified only to the extent such provisions
          are inconsistent. If a lawsuit or court proceeding is permitted under these Terms, then
          the parties agree to submit to the federal or state courts in New York County, New York
          for exclusive jurisdiction for the purpose of litigating any dispute arising out of or
          related to your use of the Services or your breach of these Terms.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>13. Dispute Resolution and Arbitration</h2>
        <ol type="a">
          <li>
            <p>
              Generally. In the interest of resolving disputes between you and My Vision Directory
              in the most expedient and cost effective manner, you and My Vision Directory agree
              that every dispute arising out of or in connection with these Terms or your use of
              the Services will be resolved by binding arbitration. Arbitration is less formal than
              a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury,
              may allow for more limited discovery than in court, and may be subject to very
              limited review by courts. Arbitrators can award the same damages and relief that a
              court can award. This agreement to arbitrate disputes includes all claims arising out
              of or relating to any aspect of these Terms or your use of the Services, whether
              based in contract, tort, statute, fraud, misrepresentation, or any other legal
              theory, and regardless of whether a claim arises during or after the termination of
              these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND MY
              VISION DIRECTORY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
              CLASS ACTION.
            </p>
          </li>
          <li>
            <p>
              Exceptions. Despite the provisions of Section 13(a) nothing in these Terms will be
              deemed to waive, preclude, or otherwise limit the right of either party to: (i) bring
              an individual action in small claims court; (ii) pursue an enforcement action through
              the applicable federal, state, or local agency if that action is available; (iii)
              seek injunctive relief in a court of law; or (iv) to file suit in a court of law to
              address an intellectual property infringement claim.
            </p>
          </li>
          <li>
            <p>
              Arbitrator. Any arbitration between you and My Vision Directory will be settled under
              the Federal Arbitration Act, and governed by the Commercial Dispute Resolution
              Procedures and the Supplementary Procedures for Consumer Related Disputes
              (collectively, &ldquo;AAA Rules&rdquo;) of the American Arbitration Association
              (&ldquo;AAA&rdquo;), as modified by these Terms, and will be administered by the AAA.
              The AAA Rules and filing forms are available online at{" "}
              <a rel="noopener noreferrer" target="_blank" href="https://www.adr.org/">
                www.adr.org
              </a>
              , by calling the AAA at 1-800-778-7879, or by contacting My Vision Directory. The
              arbitrator has exclusive authority to resolve any dispute relating to the
              interpretation, applicability, or enforceability of this binding arbitration
              agreement.
            </p>
          </li>
          <li>
            <p>
              Notice; Process. A party who intends to seek arbitration must first send a written
              notice of the dispute to the other party by certified U.S. Mail or by Federal Express
              (signature required) or, only if such other party has not provided a current physical
              address, then by electronic mail (&ldquo;Notice&rdquo;). My Vision Directory's
              address for Notice is: My Vision Directory, 233 Spring Street, 6th Floor East, New
              York, NY 10013, Attn: Legal Department. The Notice must: (i) describe the nature and
              basis of the claim or dispute; and (ii) set forth the specific relief sought
              (&ldquo;Demand&rdquo;). The parties will make good faith efforts to resolve the claim
              directly, but if the parties do not reach an agreement to do so within 30 days after
              the Notice is received, you or My Vision Directory may commence an arbitration
              proceeding. During the arbitration, the amount of any settlement offer made by you or
              My Vision Directory must not be disclosed to the arbitrator until after the
              arbitrator makes a final decision and award, if any. If the dispute is finally
              resolved through arbitration in your favor, we will pay you the highest of the
              following: (i) the amount awarded by the arbitrator, if any; (ii) the last written
              settlement amount offered by My Vision Directory in settlement of the dispute prior
              to the arbitrator&rsquo;s award; or (iii) $1,000.
            </p>
          </li>
          <li>
            <p>
              Fees. If you commence arbitration in accordance with these Terms, we will reimburse
              you for your payment of the filing fee, unless your claim is for more than $10,000,
              in which case the payment of any fees will be decided by the AAA Rules. Any
              arbitration hearing will take place at a location to be agreed upon in New York
              County, New York, but if the claim is for $10,000 or less, you may choose whether the
              arbitration will be conducted: (i) solely on the basis of documents submitted to the
              arbitrator; (ii) through a non-appearance based telephone hearing; or (iii) by an
              in-person hearing as established by the AAA Rules in the county (or parish) of your
              billing address. If the arbitrator finds that either the substance of your claim or
              the relief sought in the Demand is frivolous or brought for an improper purpose (as
              measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then
              the payment of all fees will be governed by the AAA Rules. In that case, you agree to
              reimburse My Vision Directory for all monies previously disbursed by it that are
              otherwise your obligation to pay under the AAA Rules. Regardless of the manner in
              which the arbitration is conducted, the arbitrator must issue a reasoned written
              decision sufficient to explain the essential findings and conclusions on which the
              decision and award, if any, are based. The arbitrator may make rulings and resolve
              disputes as to the payment and reimbursement of fees or expenses at any time during
              the proceeding and upon request from either party made within 14 days of the
              arbitrator&rsquo;s ruling on the merits.
            </p>
          </li>
          <li>
            <p>
              No Class Actions. YOU AND MY VISION DIRECTORY AGREE THAT EACH MAY BRING CLAIMS
              AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless
              both you and My Vision Directory agree otherwise, the arbitrator may not consolidate
              more than one person&rsquo;s claims, and may not otherwise preside over any form of a
              representative or class proceeding.
            </p>
          </li>
          <li>
            <p>
              Modifications to this Arbitration Provision. If we make any future change to this
              arbitration provision, other than a change to our address for Notice, you may reject
              the change by sending us written notice to our address for Notice within 30 days of
              the change, in which case this arbitration provision, as in effect immediately prior
              to the changes you rejected, will survive.
            </p>
          </li>
          <li>
            <p>
              Enforceability. If Section 13(f) is found to be unenforceable or if the entirety of
              this Section 13(f) is found to be unenforceable, then the entirety of this Section 13
              will be null and void and, in that case, the parties agree that the exclusive
              jurisdiction and venue described in Section 13 will govern any action arising out of
              or related to these Terms.
            </p>
          </li>
        </ol>
      </div>

      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>14. Severability</h2>
        <p>
          If it turns out that any part of these Terms are invalid, void, or for any reason
          unenforceable, that term will be deemed severable and limited or eliminated to the
          minimum extent necessary. The limitation or elimination of the term will not affect any
          other terms. NOTHING IN THESE TERMS WILL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT
          APPLY TO YOU.
        </p>
      </div>

      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>15. Consent to Electronic Communications</h2>
        <p>
          By using the Services, you consent to receiving certain electronic communications from us
          as further described in our{" "}
          <a rel="noopener noreferrer" target="_blank" href="/disclaimer/privacy-policy">
            Privacy Policy
          </a>
          . Please read our{" "}
          <a rel="noopener noreferrer" target="_blank" href="/disclaimer/privacy-policy">
            Privacy Policy{" "}
          </a>{" "}
          to learn more about our electronic communications practices. You agree that any notices,
          agreements, disclosures, or other communications that we send to you electronically will
          satisfy any legal communication requirements, including that those communications be in
          writing.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>16. Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and My Vision Directory and
          supersede all prior or contemporaneous communications and proposals, whether electronic,
          oral, or written, with respect to these Services. Any rights not expressly granted herein
          are reserved.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>17. Miscellaneous</h2>
        <p>
          We take our commitment to customers seriously, and we&rsquo;ll do what we can for you.
          However, sometimes things may come up that are outside of our control. We will not be
          liable for any failure to perform any of our obligations stated in these Terms if the
          failure results from a cause beyond our reasonable control, including mechanical,
          electronic or communications failure or degradation.
        </p>
        <p>
          You cannot assign, transfer or sublicense these Terms without first obtaining our
          consent. We may assign, transfer, or delegate any of our rights and obligations without
          your consent. These Terms do not create any agency, partnership, joint venture, or
          employment relationship, and neither party has any authority to bind the other in any
          respect.
        </p>
        <p>
          If we do not enforce any part of these Terms, it does not mean we give up the right to
          later enforce that or any other part of these Terms. In order for any waiver of
          compliance with these Terms to be binding, we must provide you with written notice of
          such waiver through one of our authorized representatives.
        </p>
        <p>
          The section and paragraph headings in these Terms are for convenience only and do not
          affect their interpretation.
        </p>
      </div>
    </div>
    <Footer />
  </Fragment>
);

export default TermsOfUse;
