/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";

import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";

import disclaimerStyles from "styles/disclaimers";

// tslint:disable: strict-type-predicates
// tslint:disable: strict-comparisons
const NoticeOfPrivacy = (): JSX.Element => (
  <Fragment>
    <Header />
    <div css={disclaimerStyles.container}>
      <div css={disclaimerStyles.intro}>
        <h1>Notice of Privacy Practices</h1>
        <h1>Effective Date: December 1, 2019</h1>
        <p>
          THIS NOTICE DESCRIBES HOW YOUR HEALTH INFORMATION MAY BE USED AND DISCLOSED, AND HOW YOU
          CAN ACCESS THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.
        </p>
        <p>
          My Vision Directory takes the confidentiality of your health information very seriously.
          We are required by law to provide you with this Notice of Privacy Practices
          (&ldquo;Notice&rdquo;) and follow the terms of this Notice while it is in effect. This
          Notice is a summary of your privacy rights, and your use of My Vision Directory&rsquo;s
          Services evidences your acceptance of the terms of this Notice. &ldquo;Services&rdquo;
          means any of the services we offer, including helping you find and book an appointment
          with an optometrist. In this Notice, &ldquo;health information&rdquo;, &ldquo;protected
          health information&rdquo;, and &ldquo;PHI&rdquo; refer to individually identifiable
          health information that we obtain from you in connection with the Services.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>1. My Vision Directory&rsquo;s use and disclosure of PHI</h2>
        <p>
          Generally, My Vision Directory uses and discloses your PHI for the normal business
          activities that fall in the categories of treatment, payment, and healthcare operations,
          in accordance with the Health Insurance Portability and Accountability Act of 1996
          (&ldquo;HIPAA&rdquo;). Below are a few examples of those activities (but note that not
          every use or disclosure that falls within each category is included!).
        </p>
        <p>
          Treatment. My Vision Directory keeps a record of the PHI you provide to us in the course
          of using the Services. This record includes the information you provide when booking an
          appointment and other information we learn about your health through our provision of the
          Services. We may disclose this information so that doctors, nurses, other optical
          dispensers, and other entities like laboratories can meet your healthcare needs.
        </p>
        <p>
          Payment. My Vision Directory keeps a record of the PHI you provide to us in the course of
          using the Services so that, if applicable, you (or your vision insurance plan or another
          third party) can pay us or the optometrist for your exam. If applicable, we may share
          information with your vision insurance plan.
        </p>
        <p>
          Healthcare Operations. My Vision Directory uses PHI to improve the Services and train
          staff, and for business management, quality improvement, performance evaluation,
          marketing activities permitted under HIPAA, customer service activities, and other
          internal business purposes.
        </p>
        <p>My Vision Directory may also use or disclose your PHI to:</p>
        <ul css={disclaimerStyles.unordered}>
          <li>
            <p>Comply with federal, state, or local laws.</p>
          </li>
          <li>
            <p>
              Assist in public health activities, such as tracking diseases or medical devices.
            </p>
          </li>
          <li>
            <p>Inform authorities in order to protect victims of abuse or neglect.</p>
          </li>
          <li>
            <p>
              Comply with federal and state health oversight activities, such as fraud
              investigations.
            </p>
          </li>
          <li>
            <p>
              Respond to law enforcement officials or to judicial orders, subpoenas, or other
              process.
            </p>
          </li>
          <li>
            <p>
              Inform coroners, medical examiners, and funeral directors of information necessary
              for them to fulfill their duties.
            </p>
          </li>
          <li>
            <p>Facilitate organ and tissue donation or procurement.</p>
          </li>
          <li>
            <p>
              Conduct research (following internal review protocols to ensure the balancing of
              privacy and research needs).
            </p>
          </li>
          <li>
            <p>Avert a serious threat to health or safety.</p>
          </li>
          <li>
            <p>
              Assist in specialized government functions, such as national security, intelligence,
              and protective services.
            </p>
          </li>
          <li>
            <p>Inform military and veteran authorities if you are an armed forces member.</p>
          </li>
          <li>
            <p>Inform a correctional institution if you are an inmate.</p>
          </li>
          <li>
            <p>
              Inform workers&rsquo; compensation carriers or your employer if you are injured at
              work, as authorized by and as necessary to comply with relevant laws.
            </p>
          </li>
          <li>
            <p>Tell you about health-related products and services.</p>
          </li>
          <li>
            <p>Send appointment confirmations and reminders.</p>
          </li>
          <li>
            <p>
              Communicate with individuals, such as friends and family, who are involved in your
              care or involved in the payment for that care.
            </p>
          </li>
          <li>
            <p>
              Communicate within our organization for treatment, payment, or healthcare operations.
            </p>
          </li>
          <li>
            <p>
              Communicate with other providers, health plans, or their related entities for their
              treatment, payment, or healthcare operations activities.
            </p>
          </li>
          <li>
            <p>
              Provide information to other third parties with whom we do business in order to allow
              those third parties to provide services to us or on our behalf. (Don&rsquo;t
              worry&mdash;in these situations, we require third parties to provide us with
              assurances that they will safeguard your information.)
            </p>
          </li>
        </ul>
        <p>
          Any other uses or disclosures not set forth in this Notice may only be performed with
          your written permission. We will also obtain your written permission before we use or
          disclose your PHI for any marketing purposes that are unrelated to the Services we
          provide. You may revoke your permission, in writing, at any time. If you do so, we will
          no longer use or disclose your PHI for the reasons covered by your written permission,
          but note that we are unable to take back any disclosures we have already made with your
          permission, and that we are required to retain our records of the care that we&rsquo;ve
          provided to you.
        </p>
      </div>

      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>
          2. My Vision Directory&rsquo;s responsibilities with respect to your PHI
        </h2>
        <p>My Vision Directory is required by HIPAA to:</p>
        <ul css={disclaimerStyles.unordered}>
          <li>
            <p>Maintain the privacy of your PHI.</p>
          </li>
          <li>
            <p>Provide this Notice setting forth our duties and privacy practices.</p>
          </li>
          <li>
            <p>Abide by the terms of the version of this Notice currently in effect.</p>
          </li>
          <li>
            <p>Tell you if there has been a security breach that compromises your PHI.</p>
          </li>
        </ul>
        <p>
          Please note that some states have laws that may require that we apply extra protections
          to some of your health information.
        </p>
      </div>

      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>3. Your rights with respect to your PHI</h2>
        <p>
          You have the following rights with respect to your PHI maintained by My Vision Directory.
        </p>
        <ul css={disclaimerStyles.unordered}>
          <li>
            <p>
              Inspect and copy. You have the right to inspect and copy certain portions of your
              PHI. We may, in certain limited circumstances, deny your request to inspect or copy
              your PHI. If we do so, we will inform you of the reason for the denial.
            </p>
          </li>
          <li>
            <p>
              Amend. You have the right to amend your PHI if you feel that it is incorrect or
              incomplete. We may, in certain limited circumstances, deny your request to amend your
              PHI. If we do so, you may submit a statement of disagreement for inclusion in your
              records.
            </p>
          </li>
          <li>
            <p>
              Accounting of disclosures. You have the right to an accounting of our disclosures of
              your PHI made over the past six years. This right does not apply to disclosures made
              for treatment, payment, or healthcare operations; disclosures made to you about your
              treatment; disclosures made pursuant to an authorization; and certain other
              disclosures.
            </p>
          </li>
          <li>
            <p>
              Restrictions on disclosure. You have the right to request restrictions on how we use
              or disclose your PHI. We&rsquo;re not required to comply with such requests except
              for requests pertaining to disclosure to a vision insurance plan or other payor for
              payment or certain care operations that relate to an item or service for which you
              have paid out of pocket in full. If we agree to any such request, we will comply with
              your request except in certain emergency situations or as required by law.
            </p>
          </li>
          <li>
            <p>
              Communication. You have the right to request that we communicate with you at a
              specific telephone number or address.
            </p>
          </li>
          <li>
            <p>
              Paper copy. You have the right to obtain a paper copy of this Notice (even if
              you&rsquo;re currently reading it electronically!).
            </p>
          </li>
        </ul>
      </div>

      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>4. Changes to this Notice</h2>
        <p>
          We occasionally review this Notice to make sure it complies with applicable laws and
          conforms to changes in our business. We may need to update this Notice, and we reserve
          the right to do so at any time. If we change the terms of this Notice, the new terms will
          apply to all PHI that we maintain, including PHI that you provided to us before such
          changes were made. We will post the new Notice on our websites and mobile applications,
          and will update the &ldquo;Effective Date&rdquo; at the top of this page so you can tell
          if it has changed since your last visit. We will make the new Notice available upon
          request. Your continued use of the Services constitutes your acceptance of the terms of
          such revised Notice.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>5. Complaints</h2>
        <p>
          If you believe that your privacy has been violated or that My Vision Directory has not
          followed its obligations under HIPAA, you may file a complaint with us or with the
          Secretary of Health and Human Services. We will not retaliate against you or penalize you
          for filing any such complaint.
        </p>
        <p>
          To file a complaint with us, email{" "}
          <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a> or write to
          233 Spring Street, 6th floor East, New York City, NY 10013, Attn: Legal Department.
        </p>
        <p>
          To file a complaint with the Secretary of Health and Human Services, call 877.696.6775 or
          write to 200 Independence Avenue S.W., Washington, D.C. 20201.
        </p>
      </div>
      <div css={disclaimerStyles.section}>
        <h2 css={disclaimerStyles.h2}>6. Contacting My Vision Directory</h2>
        <p>
          To exercise any of your rights set forth in this Notice, or for more information about My
          Vision Directory&rsquo;s privacy practices, email{" "}
          <a href="mailto:help@myvisiondirectory.com">help@myvisiondirectory.com</a>, write to 233
          Spring Street, 6th floor East, New York City, NY 10013, Attn: Legal Department, or call
          888.492.7297 and ask to speak with the Legal Department.
        </p>
      </div>
    </div>
    <Footer />
  </Fragment>
);

export default NoticeOfPrivacy;
