/** @jsx jsx */
import { jsx } from "@emotion/core";
import { format } from "date-fns";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IConfirmationProps, IDoctor } from "types";
import { formatPhoneNumber } from "ui/clinic-page/components/OfficeDetails";

import confirmationStyles from "styles/confirmation";

const timeAdjust = (time: string): string => {
  const hour = +time.split(":")[0];
  const minutes = time.split(":")[1];
  const amPm = hour >= 12 ? "p.m." : "a.m.";
  const formattedHour = hour > 12 ? hour - 12 : hour;
  return `${formattedHour}:${minutes} ${amPm}`;
};

const formatAddress = (officeAddress: string): JSX.Element => {
  const hasParenthesis = officeAddress.indexOf("(") > 0;
  if (hasParenthesis) {
    const parenthesis = officeAddress.indexOf("(");
    const wp = officeAddress.slice(parenthesis);
    return (
      <span css={confirmationStyles.detailsText}>
        {officeAddress.slice(0, parenthesis)}
        <br />
        {wp}
      </span>
    );
  }
  return <span css={confirmationStyles.detailsText}>{officeAddress}</span>;
};

const Confirmation = ({
  appointment,
  config,
  customer,
  doctorID,
  doctorsArray,
  officeAddress,
  officeCity,
  officePhoneNumber,
  officeState,
  officeZipcode,
  officeShortName,
}: IConfirmationProps): JSX.Element => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });

  const examDateString = format(customer.examTime.date, "MMMM D, YYYY");
  const examTimeString = timeAdjust(customer.examTime.time);
  const doctor = doctorsArray.find((doctor: IDoctor) => doctor.id === doctorID);
  const isIntakeFormPilotFacility = config && config.has_intake_form;

  let intakeFormLink = `/intake-form/start?docid=${appointment.doctor}&hash=${appointment.cancel_signature}` +
    `&apptid=${appointment.id}`;
  if (officeShortName) {
    intakeFormLink += `&shortName=${officeShortName}`;
  }
  return (
    <div css={confirmationStyles.container}>
      <div css={confirmationStyles.confirmationDetailsContainer}>
        <div>
          <h1 style={{ margin: "40px 0 20px" }}>
            See you {examDateString} at {examTimeString}!
          </h1>
          <p css={confirmationStyles.confirmationText}>
            Your appointment is confirmed at {officeAddress}.
          </p>
          {isIntakeFormPilotFacility && (
            <p css={confirmationStyles.confirmationText}>
              For your health, we’ll be minimizing contact at your appointment, so please fill out
              our digital intake form before you arrive.
            </p>
          )}
        </div>
      </div>
      {isIntakeFormPilotFacility && (
        <div css={confirmationStyles.blueButtonContainer}>
          <Link to={intakeFormLink}>
            Begin intake form
          </Link>
        </div>
      )}
      <div css={confirmationStyles.confirmationDetailsContainerContent}>
        <div css={confirmationStyles.details}>
          <div css={confirmationStyles.detailContainer}>
            <div>
              <h4 css={confirmationStyles.detailsHeader}>Appointment details</h4>
            </div>
            <span css={confirmationStyles.detailsTextBold}>{customer.exam.examType}</span>
            <span css={confirmationStyles.detailsText}>{examDateString}</span>
            <span css={confirmationStyles.detailsText}>{examTimeString}</span>
          </div>
          <div css={confirmationStyles.detailContainer}>
            {doctor && (
              <span css={confirmationStyles.detailsTextBold}>{`${doctor.first_name} ${
                doctor.last_name
              }, ${doctor.suffix || "OD"}`}</span>
            )}
            {formatAddress(officeAddress)}
            <span css={confirmationStyles.detailsText}>
              {officeCity}, {officeState} {officeZipcode}
            </span>
            {!/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent,
            ) ? (
              <span css={confirmationStyles.detailsText}>
                {formatPhoneNumber(officePhoneNumber) || "(888) 492-7297"}
              </span>
            ) : (
              <a
                css={confirmationStyles.detailsTextHighlight}
                href={`tel:+${officePhoneNumber || "888-492-7297"}`}
              >
                {formatPhoneNumber(officePhoneNumber)}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
