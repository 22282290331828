import React, { useEffect } from "react";
import { connect } from "react-redux";

import Footer from "shared-ui/Footer";
import FunnelHeader from "shared-ui/FunnelHeader";
import ProgressBar from "shared-ui/ProgressBar";
import { IPersonalInfoProps, IPersonalInfoState, IState } from "types";

import Form from "./components/Form";

const PersonalInfoPage = ({
  appointmentProfileID,
  customerInfo,
  dispatch,
  doctorID,
  errors,
  examDate,
  examDuration,
  examRoom,
  examTime,
  officeID,
  officePhoneNumber,
  step,
}: IPersonalInfoProps): JSX.Element => {
  useEffect(() => {
    dispatch({ payload: "5 - personal info", type: "step" });
    // tslint:disable-next-line: align
  }, []);
  return (
    <>
      <ProgressBar progress={"80%"} />
      <FunnelHeader step={step} />
      <div id="component">
        <div className="funnel">
          <h1>Last step! Enter the patient's information.</h1>
          <Form
            appointmentProfileID={appointmentProfileID}
            customerInfo={customerInfo}
            dispatch={dispatch}
            doctorID={doctorID}
            errors={errors}
            examDate={examDate}
            examTime={examTime}
            examDuration={examDuration}
            examRoom={examRoom}
            officeID={officeID}
            officePhoneNumber={officePhoneNumber}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

const select = (state: IState): IPersonalInfoState => ({
  appointmentProfileID: state.customer.exam.appointmentProfileID,
  customerInfo: state.customer.personalInfo,
  doctorID: state.customer.examTime.doctor,
  errors: state.customer.error,
  examDate: state.customer.examTime.date,
  examDuration: state.customer.exam.examDuration,
  examRoom: state.customer.examTime.examRoom,
  examTime: state.customer.examTime.time,
  officeID: state.pmp.officeDetails.data.id,
  officePhoneNumber: state.pmp.officeDetails.data.phone_number,
  step: state.funnel.step,
});

export default connect(select)(PersonalInfoPage);
