/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { IProgressProps } from "../types";

const meter = css`
  height: 5px;
  position: relative;
  background: #dfe9f0;
  overflow: hidden;
  span {
    display: block;
    height: 100%;
  }
`;

const ProgressBar = ({ progress }: IProgressProps): JSX.Element => {
  const progressCSS = css`
    @keyframes progressBar {
      0% {
        width: ${progress};
      }
      100% {
        width: 100%;
      }
    }

    background-color: #0089bf;
    animation: progressBar 1s ease-in-out;
    animation-fill-mode: both;
  `;

  return (
    <div css={meter}>
      <span css={{ width: progress }}>
        <span css={progressCSS} />
      </span>
    </div>
  );
};

export default ProgressBar;
