/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import Footer from "./Footer";
import Header from "./Header";

const errorContainer = css`
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  @media screen and (max-width: 900px) {
    h1 {
      font-size: 28px;
    }
  }
`;

const Error = (): JSX.Element => (
  <div>
    <Header />
    <div css={errorContainer}>
      <h1>
        MyVisionDirectory is currently unavailable. <br /> Please try back later.
      </h1>
    </div>
    <Footer />
  </div>
);

export default Error;
