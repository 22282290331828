/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { IFunnelHeaderProps } from "../types";

import BackButton from "./BackButton";
import CloseButton from "./CloseButton";
import BackArrow from "./icons/back-arrow.svg";
import Close from "./icons/close.svg";

const headerContainer = css`
  height: 50px;
  div {
    margin: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

const FunnelHeader = ({ step }: IFunnelHeaderProps): JSX.Element => (
  <div css={headerContainer}>
    <div>
      <BackButton src={BackArrow} />
      <CloseButton src={Close} />
    </div>
  </div>
);

export default FunnelHeader;
