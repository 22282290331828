/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";

import Error from "shared-ui/Error";
import ErrorBooking from "shared-ui/ErrorBooking";
import Footer from "shared-ui/Footer";
import FooterBlank from "shared-ui/FooterBlank";
import Header from "shared-ui/Header";
import Spinner from "shared-ui/Spinner";
import { IConfirmationPageState, IConfirmationProps, IState } from "types";

import Confirmation from "./components/Confirmation";
import Reschedule from "./components/Reschedule";

import confirmationStyles from "styles/confirmation";

const ConfirmationPage = ({
  appointment,
  appointmentPending,
  error,
  officePhoneNumber,
  doctorID,
  doctorsArray,
  ...props
}: IConfirmationProps): JSX.Element => {
  if (appointmentPending) {
    return (
      <div>
        <Spinner />
        <FooterBlank />
      </div>
    );
  }

  if (error) {
    return error.includes("overlap") ? <ErrorBooking /> : <Error />;
  }

  if (!doctorID) {
    return <Error />;
  }

  return (
    <div>
      <Header />
      <div id="component">
        <Confirmation
          appointment={appointment}
          appointmentPending={appointmentPending}
          error={error}
          officePhoneNumber={officePhoneNumber}
          doctorID={doctorID}
          doctorsArray={doctorsArray}
          {...props}
        />
        <div css={confirmationStyles.rescheduleContainer}>
          <Reschedule officePhoneNumber={officePhoneNumber} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const select = (state: IState): IConfirmationPageState => ({
  appointment: state.customer.appointment.data,
  appointmentPending: state.customer.appointment.pending,
  config: state.pmp.officeDetails.data.config,
  customer: state.customer,
  doctorsArray: state.pmp.doctors.data,
  doctorID: state.customer.examTime.doctor,
  error: state.customer.appointment.error,
  officeAddress: state.pmp.officeDetails.data.address,
  officeCity: state.pmp.officeDetails.data.city,
  officePhoneNumber: state.pmp.officeDetails.data.phone_number,
  officeState: state.pmp.officeDetails.data.state,
  officeZipcode: state.pmp.officeDetails.data.zip_code,
  officeShortName: state.pmp.officeDetails.data.location_short_name,
});

export default connect(select)(ConfirmationPage);
