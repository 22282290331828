/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";

import { IExamProps } from "types";
import { format } from "../tools/formatters";

import examsStyles from "styles/funnel/exams";

const Exam = ({
  appointmentProfileID,
  description,
  dispatch,
  priceMin,
  priceMax,
  examDuration,
  examType,
}: IExamProps): JSX.Element => (
  <div css={examsStyles.examContainer}>
    <h2 css={examsStyles.h2}>{examType}</h2>
    <p css={examsStyles.pricing}>{format.getGeneralEyeExamPricing(priceMax, priceMin)}</p>
    <p css={examsStyles.textContainer}>{description}</p>
    <div css={examsStyles.buttonContainer}>
      <Link
        css={examsStyles.button}
        onClick={(): void => {
          const payload = {
            appointmentProfileID,
            examDuration,
            examType,
          };
          dispatch({ type: "exam", payload });
        }}
        to={`/${window.location.pathname.split("/")[1]}/exams-time-page`}
      >
        Select and continue
      </Link>
    </div>
  </div>
);

export default Exam;
