/** @jsx jsx */
import { jsx } from "@emotion/core";
import Footer from "shared-ui/Footer";
import Spinner from "shared-ui/Spinner";
import ProgressBar from "shared-ui/ProgressBar";
import IntakeFormHeader from "shared-ui/IntakeFormHeader";
import { IIntroPageProps } from "types";
import { actions } from "redux/actions/actions";
import intakeFormStyles from "styles/intakeForm";

const IntroPage = ({
  dispatch,
  userFirstName,
  intro,
  isLoading,
  error,
}: IIntroPageProps): JSX.Element => {
  if (intro && !isLoading) {
    // Data comes back as one string
    // Designs asked to split this into two separate lines
    // Decision made to use '\n' as delimited
    const splitSubtext = intro.subtext.split("\n");
    const startIntakeFormPayload = { question: intro.question, response: "OK" };

    return (
      <div css={intakeFormStyles.block}>
        <IntakeFormHeader />
        <ProgressBar progress={"0%"} />
        <div css={intakeFormStyles.container}>
          <h1>{intro.text.replace("{first_name}", userFirstName)}</h1>
          <div css={intakeFormStyles.textContainer}>
            <p>{splitSubtext.reduce((all: any, cur: string) => [...all, <br />, cur])}</p>
          </div>
          <div css={intakeFormStyles.blueButtonContainer}>
            <button onClick={() => actions.startIntakeForm(dispatch, startIntakeFormPayload)}>
              {intro.next_button_text}
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  if (error && !isLoading) {
    return (
      <div css={intakeFormStyles.block}>
        <IntakeFormHeader />
        <ProgressBar progress={"0%"} />
        <div css={intakeFormStyles.container}>
          <h1>Oops! Something went wrong ...</h1>
          <div css={intakeFormStyles.textContainer}>
            <p>{error}</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div css={intakeFormStyles.block}>
      <IntakeFormHeader />
      <ProgressBar progress={"0%"} />
      <Spinner />
      <Footer />
    </div>
  );
};

export default IntroPage;
