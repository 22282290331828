/** @jsx jsx */

import { jsx } from "@emotion/core";
import { connect } from "react-redux";

import { IAllPaymentMethods, IOfficeDetailsProps, IOfficeDetailsState, IState, IAllInsurancePlans } from "types";
import officeDetailsStyles from "styles/officeDetails";

const lister = (arrObj: any, i: number): JSX.Element => (
  <p css={officeDetailsStyles.item} key={arrObj.key}>
    {arrObj.name}
  </p>
);

export const formatPhoneNumber = (phoneNumberString: string): string | null => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

const formatAddress = (officeAddress: string): JSX.Element => {
  const hasParenthesis = officeAddress.indexOf("(") > 0;
  if (hasParenthesis) {
    const parenthesis = officeAddress.indexOf("(");
    const wp = officeAddress.slice(parenthesis);
    return (
      <p css={officeDetailsStyles.address}>
        {officeAddress.slice(0, parenthesis)}
        <br />
        {wp}
      </p>
    );
  }
  return <p css={officeDetailsStyles.address}>{officeAddress}</p>;
};

const OfficeDetails = ({
  doctorInsurances,
  doctorPayments,
  lat,
  lng,
  officeAddress,
  officeFaxNumber,
  officeEmail,
  officeCity,
  officePhoneNumber,
  officeState,
  officeZipcode,
}: IOfficeDetailsProps): JSX.Element => (
  <div css={officeDetailsStyles.officeDetailsContainer}>
    <div css={officeDetailsStyles.officeDetails}>
      <div css={officeDetailsStyles.section}>
        <h4 css={officeDetailsStyles.detailsHeader}>Location</h4>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.google.com/maps/place/${lat}, ${lng}`}
        >
          {formatAddress(officeAddress)}
          <p
            css={officeDetailsStyles.address}
          >{`${officeCity}, ${officeState} ${officeZipcode}`}</p>
        </a>
      </div>
      {(doctorInsurances || []).length > 0 && (
        <div css={officeDetailsStyles.section}>
          <h4 css={officeDetailsStyles.detailsHeader}>In-network Insurances</h4>
          {(doctorInsurances || []).map(lister)}
        </div>
      )}
    </div>
    <div css={officeDetailsStyles.officeDetails}>
      <div css={officeDetailsStyles.section}>
        <h4 css={officeDetailsStyles.detailsHeader}>Contact</h4>
        {!/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? (
          <div>
            <p css={officeDetailsStyles.phone}>
              <b>Phone:</b> {formatPhoneNumber(officePhoneNumber)}
            </p>
            {officeFaxNumber && (
              <p css={officeDetailsStyles.phone}>
                <b>Fax:</b> {formatPhoneNumber(officeFaxNumber)}
              </p>
            )}
            {officeEmail && (
              <p css={officeDetailsStyles.phone}>
                <b>Email:</b> {officeEmail}
              </p>
            )}
          </div>
        ) : (
          <div>
            <b>Phone:</b>
            <a css={officeDetailsStyles.phoneMobile} href={`tel:${officePhoneNumber}`}>
              {formatPhoneNumber(officePhoneNumber)}
            </a>
            {officeFaxNumber && (
              <div>
                <b>Fax:</b>
                <a css={officeDetailsStyles.phoneMobile} href={`tel:${officeFaxNumber}`}>
                  {formatPhoneNumber(officeFaxNumber)}
                </a>
              </div>
            )}
            {officeEmail && (
              <div>
                <b>Email:</b>
                <a css={officeDetailsStyles.phoneMobile} href={`mailto:${officeEmail}`}>
                  {officeEmail}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      <div css={officeDetailsStyles.section}>
        <h4 css={officeDetailsStyles.detailsHeader}>
          {(doctorPayments || []).length > 0 && "Payment Methods"}
        </h4>
        {(doctorPayments || []).map(lister)}
      </div>
    </div>
  </div>
);
const select = (state: IState): IOfficeDetailsState => {
  const practiceDetails = state.pmp.practiceDetails.data;
  const insurancePlans = practiceDetails.insurance_plans || [];
  const paymentMethods = practiceDetails.payment_methods || [];
  const allInsurancePlans = state.pmp.insurancePlans.data || [];
  const allPaymentMethods = state.pmp.paymentMethods.data || [];

  return {
    doctorInsurances: allInsurancePlans.filter((plan:IAllInsurancePlans) => insurancePlans.includes(plan.key)),
    doctorPayments: allPaymentMethods.filter((method:IAllPaymentMethods) => paymentMethods.includes(method.key)),
    lat:
      state.pmp.officeDetails &&
      state.pmp.officeDetails.data.geolocation &&
      state.pmp.officeDetails.data.geolocation.lat,
    lng:
      state.pmp.officeDetails &&
      state.pmp.officeDetails.data.geolocation &&
      state.pmp.officeDetails.data.geolocation.lng,
    officeAddress: state.pmp.officeDetails.data.address,
    officeCity: state.pmp.officeDetails.data.city,
    officeEmail: state.pmp.officeDetails.data.email || "",
    officeFaxNumber: state.pmp.officeDetails.data.fax_number || "",
    officePhoneNumber: state.pmp.officeDetails.data.phone_number || "(888) 492-7297",
    officeState: state.pmp.officeDetails.data.state,
    officeZipcode: state.pmp.officeDetails.data.zip_code,
  }
};
export default connect(select)(OfficeDetails);
