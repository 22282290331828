import {
  IAction,
  IAllOfficesArr,
  IOfficeDetails,
  IOpenSlotsArr,
  IOpenSlotsBoolArr,
  IIntakeFormResponse,
  IIntakeFormSuccessResponse,
  IIntakeFormRulesArr,
  IAppointmentProfilesArr,
  IPracticeDetailsData
} from "types";

export const FETCH_PRACTICE_DETAILS_PENDING = "FETCH_PRACTICE_DETAILS_PENDING"
export const FETCH_PRACTICE_DETAILS_SUCCESS = "FETCH_PRACTICE_DETAILS_SUCCESS"
export const FETCH_PRACTICE_DETAILS_ERROR = "FETCH_PRACTICE_DETAILS_ERROR"

export const FETCH_DOCTORS_PENDING = "FETCH_DOCTORS_PENDING"
export const FETCH_DOCTORS_SUCCESS = "FETCH_DOCTORS_SUCCESS"
export const FETCH_DOCTORS_ERROR = "FETCH_DOCTORS_ERROR"


export const FETCH_ALL_INSURANCE_PLANS_PENDING = "FETCH_ALL_INSURANCE_PLANS_PENDING"
export const FETCH_ALL_INSURANCE_PLANS_SUCCESS = "FETCH_ALL_INSURANCE_PLANS_SUCCESS"
export const FETCH_ALL_INSURANCE_PLANS_ERROR = "FETCH_ALL_INSURANCE_PLANS_ERROR"

export const FETCH_ALL_PAYMENT_METHODS_PENDING = "FETCH_ALL_PAYMENT_METHODS_PENDING"
export const FETCH_ALL_PAYMENT_METHODS_SUCCESS = "FETCH_ALL_PAYMENT_METHODS_SUCCESS"
export const FETCH_ALL_PAYMENT_METHODS_ERROR = "FETCH_ALL_PAYMENT_METHODS_ERROR"

export const FETCH_TIME_SLOTS_PENDING = "FETCH_TIME_SLOTS_PENDING";
export const FETCH_TIME_SLOTS_SUCCESS = "FETCH_TIME_SLOTS_SUCCESS";
export const FETCH_TIME_SLOTS_ERROR = "FETCH_TIME_SLOTS_ERROR";

export const FETCH_TIME_SLOTS_BOOL_PENDING = "FETCH_TIME_SLOTS_BOOL_PENDING";
export const FETCH_TIME_SLOTS_BOOL_SUCCESS = "FETCH_TIME_SLOTS_BOOL_SUCCESS";
export const FETCH_TIME_SLOTS_BOOL_ERROR = "FETCH_TIME_SLOTS_BOOL_ERROR";

export const FETCH_OFFICE_DETAILS_PENDING = "FETCH_OFFICE_DETAILS_PENDING";
export const FETCH_OFFICE_DETAILS_SUCCESS = "FETCH_OFFICE_DETAILS_SUCCESS";
export const FETCH_OFFICE_DETAILS_ERROR = "FETCH_OFFICE_DETAILS_ERROR";

export const CREATE_APPOINTMENT_PENDING = "CREATE_APPOINTMENT_PENDING";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_ERROR = "CREATE_APPOINTMENT_ERROR";

export const FETCH_ALL_OFFICES_PENDING = "FETCH_ALL_OFFICES_PENDING";
export const FETCH_ALL_OFFICES_SUCCESS = "FETCH_ALL_OFFICES_SUCCESS";
export const FETCH_ALL_OFFICES_ERROR = "FETCH_ALL_OFFICES_ERROR";

export const CLEAR_OFFICE_DETAILS_FETCHES = "CLEAR_OFFICE_DETAILS_FETCHES";

export const CANCEL_APPOINTMENT_PENDING = "CANCEL_APPOINTMENT_PENDING";
export const CANCEL_APPOINTMENT_SUCCESS = "CANCEL_APPOINTMENT_SUCCESS";
export const CANCEL_APPOINTMENT_ERROR = "CANCEL_APPOINTMENT_ERROR";

export const FETCH_INTAKE_FORM_PENDING = "FETCH_INTAKE_FORM_PENDING";
export const FETCH_INTAKE_FORM_SUCCESS = "FETCH_INTAKE_FORM_SUCCESS";
export const FETCH_INTAKE_FORM_ERROR = "FETCH_INTAKE_FORM_ERROR";
export const FETCH_INTAKE_FORM_CANCELLED = "FETCH_INTAKE_FORM_CANCELLED_APPOINTMENT";

export const START_INTAKE_FORM = "START_INTAKE_FORM";
export const TRIGGER_NEXT_QUESTION = "TRIGGER_NEXT_QUESTION";
export const TRIGGER_PREVIOUS_QUESTION = "TRIGGER_PREVIOUS_QUESTION";

export const SUBMIT_INTAKE_FORM_PENDING = "SUBMIT_INTAKE_FORM_PENDING";
export const SUBMIT_INTAKE_FORM_SUCCESS = "SUBMIT_INTAKE_FORM_SUCCESS";
export const SUBMIT_INTAKE_FORM_ERROR = "SUBMIT_INTAKE_FORM_ERROR";

export const FETCH_APPOINTMENT_PROFILES_PENDING = "FETCH_APPOINTMENT_PROFILES_PENDING";
export const FETCH_APPOINTMENT_PROFILES_SUCCESS = "FETCH_APPOINTMENT_PROFILES_SUCCESS"
export const FETCH_APPOINTMENT_PROFILES_ERROR = "FETCH_APPOINTMENT_PROFILES_ERROR"

export const COMPLETE_INTAKE_FORM = "COMPLETE_INTAKE_FORM";

export const actionCreators = {
  completeIntakeForm(userResponses: IIntakeFormResponse): IAction {
    return {
      type: COMPLETE_INTAKE_FORM,
      payload: userResponses,
    };
  },

  submitIntakeFormPending(): IAction {
    return {
      type: SUBMIT_INTAKE_FORM_PENDING,
    };
  },

  submitIntakeFormSuccess(successResponse: IIntakeFormSuccessResponse): IAction {
    return {
      payload: successResponse,
      type: SUBMIT_INTAKE_FORM_SUCCESS,
    };
  },

  submitIntakeFormError(error: string): IAction {
    return {
      payload: error,
      type: SUBMIT_INTAKE_FORM_ERROR,
    };
  },

  triggerPreviousQuestion(): IAction {
    return {
      type: TRIGGER_PREVIOUS_QUESTION,
    };
  },

  startIntakeForm(userResponses: IIntakeFormResponse): IAction {
    return {
      type: START_INTAKE_FORM,
      payload: userResponses,
    };
  },

  triggerNextQuestion(
    userResponses: IIntakeFormResponse,
    questionRules: IIntakeFormRulesArr,
  ): IAction {
    return {
      type: TRIGGER_NEXT_QUESTION,
      payload: { questionRules, userResponses },
    };
  },

  fetchAllOfficesPending(): IAction {
    return {
      type: FETCH_ALL_OFFICES_PENDING,
    };
  },

  fetchAllOfficesSuccess(offices: IAllOfficesArr): IAction {
    return {
      payload: offices,
      type: FETCH_ALL_OFFICES_SUCCESS,
    };
  },

  fetchAllOfficesError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_ALL_OFFICES_ERROR,
    };
  },

  fetchDoctorsPending(): IAction {
    return {
      type: FETCH_DOCTORS_PENDING,
    };
  },

  fetchDoctorsSuccess(doctors: any): IAction {
    return {
      payload: doctors,
      type: FETCH_DOCTORS_SUCCESS,
    };
  },

  fetchDoctorsError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_DOCTORS_ERROR,
    };
  },

  fetchTimeSlotsPending(): IAction {
    return {
      type: FETCH_TIME_SLOTS_PENDING,
    };
  },

  fetchTimeSlotsSuccess(timeSlots: IOpenSlotsArr): IAction {
    return {
      payload: timeSlots,
      type: FETCH_TIME_SLOTS_SUCCESS,
    };
  },

  fetchTimeSlotsError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_TIME_SLOTS_ERROR,
    };
  },

  fetchTimeSlotsBoolPending(): IAction {
    return {
      type: FETCH_TIME_SLOTS_BOOL_PENDING,
    };
  },

  fetchTimeSlotsBoolSuccess(openSlots: IOpenSlotsBoolArr): IAction {
    return {
      payload: openSlots,
      type: FETCH_TIME_SLOTS_BOOL_SUCCESS,
    };
  },

  fetchTimeSlotsBoolError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_TIME_SLOTS_BOOL_ERROR,
    };
  },

  fetchAppointmentProfilesPending(): IAction {
    return {
      type: FETCH_APPOINTMENT_PROFILES_PENDING,
    };
  },

  fetchAppointmentProfilesSuccess(appointmentProfiles: any): IAction {
    return {
      payload: appointmentProfiles.appointment_profiles.filter((profile: any) => profile.is_active) as IAppointmentProfilesArr,
      type: FETCH_APPOINTMENT_PROFILES_SUCCESS,
    };
  },

  fetchAppointmentProfilesError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_APPOINTMENT_PROFILES_ERROR,
    };
  },

  fetchOfficeDetailsPending(): IAction {
    return {
      type: FETCH_OFFICE_DETAILS_PENDING,
    };
  },

  fetchOfficeDetailsSuccess(officeDetails: IOfficeDetails): IAction {
    return {
      payload: officeDetails,
      type: FETCH_OFFICE_DETAILS_SUCCESS,
    };
  },

  fetchOfficeDetailsError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_OFFICE_DETAILS_ERROR,
    };
  },

  fetchPracticeDetailsPending(): IAction {
    return {
      type: FETCH_PRACTICE_DETAILS_PENDING,
    };
  },

  fetchPracticeDetailsSuccess(practiceDetails: any): IAction {
    return {
      payload: practiceDetails,
      type: FETCH_PRACTICE_DETAILS_SUCCESS,
    };
  },

  fetchPracticeDetailsError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_PRACTICE_DETAILS_ERROR,
    };
  },


  fetchAllInsurancePlansPending(): IAction {
    return {
      type: FETCH_ALL_INSURANCE_PLANS_PENDING,
    };
  },

  fetchAllInsurancePlansSuccess(practiceDetails: IPracticeDetailsData): IAction {
    return {
      payload: practiceDetails,
      type: FETCH_ALL_INSURANCE_PLANS_SUCCESS,
    };
  },

  fetchAllInsurancePlansError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_ALL_INSURANCE_PLANS_ERROR,
    };
  },


  fetchAllPaymentMethodsPending(): IAction {
    return {
      type: FETCH_ALL_PAYMENT_METHODS_PENDING,
    };
  },

  fetchAllPaymentMethodsSuccess(practiceDetails: IPracticeDetailsData): IAction {
    return {
      payload: practiceDetails,
      type: FETCH_ALL_PAYMENT_METHODS_SUCCESS,
    };
  },

  fetchAllPaymentMethodsError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_ALL_PAYMENT_METHODS_ERROR,
    };
  },

  createAppointmentPending(): IAction {
    return {
      type: CREATE_APPOINTMENT_PENDING,
    };
  },

  createAppointmentSuccess(createdAppointment: Response): IAction {
    return {
      payload: createdAppointment,
      type: CREATE_APPOINTMENT_SUCCESS,
    };
  },

  createAppointmentError(error: string): IAction {
    return {
      payload: error,
      type: CREATE_APPOINTMENT_ERROR,
    };
  },

  cancelAppointmentPending(): IAction {
    return {
      type: CANCEL_APPOINTMENT_PENDING,
    };
  },

  cancelAppointmentSuccess(cancelledAppointment: Response): IAction {
    return {
      payload: cancelledAppointment,
      type: CANCEL_APPOINTMENT_SUCCESS,
    };
  },

  cancelAppointmentError(error: string): IAction {
    return {
      payload: error,
      type: CREATE_APPOINTMENT_ERROR,
    };
  },

  fetchIntakeFormPending(): IAction {
    return {
      type: FETCH_INTAKE_FORM_PENDING,
    };
  },

  fetchIntakeFormSuccess(intakeForm: any): IAction {
    return {
      payload: intakeForm,
      type: FETCH_INTAKE_FORM_SUCCESS,
    };
  },

  fetchIntakeFormCancelled(cancelledMessage: string): IAction {
    return {
      payload: cancelledMessage,
      type: FETCH_INTAKE_FORM_CANCELLED,
    };
  },

  fetchIntakeFormError(error: string): IAction {
    return {
      payload: error,
      type: FETCH_INTAKE_FORM_ERROR,
    };
  },
};
