import {
  FETCH_INTAKE_FORM_ERROR,
  FETCH_INTAKE_FORM_PENDING,
  FETCH_INTAKE_FORM_SUCCESS,
  FETCH_INTAKE_FORM_CANCELLED,
  START_INTAKE_FORM,
  TRIGGER_NEXT_QUESTION,
  TRIGGER_PREVIOUS_QUESTION,
  COMPLETE_INTAKE_FORM,
  SUBMIT_INTAKE_FORM_PENDING,
  SUBMIT_INTAKE_FORM_SUCCESS,
  SUBMIT_INTAKE_FORM_ERROR,
} from "redux/actions/action-creators";

import {
  IAction,
  IIntakeFormState,
  IIntakeFormCurrentStep,
  IIntakeFormPayload,
  IIntakeFormRulesArr,
  IIntakeFormMultiSelectResponsesObjArr,
  IMultiSelectionState,
  IIntakeFormStep,
  IIntakeFormStepsArr,
  IIntakeFormUserResponsesPayload,
} from "types";

import { selectors } from "redux/selectors/intake-form/selectors";

const ACTIONS = {
  ACTIVATE: "activate",
  COMPLETE: "complete",
};

export const QUESTION_TYPES = {
  MULTIPLE_SELECT: "MULTIPLE_SELECT",
  SINGLE_SELECT: "SINGLE_SELECT",
  OPEN_ENDED: "OPEN_ENDED",
  YES_NO: "YES_NO",
  ACKNOWLEDGEMENT: "ACKNOWLEDGEMENT",
};

export const OPTIONS = {
  OTHER: "OTHER",
};

export const PUNCTUATION = ":";

const EQUAL = "EQUAL";
const NOT_EQUAL = "NOT_EQUAL";

const operatorHelper = (operator: string, operatorValue: string, response: string): boolean => {
  switch (operator) {
    case EQUAL:
      return operatorValue === response;
    case NOT_EQUAL:
      return operatorValue !== response;
    default:
      return false;
  }
};

const toggleShowPromptIfPrevRes = (responses: any, currentStep: IIntakeFormCurrentStep) => {
  const isYesNo = currentStep.type === QUESTION_TYPES.YES_NO;
  const isMultiSelect = currentStep.type === QUESTION_TYPES.MULTIPLE_SELECT;
  const mayHaveTextResponse = isYesNo || isMultiSelect;
  const prevResObj = responses.find((res: any) => res.question === currentStep.question);

  if (!mayHaveTextResponse || !prevResObj) return false;
  if (!isYesNo) {
    const hasOtherRes = prevResObj.response.find((res: any) => res.includes(OPTIONS.OTHER));
    return !!hasOtherRes;
  }
  return prevResObj ? prevResObj.response.includes(PUNCTUATION) : false;
};

const createPrevMultiselectObj = (
  responses: IIntakeFormMultiSelectResponsesObjArr,
  currentStep: IIntakeFormCurrentStep,
) => {
  const isMultiSelect = currentStep.type === QUESTION_TYPES.MULTIPLE_SELECT;
  if (!isMultiSelect) return null;

  const prevResObj = responses.find(res => res.question === currentStep.question);

  if (!prevResObj) return null;

  const prevMultiSelectState = {} as IMultiSelectionState;
  prevResObj.response.forEach(prevOptionRes => {
    if (prevOptionRes.includes(OPTIONS.OTHER)) {
      prevMultiSelectState[OPTIONS.OTHER] = true;
    } else {
      prevMultiSelectState[prevOptionRes] = true;
    }
  });
  return prevMultiSelectState;
};

const updateStepsAndHistory = (
  questionRules: IIntakeFormRulesArr,
  steps: IIntakeFormStepsArr,
  userResponse: string,
) => {
  const defaultObj = {
    updatedSteps: steps,
    activatedHistory: [],
  };

  if (!questionRules) return defaultObj;

  // Below, we are trying to find the rule that matches current response to the operator value
  // If there's a match, questionRuleToBeActivated will be that rule object
  // If there's no match, questionRuleToBeActivated will be null
  const questionRuleToBeActivated = questionRules.find(rule =>
    operatorHelper(rule.operator, rule.operator_value, userResponse),
  );

  if (!questionRuleToBeActivated) return defaultObj;

  const quesToBeActivated = questionRuleToBeActivated.activate_set;
  const activatedHistory: { action: string; index: number }[] = [];
  const updatedSteps: IIntakeFormStep[] = [];

  steps.forEach((step: IIntakeFormStep, i: number) => {
    if (quesToBeActivated.includes(step.question)) {
      activatedHistory.push({
        action: ACTIONS.ACTIVATE,
        index: i,
      });
      updatedSteps.push({ ...step, isActive: true });
    } else {
      updatedSteps.push(step);
    }
  });

  return {
    updatedSteps,
    activatedHistory,
  };
};

const initState: IIntakeFormState = {
  currentIndex: 0,
  currentStep: null,
  duration: null,
  end: null,
  error: null,
  cancelledMessage: null,
  flow: null,
  history: [],
  intakeFormComplete: false,
  intakeFormSubmitted: false,
  isLoading: false,
  parameters: null,
  prevMultiSelectState: null,
  questions: null,
  responses: [],
  showPrompt: false,
  start: null,
  steps: null,
  successResponse: null,
};

export const intakeFormReducer = (
  state: IIntakeFormState = initState,
  action: IAction,
): IIntakeFormState => {
  const { type, payload } = action;
  switch (type) {
    case COMPLETE_INTAKE_FORM: {
      const end = new Date() as any;
      const diff = end - state.start;
      return {
        ...state,
        duration: Math.round(diff / 1000),
        responses: state.responses.concat(payload),
        end,
        intakeFormComplete: true,
      };
    }
    case SUBMIT_INTAKE_FORM_PENDING:
    case FETCH_INTAKE_FORM_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_INTAKE_FORM_SUCCESS: {
      const { questions, flow, parameters } = payload as IIntakeFormPayload;
      const steps = selectors.getSteps(flow, questions);
      const { currentStep } = selectors.getCurrentStep(state.currentIndex, steps);
      return {
        ...state,
        isLoading: false,
        error: null,
        questions,
        flow,
        steps,
        parameters,
        currentStep,
      };
    }
    case FETCH_INTAKE_FORM_CANCELLED: {
      return {
        ...state,
        isLoading: false,
        cancelledMessage: payload as string,
        error: null,
      };
    }
    case SUBMIT_INTAKE_FORM_ERROR:
    case FETCH_INTAKE_FORM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case START_INTAKE_FORM: {
      const { currentIndex, steps, responses } = state;
      if (currentIndex !== 0) return state;
      const firstQuestionIndex = currentIndex + 1;
      const { currentStep } = selectors.getCurrentStep(firstQuestionIndex, steps);
      return {
        ...state,
        currentIndex: firstQuestionIndex,
        currentStep,
        history: [{ action: ACTIONS.COMPLETE, index: currentIndex }],
        responses: responses.concat(payload),
        start: new Date(),
      };
    }
    case TRIGGER_NEXT_QUESTION: {
      const { questionRules, userResponses } = payload as IIntakeFormUserResponsesPayload;
      const { steps, history, currentIndex, responses } = state;
      const { response } = userResponses;
      const nextIndex = currentIndex + 1;
      const { updatedSteps, activatedHistory } = updateStepsAndHistory(
        questionRules,
        steps,
        response,
      );
      const { index, currentStep } = selectors.getCurrentStep(nextIndex, updatedSteps);
      return {
        ...state,
        currentIndex: index,
        currentStep,
        history: history.concat(
          { action: ACTIONS.COMPLETE, index: currentIndex },
          activatedHistory,
        ),
        prevMultiSelectState: createPrevMultiselectObj(responses, currentStep),
        responses: selectors.findAndReplaceCurrentResponse(responses, userResponses),
        showPrompt: toggleShowPromptIfPrevRes(responses, currentStep),
        steps: updatedSteps,
      };
    }
    case TRIGGER_PREVIOUS_QUESTION: {
      const { steps, history, responses } = state;
      if (history.length === 1) {
        return {
          ...state,
          currentIndex: 0,
          currentStep: selectors.getCurrentStep(0, steps).currentStep,
          prevMultiSelectState: null,
          responses: [],
          start: null,
        };
      }
      let newState = { ...state };
      for (let i = history.length - 1; i > -1; i -= 1) {
        const { action: historyAction, index } = history[i];
        const { currentStep } = selectors.getCurrentStep(index, steps);
        if (historyAction === ACTIONS.COMPLETE) {
          return {
            ...newState,
            currentIndex: index,
            currentStep,
            history: history.slice(0, i),
            showPrompt: toggleShowPromptIfPrevRes(responses, currentStep),
            steps,
            prevMultiSelectState: createPrevMultiselectObj(responses, currentStep),
            end: null,
          };
        }
        if (historyAction === ACTIONS.ACTIVATE) {
          if (steps[index].optional) steps[index].isActive = false;
          newState = { ...newState, steps };
        }
      }
      return newState;
    }
    case SUBMIT_INTAKE_FORM_SUCCESS:
      return {
        ...state,
        currentStep: null,
        duration: null,
        end: null,
        error: null,
        flow: null,
        history: [],
        parameters: null,
        prevMultiSelectState: null,
        questions: null,
        responses: [],
        showPrompt: false,
        start: null,
        steps: null,
        intakeFormSubmitted: true,
        successResponse: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
