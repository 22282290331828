import { SerializedStyles } from "@emotion/core";
import intakeFormStyles from "styles/intakeForm";

const toggleOptionStyles = (
  responses: string,
  loggedResForQuestionType: string,
  optionName: string,
): SerializedStyles => {
  const determiner = responses || loggedResForQuestionType;
  if (!determiner) return intakeFormStyles.optionInactive;
  return determiner.includes(optionName)
    ? intakeFormStyles.optionActive
    : intakeFormStyles.optionInactive;
};

export const helpers = {
  toggleOptionStyles,
};
