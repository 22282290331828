/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { actionCreators } from "redux/actions/action-creators";

import { actions } from "redux/actions/actions";
import { IAction, IFormProps } from "types";
import { format } from "../tools/formatters";
import { validators } from "../tools/validators";

import personalInfoStyles from "styles/funnel/personalInfo";

const onChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  dispatch: React.Dispatch<IAction>,
  type: string,
): void => {
  const val = e.target.value;
  dispatch({ payload: val, type });
};

const Form = ({
  appointmentProfileID,
  customerInfo,
  dispatch,
  doctorID,
  errors,
  examDate,
  examDuration,
  examRoom,
  examTime,
  officeID,
}: IFormProps): JSX.Element => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const inputElements = Array.from(document.querySelectorAll("input:not(#phoneNumber)"));
    inputElements.forEach((inputEle: Element): void => {
      inputEle.addEventListener("click, touch", format.phoneFormatter);
    });

    format.moveToNext(".month", ".day");
    format.moveToNext(".day", ".year");

    const dobInputs = document.querySelectorAll(".date-input-container input");

    dobInputs.forEach((inputEle: Element): void => {
      inputEle.addEventListener("focus", format.divFocus);
    });

    dobInputs.forEach((inputEle: Element): void => {
      inputEle.addEventListener("blur", format.divBlur);
    });

    // tslint:disable-next-line: align
  }, []);
  const isProd = window.location.href.indexOf("myvisiondirectory") > -1;
  return (
    <div css={personalInfoStyles.formContainer}>
      <div css={personalInfoStyles.textContainer}>
        <p style={{ lineHeight: "1.4em" }}>
          Patients under 18 must be accompanied by a parent or legal guardian.
        </p>
      </div>
      <form css={personalInfoStyles.form} autoComplete="off">
        <div css={personalInfoStyles.label}>
          <label>Patient's first name</label>
          <input
            id="first_name"
            css={errors.firstName ? personalInfoStyles.inputError : personalInfoStyles.input}
            type="text"
            name="name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              onChange(e, dispatch, "first-name")
            }
            value={customerInfo.firstName}
          />
        </div>
        {errors.firstName && (
          <span css={personalInfoStyles.errorText}>First name can only include letters</span>
        )}
        <div css={personalInfoStyles.label}>
          <label>Patient's last name</label>
          <input
            css={errors.lastName ? personalInfoStyles.inputError : personalInfoStyles.input}
            type="text"
            name="name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              onChange(e, dispatch, "last-name")
            }
            value={customerInfo.lastName}
          />
        </div>
        {errors.lastName && (
          <span css={personalInfoStyles.errorText}>Last name can only include letters</span>
        )}
        <div css={personalInfoStyles.label}>
          <label>Patient's email address</label>
          <input
            css={errors.email ? personalInfoStyles.inputError : personalInfoStyles.input}
            type="email"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              onChange(e, dispatch, "email")
            }
            value={customerInfo.email}
          />
        </div>
        {errors.email && (
          <span css={personalInfoStyles.errorText}>
            Invalid email address format (e.g. john.smith@gmail.com)
          </span>
        )}
        <div css={personalInfoStyles.label}>
          <label>Phone number</label>
          <input
            css={errors.phone ? personalInfoStyles.inputError : personalInfoStyles.input}
            id="phoneNumber"
            type="text"
            maxLength={10}
            name="telephone"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              onChange(e, dispatch, "phone")
            }
            placeholder="(e.g. 9171234567)"
            value={customerInfo.phone}
          />
        </div>
        {errors.phone && (
          <span css={personalInfoStyles.errorText}>Phone number can only include numbers</span>
        )}
        <div css={personalInfoStyles.label}>
          <label>Patient's date of birth</label>
          <div
            css={
              errors.dob
                ? personalInfoStyles.dateInputContainerError
                : personalInfoStyles.dateInputContainer
            }
            className="date-input-container"
          >
            <input
              type="text"
              maxLength={2}
              placeholder="MM"
              className="month"
              css={personalInfoStyles.month}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onChange(e, dispatch, "dob-month")
              }
              value={customerInfo.dob.month}
            />
            <span className="separator">/</span>
            <input
              type="text"
              maxLength={2}
              placeholder="DD"
              className="day"
              css={personalInfoStyles.day}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onChange(e, dispatch, "dob-day")
              }
              value={customerInfo.dob.day}
            />
            <span className="separator">/</span>
            <input
              type="text"
              maxLength={4}
              placeholder="YYYY"
              className="year"
              css={personalInfoStyles.year}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onChange(e, dispatch, "dob-year")
              }
              value={customerInfo.dob.year}
            />
          </div>
        </div>
        {errors.dob && (
          <span css={personalInfoStyles.errorText}>Invalid date of birth (e.g. 01/01/1988)</span>
        )}
      </form>
      <div css={personalInfoStyles.nonFormContainer}>
        <div css={personalInfoStyles.buttonContainer}>
          <Link
            className="link"
            css={
              validators.validateFormCompletion(customerInfo)
                ? personalInfoStyles.enabledButton
                : personalInfoStyles.disabledButton
            }
            to={
              validators.validateAllFields(customerInfo)
                ? `/${window.location.pathname.split("/")[1]}/confirmation-page`
                : `/${window.location.pathname.split("/")[1]}/personal-info-page`
            }
            onClick={(): void => {
              const postPayload = {
                date_of_birth: `${customerInfo.dob.year}-${customerInfo.dob.month}-${customerInfo.dob.day}`,
                duration: examDuration,
                email: customerInfo.email,
                exam_room: examRoom,
                first_name: customerInfo.firstName,
                last_name: customerInfo.lastName,
                phone: customerInfo.phone,
                profile: appointmentProfileID,
                scheduled_time: `${examDate}T${examTime}:00`,
              };
              if (validators.validateAllFields(customerInfo)) {
                actions
                  .createAppointment(dispatch, officeID, doctorID, postPayload)
                  .catch((e: Error): void => {
                    dispatch(actionCreators.createAppointmentError(e.message));
                  });
                dispatch({ type: "clear-error" });
              } else {
                dispatch({ payload: validators.errorObj(customerInfo), type: "error" });
              }
            }}
          >
            <button css={{ width: "100%", fontSize: "16px" }}>Confirm exam booking</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Form;
