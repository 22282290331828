/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { IOfficeProps } from "types";
import officesStyles from "styles/offices";

const formatAddress = (officeAddress: string): JSX.Element => {
  const hasParenthesis = officeAddress.indexOf("(") > 0;
  if (hasParenthesis) {
    const parenthesis = officeAddress.indexOf("(");
    return <p>{officeAddress.slice(0, parenthesis)}</p>;
  }
  return <p>{officeAddress}</p>;
};

const Office = ({ officeName, address, city, shortName }: IOfficeProps): JSX.Element => (
  <div css={officesStyles.officeContainer}>
    <p>
      {city}, {officeName}
    </p>
    {formatAddress(address)}
    <Link css={officesStyles.clickable} to={`/${shortName}`}>
      Book an eye exam
    </Link>
  </div>
);

export default Office;
