import React from "react";
import { withRouter } from "react-router-dom";

import { IRouter } from "../types";

const BackButton = withRouter(
  ({ history, src }: IRouter): JSX.Element => (
    <button
      onClick={(): void => {
        history.goBack();
      }}
    >
      <img src={src} alt={src} />
    </button>
  ),
);

export default BackButton;
