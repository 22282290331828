/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";

import { IAction, ITimeSlot, ITimesProps, Target } from "types";

import timesStyles from "styles/funnel/times";

const timeAdjust = (time: string): string => {
  const hour = +time.split(":")[0];
  const minutes = time.split(":")[1];
  const amPm = hour >= 12 ? "p.m." : "a.m.";
  const formattedHour = hour > 12 ? hour - 12 : hour;
  return `${formattedHour}:${minutes} ${amPm}`;
};

const handleClick = (
  e: React.MouseEvent<HTMLElement>,
  dispatch: React.Dispatch<IAction>,
  calendarHidden: boolean,
  examRoom: number,
  doctorID: number,
): void => {
  const time = (e.target as Target).className.split(" ")[0];
  dispatch({ payload: examRoom, type: "exam-room" });
  dispatch({ payload: time, type: "time" });
  dispatch({ payload: true, type: "calendar-hidden" });
  dispatch({ payload: doctorID, type: "doctor-id" });
};

const Times = ({
  calendarHidden,
  dispatch,
  pending,
  selectedDate,
  timeSlots,
  openSlots,
}: ITimesProps): JSX.Element => {
  const filteredSlots = (timeSlots || []).filter((slot: ITimeSlot) => slot.date === selectedDate);
  const slots = (filteredSlots[0] && filteredSlots[0].time_slots) || [];

  const toggleCal = (): void => {
    dispatch({ payload: !calendarHidden, type: "calendar-hidden" });
  };

  if (!pending && slots.length === 0) {
    return (
      <div css={timesStyles.moreDates}>
        <p>No available appointments on this date</p>
        <button css={timesStyles.buttonNoAppt} onClick={toggleCal}>
          More Dates
        </button>
      </div>
    );
  }

  return (
    <div css={timesStyles.timesContainer}>
      {slots.map((slot: ITimeSlot, i: number) => (
        <Link
          css={timesStyles.button}
          onClick={(e: React.MouseEvent<HTMLElement>): void =>
            handleClick(e, dispatch, calendarHidden, slot.exam_room, slot.doctor)
          }
          key={i}
          className={slot.time}
          to={`/${window.location.pathname.split("/")[1]}/personal-info-page`}
        >
          {timeAdjust(slot.time)}
        </Link>
      ))}
    </div>
  );
};

export default Times;
