/** @jsx jsx */
import { css } from "@emotion/core";

const optionTextContainer = css`
  display: flex;
  height: 192px;
  width: 100%;
  margin: 30px 0 54px;
  flex-direction: column;

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #414b56;
    display: block;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    text-align: left;
  }

  textarea {
    height: 100%;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #e1e5e6;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #414b56;
    display: block;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    padding: 20px;
    &:focus {
      outline: none;
    }
  }
`;

const optionsContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 748px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
`;

const optionContainer = css`
  box-sizing: border-box;
  padding: 0 0 12px;
  max-width: 354px;
  width: 100%;
  &:nth-of-type(odd) {
    padding-right: 12px;
  }
  @media (max-width: 720px) {
    box-sizing: border-box;
    padding: 0 0 12px 0;
    max-width: none;
    width: 100%;
    &:nth-of-type(odd) {
      padding-right: 0;
    }
  }
`;

const optionInactive = css`
  background: #ffffff;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  color: #414b56;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 60px;
  line-height: 24px;
  padding: 18px;
  outline: none;
  text-align: left;
  width: 100%;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;

  @media (hover: hover) {
    &:hover {
      border: 1px solid #00a2e1;
    }
  }
`;

const blueCheck =
  "https://d3i1ct304nu39.cloudfront.net/v/c/assets/intake-form/image/bluecheckmark/0/142ebf539e.png";

const optionActive = css`
  border: 1px solid #00a2e1;
  background: url(${blueCheck}) no-repeat #fff 92% 49%;
  background-size: 17.5px 15px;
  box-sizing: border-box;
  color: #414b56;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 60px;
  line-height: 24px;
  padding: 18px;
  outline: none;
  text-align: left;
  width: 100%;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;

  @media (hover: hover) {
    &:hover {
      border: 1px solid #00a2e1;
    }
  }
`;

const textContainer = css`
  padding: 30px 0 60px;
  @media (max-width: 720px) {
    padding: 30px 0;
  }
`;

const block = css`
  width: 100%;
`;

const container = css`
  display: block;
  margin: 90px auto 10vh;
  max-width: 1000px;
  padding: 0 30px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
    color: #414b56;
    margin: 0;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #676f78;
    margin: 0;
  }

  @media (max-width: 720px) {
    padding: 0 30px;
    h1,
    p {
      text-align: left;
    }
    p {
      margin: 0;
    }
  }
`;

// confirmation page
const containerH1 = css`
  color: #414b56;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 50px;
`;

const containerText = css`
  color: #676f78;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const rescheduleContainer = css`
  position: absolute;
  bottom: 200px;
  align-items: center;
  width: 100%;
  @media (max-width: 893px) {
    bottom: 350px;
  }
  @media (max-width: 767px) {
    position: inherit;
  }
`;

//acknowledgement question
const consentContainer = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 748px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;
const link = css`
  color: white;
  &:active,
  &:hover {
    color: white;
  }
`;
const consentIcon = css`
  width: 150px;
  height: 193px;
  margin: 0 auto;
`;
const pillButtonContainer = css`
  display: inline-block;
  margin: 30px auto 18px;
  width: 196px;
  position: relative;

  button {
    position: absolute;
    bottom: 5em;
    right: 0.6em;
    padding: 6px 31px;
    background-color: #0089bf;
    border-radius: 100px;
  }
`;

const footerTextCss = css`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 30px;
`;

// question page
const quesBlock = css`
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  width: 100%;
`;

const quesContainer = css`
  display: block;
  box-sizing: border-box;
  margin: 14px auto 0;
  max-width: 768px;
  text-align: center;
  padding: 0 30px;

  h1 {
    color: #414b56;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 50px;
    text-align: left;
  }
  @media (max-width: 720px) {
    padding: 0 13px;
    h1 {
      font-size: 28px;
      line-height: 40px;
      text-align: left;
      font-weight: normal;
    }
  }
`;

const quesBlueButtonContainer = css`
  display: flex;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  button {
    background: #0089bf;
    border-radius: 1px;
    border: 1px solid #00a2e1;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 60px;
    margin-bottom: 12px;
    min-width: 232px;
    outline: 0;
    padding: 6px 16px;
    text-align: center;
    transition: background-color 0.4s;
    vertical-align: middle;
    white-space: nowrap;
    width: 348px;
    &:disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  @media (max-width: 720px) {
    button {
      width: 100%;
    }
  }
`;

const arrowContainer = css`
  display: flex;
  box-sizing: border-box;
  margin: 36px auto;
  max-width: 768px;
  outline: none;
  justify-content: left;
`;

const arrow = css`
  height: 24px;
  width: 24px;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const centeredH1 = css`
  text-align: center !important;
`;

// intro page
const blueButtonContainer = css`
  display: flex;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  flex-direction: column;
  margin: 0 auto;

  button {
    background: #0089bf;
    border-radius: 1px;
    border: 1px solid #00a2e1;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 60px;
    margin-bottom: 12px;
    min-width: 232px;
    outline: 0;
    padding: 6px 16px;
    text-align: center;
    transition: background-color 0.4s;
    vertical-align: middle;
    white-space: nowrap;
    width: 348px;
  }

  @media (max-width: 720px) {
    button {
      width: 100%;
    }
  }
`;

export default {
  blueButtonContainer,
  quesBlock,
  quesContainer,
  quesBlueButtonContainer,
  arrowContainer,
  arrow,
  centeredH1,
  consentContainer,
  link,
  consentIcon,
  pillButtonContainer,
  footerTextCss,
  containerH1,
  containerText,
  rescheduleContainer,
  optionActive,
  optionContainer,
  optionInactive,
  optionsContainer,
  optionTextContainer,
  textContainer,
  block,
  container,
};
