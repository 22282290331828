import { IIntakeFormResponsePayload, IIntakeFormResponsesObjArr } from "types";

const getCurrentStep = (index: number, steps: any): any => {
  if (!steps) return { index: null, currentStep: {} };
  const { isActive } = steps[index];
  if (!isActive) return getCurrentStep(index + 1, steps);
  return {
    currentStep: steps[index],
    index,
  };
};

const getSteps = (flow: any, questions: any): any =>
  flow.map((flowQuesObj: any, i: number) => ({
    ...flowQuesObj,
    ...questions[flowQuesObj.question],
    index: i,
    isActive: !flowQuesObj.optional,
  }));

const findAndReplaceCurrentResponse = (
  responses: IIntakeFormResponsesObjArr,
  userResponses: IIntakeFormResponsePayload,
): any => {
  const { question, response } = userResponses;
  const foundMatch = responses.findIndex(
    (res: IIntakeFormResponsePayload) => res.question === question,
  );
  const loggedResponses = responses;

  if (foundMatch > -1) {
    loggedResponses[foundMatch].response = response;
    return loggedResponses;
  }

  return loggedResponses.concat(userResponses);
};

export const selectors = {
  findAndReplaceCurrentResponse,
  getSteps,
  getCurrentStep,
};
