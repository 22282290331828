const getGeneralEyeExamPricing = (priceMax: string, priceMin: string): string => {
  const priceMaxNum = Number(priceMax || '0');
  const priceMinNum = Number(priceMin || '0');
  // if both values are 0
  if (!priceMaxNum && !priceMinNum) return "$75 due at visit";
  if (priceMaxNum === priceMinNum) return `$${priceMinNum} due at visit`;
  return `$${priceMinNum} - $${priceMaxNum} due at visit`;
}

export const format = {
  getGeneralEyeExamPricing,
};
