/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ICAOfficesProps, IOfficeElement, ITempObj } from "types";

import Office from "./Office";
import officesStyles from "styles/offices";

// tslint:disable: no-unsafe-any

const CanadaOffices = ({ officesCA }: ICAOfficesProps): JSX.Element => {
  const data = officesCA;
  const groups = Object.create(null);
  let result;

  data.forEach((office: IOfficeElement): void => {
    groups[office.state] = groups[office.state] || [];
    groups[office.state].push(office);
  });

  result = Object.keys(groups).map(
    (k: string): ITempObj => {
      const temp = ({} as unknown) as ITempObj;
      temp[k] = groups[k];
      return temp;
    },
  );

  return (
    <div css={officesStyles.countryContainer}>
      <h1 css={officesStyles.countryHeader}>Locations in Canada</h1>
      {result.map((x: ITempObj, i: number) => (
        <div key={i}>
          {officesCA.map((office: IOfficeElement, k: number) => {
            const officeName = office.name;
            const address = office.address;
            const city = office.city;
            const shortName = office.location_short_name;

            return (
              <Office
                key={k}
                officeName={officeName}
                address={address}
                city={city}
                shortName={shortName}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default CanadaOffices;
