import { IQueries } from "types";
const getUrlVars = (): IQueries => {
  const vars = {} as any;
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (_m: any, key: string, value: string): any => {
      vars[key] = value;
    },
  );
  return vars;
};

export const format = {
  getUrlVars,
};
