/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { actionCreators } from "redux/actions/action-creators";
import { actions } from "redux/actions/actions";
import Error from "shared-ui/Error";
import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";
import { IFunnelProps } from "types";
import { format } from "../tools/formatters";

import cancelStyles from "styles/funnel/cancel";

const CancelPromptPage = ({ dispatch }: IFunnelProps): JSX.Element => {
  const doctorName = format.getUrlVars()["doc" as string].replace("+", " ");
  const shortName = format.getUrlVars()["shortName" as string];
  const doctorID = format.getUrlVars()["docid" as string];
  const appointmentDate = format
    .getUrlVars()["date" as string]
    .replace("%2C", ",")
    .split("+")
    .join(" ");
  const appointmentTime = format
    .getUrlVars()["time" as string]
    .replace("+", " ")
    .replace("%3A", ":");
  const hash = format.getUrlVars()["hash" as string];
  const appointmentID = parseInt(window.location.pathname.split("/")[2], 10);

  if (!appointmentID) {
    return <Error />;
  }

  return (
    <Fragment>
      <Header />
      <div id="component">
        <div className="funnel">
          <div css={{ padding: "0 20px" }}>
            <h1>Are you sure you want to cancel?</h1>
            <div>
              <p css={cancelStyles.textContainer}>
                Your current appointment with Dr. {doctorName} is scheduled for{" "}
                {format.getDay(appointmentDate)}, {appointmentDate} at {appointmentTime}. If you
                choose to cancel, you'll be able to book a new appointment immediately.
              </p>
            </div>
            <div css={cancelStyles.buttonContainer}>
              <Link
                css={cancelStyles.button}
                onClick={(): void => {
                  actions
                    .cancelAppointment(dispatch, parseInt(doctorID, 10), appointmentID, hash)
                    .catch((e: Error): void => {
                      dispatch(actionCreators.createAppointmentError(e.message));
                    });
                }}
                to={
                  "/cancel/:appointmentID/confirm?" +
                  `doc=${doctorName}&time=${appointmentTime}&date=${appointmentDate}&shortName=${shortName}`
                }
              >
                Confirm my cancellation
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default connect()(CancelPromptPage);
