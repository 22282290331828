/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { connect } from "react-redux";

import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";
import { IHomepageProps, IHomepageState, IOfficeElement, IState, IAllOfficesArr } from "types";

import CanadaOffices from "./components/CanadaOffices";
import UnitedStatesOffices from "./components/UnitedStatesOffices";

import officesStyles from "styles/offices";

// tslint:disable: strict-type-predicates
// tslint:disable: strict-comparisons
const Homepage = ({ officesCA, officesUS }: IHomepageProps): JSX.Element => (
  <Fragment>
    <Header />
    <div css={officesStyles.officesContainer}>
      <UnitedStatesOffices officesUS={officesUS} />
      {officesCA.length > 0 && <CanadaOffices officesCA={officesCA} />}
    </div>
    <Footer />
  </Fragment>
);

const returnMatchingFacilities = (country: string, offices: IAllOfficesArr): IAllOfficesArr => offices.filter((office: IOfficeElement) => {
  const mvdBookingFlow = office.booking_flow === "MVD";
  const hasCountry = office.country === country;
  const hasShortName = office.location_short_name !== null;
  return mvdBookingFlow && hasCountry && hasShortName;
});

const select = (state: IState): IHomepageState => ({
  officesCA: returnMatchingFacilities("CA", state.pmp.allOffices.data),
  officesUS: returnMatchingFacilities("US", state.pmp.allOffices.data),
});

export default connect(select)(Homepage);
