export class CustomException extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

const parseError = async (response: Response): Promise<Error> => {
  const body = await response.json();
  // tslint:disable-next-line: no-unsafe-any
  const error = body.error || {};
  const msg500 = "Internal Server Error.";
  const msg = (error as Error).message || msg500;

  // TODO: format json error messages
  return new CustomException(msg, response.status);
};

const parseResponse = async (response: Response): Promise<Response | object> => {
  switch (response.status) {
    case 201:
    case 200:
      const res = response.json();
      return res;
    case 204:
      return {};
    case 400:
    case 403:
    case 409:
    case 504:
    case 500:
      throw await parseError(response);
    default:
      throw new Error("Something went wrong. 😢");
  }
};

export default parseResponse;
