import React, { useEffect } from "react";
import { connect } from "react-redux";

import Footer from "shared-ui/Footer";
import FunnelHeader from "shared-ui/FunnelHeader";
import ProgressBar from "shared-ui/ProgressBar";
import { IExamsTypePageProps, IExamsTypePageState, IFeeSchedule, IState } from "types";

import Exam from "./components/Exam";
const ExamsTypePage = ({
  dispatch,
  step,
  appointmentProfiles,
  officeID,
}: IExamsTypePageProps): JSX.Element => {
  useEffect(() => {
    dispatch({ payload: "1 - exams type", type: "step" });
    dispatch({ payload: true, type: "calendar-hidden" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // tslint:disable-next-line: align
  });

  return (
    <>
      <ProgressBar progress={"20%"} />
      <FunnelHeader step={step} />
      <div id="component">
        <div className="funnel">
          <h1 style={{ marginBottom: 0 }}>Select exam type</h1>
          {appointmentProfiles.map((exam: IFeeSchedule, i: number) => {
            const appointmentProfileID = exam.id;
            const description = exam.description;
            const priceMin = exam.price_min;
            const priceMax = exam.price_max;
            const examType = exam.name;
            const examDuration = exam.duration;
            return (
              <Exam
                appointmentProfileID={appointmentProfileID}
                description={description}
                dispatch={dispatch}
                key={i}
                priceMin={priceMin}
                priceMax={priceMax}
                examType={examType}
                examDuration={examDuration}
                officeID={officeID}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

const select = (state: IState): IExamsTypePageState => ({
  appointmentProfiles: state.pmp.appointmentProfiles.data,
  officeID: state.pmp.officeDetails.data.id,
  step: state.funnel.step,
});

export default connect(select)(ExamsTypePage);
