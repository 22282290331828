import {
  IAction,
  IAllOfficesArr,
  IOfficeDetails,
  IOpenSlotsArr,
  IOpenSlotsBoolArr,
  IPMPState,
  IAppointmentProfilesArr
} from "../../types";
import {
  FETCH_ALL_PAYMENT_METHODS_ERROR,
  FETCH_ALL_PAYMENT_METHODS_SUCCESS,
  FETCH_ALL_PAYMENT_METHODS_PENDING,
  FETCH_ALL_INSURANCE_PLANS_ERROR,
  FETCH_ALL_INSURANCE_PLANS_SUCCESS,
  FETCH_ALL_INSURANCE_PLANS_PENDING,
  FETCH_PRACTICE_DETAILS_ERROR,
  FETCH_PRACTICE_DETAILS_SUCCESS,
  FETCH_PRACTICE_DETAILS_PENDING,
  CLEAR_OFFICE_DETAILS_FETCHES,
  FETCH_ALL_OFFICES_ERROR,
  FETCH_ALL_OFFICES_PENDING,
  FETCH_ALL_OFFICES_SUCCESS,
  FETCH_DOCTORS_ERROR,
  FETCH_DOCTORS_PENDING,
  FETCH_DOCTORS_SUCCESS,
  FETCH_OFFICE_DETAILS_ERROR,
  FETCH_OFFICE_DETAILS_PENDING,
  FETCH_OFFICE_DETAILS_SUCCESS,
  FETCH_TIME_SLOTS_BOOL_ERROR,
  FETCH_TIME_SLOTS_BOOL_PENDING,
  FETCH_TIME_SLOTS_BOOL_SUCCESS,
  FETCH_TIME_SLOTS_ERROR,
  FETCH_TIME_SLOTS_PENDING,
  FETCH_TIME_SLOTS_SUCCESS,
  FETCH_APPOINTMENT_PROFILES_PENDING,
  FETCH_APPOINTMENT_PROFILES_SUCCESS,
  FETCH_APPOINTMENT_PROFILES_ERROR
} from "../actions/action-creators";

const initState: IPMPState = {
  allOffices: {
    data: [],
    error: "",
    hasFetched: false,
    pending: false,
  },
  doctors: {
    data: [],
    error: "",
    hasFetched: false,
    pending: false,
  },
  insurancePlans: {
    data: [],
    error: "",
    pending: false,
  },
  officeDetails: {
    data: {
      address: "",
      archived: false,
      city: "",
      config: null,
      country: "",
      doctor: 0,
      email: "",
      end_time: "",
      fax_number: "",
      geolocation: {
        lat: null,
        lng: null,
      },
      id: 0,
      location_short_name: "",
      name: "",
      online_scheduling: false,
      phone_number: "",
      practice_group: 0,
      practice_group_id: 0,
      practice_group_name: "",
      start_time: "",
      state: "",
      zip_code: "",
    },
    error: "",
    hasFetched: false,
    pending: false,
  },
  openSlots: {
    data: [],
    error: "",
    pending: false,
  },
  paymentMethods: {
    data: [],
    error: "",
    pending: false,
  },
  practiceDetails: {
    data: {
      authorizing_doctor_id: null,
      id: null,
      insurance_plans: [],
      payment_methods: [],
      practice_group_name: "",
      booking_flow: "",
      practice_type: "",
    },
    error: "",
    pending: false,
  },
  timeSlots: {
    data: [],
    error: "",
    pending: false,
  },
  appointmentProfiles: {
    data: [],
    error: "",
    hasFetched: false,
    pending: false,
  },
};

export const pmpReducer = (state: any = initState, action: IAction): IPMPState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DOCTORS_PENDING:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          pending: true,
        },
      };
    case FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          data: payload,
          error: "",
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_DOCTORS_ERROR:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          error: payload as string,
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_APPOINTMENT_PROFILES_PENDING:
      return {
        ...state,
        appointmentProfiles: {
          ...state.appointmentProfiles,
          pending: true,
        },
      };
    case FETCH_APPOINTMENT_PROFILES_SUCCESS:
      return {
        ...state,
        appointmentProfiles: {
          ...state.appointmentProfiles,
          data: payload as IAppointmentProfilesArr,
          error: "",
          hasFetched: true,
          pending: false,
        },
        allOffices: {
          ...state.allOffices,
          error: "",
        },
      };
    case FETCH_APPOINTMENT_PROFILES_ERROR:
      return {
        ...state,
        allOffices: {
          ...state.allOffices,
          error: payload as string,
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_ALL_OFFICES_PENDING:
      return {
        ...state,
        allOffices: {
          ...state.allOffices,
          pending: true,
        },
      };
    case FETCH_ALL_OFFICES_SUCCESS:
      return {
        ...state,
        allOffices: {
          ...state.allOffices,
          data: payload as IAllOfficesArr,
          error: "",
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_ALL_OFFICES_ERROR:
      return {
        ...state,
        allOffices: {
          ...state.allOffices,
          error: payload as string,
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_ALL_PAYMENT_METHODS_PENDING:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          pending: true,
        },
      };
    case FETCH_ALL_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: payload as any,
          error: "",
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_ALL_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        allOffices: {
          ...state.paymentMethods,
          error: payload as string,
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_ALL_INSURANCE_PLANS_PENDING:
      return {
        ...state,
        insurancePlans: {
          ...state.insurancePlans,
          pending: true,
        },
      };
    case FETCH_ALL_INSURANCE_PLANS_SUCCESS:
      return {
        ...state,
        insurancePlans: {
          ...state.insurancePlans,
          data: payload as any,
          error: "",
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_ALL_INSURANCE_PLANS_ERROR:
      return {
        ...state,
        allOffices: {
          ...state.insurancePlans,
          error: payload as string,
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_TIME_SLOTS_PENDING:
      return {
        ...state,
        timeSlots: {
          ...state.timeSlots,
          pending: true,
        },
      };
    case FETCH_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        timeSlots: {
          ...state.timeSlots,
          data: payload as IOpenSlotsArr,
          error: "",
          pending: false,
        },
      };
    case FETCH_TIME_SLOTS_ERROR:
      return {
        ...state,
        timeSlots: {
          ...state.timeSlots,
          error: payload as string,
          pending: false,
        },
      };
    case FETCH_TIME_SLOTS_BOOL_PENDING:
      return {
        ...state,
        openSlots: {
          ...state.openSlots,
          pending: true,
        },
      };
    case FETCH_TIME_SLOTS_BOOL_SUCCESS:
      return {
        ...state,
        openSlots: {
          ...state.openSlots,
          data: payload as IOpenSlotsBoolArr,
          error: "",
          pending: false,
        },
      };
    case FETCH_TIME_SLOTS_BOOL_ERROR:
      return {
        ...state,
        openSlots: {
          ...state.openSlots,
          error: payload as string,
          pending: false,
        },
      };
    case FETCH_OFFICE_DETAILS_PENDING:
      return {
        ...state,
        officeDetails: {
          ...state.officeDetails,
          pending: true,
        },
      };
    case FETCH_OFFICE_DETAILS_SUCCESS:
      return {
        ...state,
        officeDetails: {
          ...state.officeDetails,
          data: payload as IOfficeDetails,
          error: "",
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_OFFICE_DETAILS_ERROR:
      return {
        ...state,
        officeDetails: {
          ...state.officeDetails,
          error: payload as string,
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_PRACTICE_DETAILS_PENDING:
      return {
        ...state,
        practiceDetails: {
          ...state.practiceDetails,
          pending: true,
        },
      };
    case FETCH_PRACTICE_DETAILS_SUCCESS:
      return {
        ...state,
        practiceDetails: {
          ...state.practiceDetails,
          data: payload as any,
          error: "",
          hasFetched: true,
          pending: false,
        },
      };
    case FETCH_PRACTICE_DETAILS_ERROR:
        return {
          ...state,
          practiceDetails: {
            ...state.practiceDetails,
            error: payload as string,
            hasFetched: true,
            pending: false,
          },
        };
    case CLEAR_OFFICE_DETAILS_FETCHES:
      return {
        ...state,
        doctors: {
          ...initState.doctors
        },
        officeDetails: {
          ...initState.officeDetails,
        },
        openSlots: {
          ...initState.openSlots,
        },
        timeSlots: {
          ...initState.timeSlots,
        },
      };
    default:
      return state;
  }
};
