import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actionCreators } from "redux/actions/action-creators";
import { actions } from "redux/actions/actions";

import Error from "shared-ui/Error";
import Footer from "shared-ui/Footer";
import FooterBlank from "shared-ui/FooterBlank";
import Header from "shared-ui/Header";
import Spinner from "shared-ui/Spinner";
import { IClinicPageProps, IClinicPageState, IState } from "types";

import DoctorDetails from "./components/DoctorDetails";
import OfficeDetails from "./components/OfficeDetails";

const ClinicPage = ({
  dispatch,
  doctorsFetched,
  error,
  officeDetailsFetched,
  officeShortName,
  officeID,
  practiceGroupID,
}: IClinicPageProps): JSX.Element => {
  useEffect(() => {
    actions.fetchAllInsurancePlans(dispatch).catch((e: Error): void => {
      dispatch(actionCreators.fetchAllInsurancePlansError(e.message));
    });
    actions.fetchAllPaymentMethods(dispatch).catch((e: Error): void => {
      dispatch(actionCreators.fetchAllPaymentMethodsError(e.message));
    });
    if (!officeDetailsFetched) {
      actions.fetchOfficeDetails(dispatch, officeShortName).catch((e: Error): void => {
        dispatch(actionCreators.fetchOfficeDetailsError(e.message));
      });
    }
  }, []);

  useEffect(() => {
    if (officeID) {
      actions.fetchDoctors(dispatch, officeID).catch((e: Error): void => {
        dispatch(actionCreators.fetchDoctorsError(e.message));
      });
    }
    // tslint:disable-next-line: align
  }, [officeID]);


  useEffect(() => {
    if (practiceGroupID) {
      actions.fetchAppointmentProfiles(dispatch, practiceGroupID).catch((e: Error): void => {
        dispatch(actionCreators.fetchAppointmentProfilesError(e.message));
      });

      actions.fetchPracticeDetails(dispatch, practiceGroupID).catch((e: Error): void => {
        dispatch(actionCreators.fetchPracticeDetailsError(e.message));
      });
    }
    // tslint:disable-next-line: align
  }, [practiceGroupID]);

  if (officeDetailsFetched && doctorsFetched) {
    return (
      <div>
        {error ? (
          <Error />
        ) : (
          <div id="component">
            <Header />
            <DoctorDetails />
            <OfficeDetails />
            <Footer />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Spinner />
      <FooterBlank />
    </>
  );
};

const select = (state: IState): IClinicPageState => {
  const { pmp } = state;
  return {
    error: pmp.officeDetails.error,
    doctorsFetched: pmp.doctors.hasFetched,
    officeDetailsFetched: pmp.officeDetails.hasFetched,
    officeID: pmp.officeDetails.data.id,
    officeShortName: window.location.pathname.split("/")[1],
    appointmentProfilesFetched: pmp.appointmentProfiles.hasFetched,
    practiceGroupID: pmp.officeDetails.data.practice_group_id,
  }
};
export default connect(select)(ClinicPage);
