/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { IMapProps, IMapState, IState } from "types";

import officeDetailsStyles from "styles/officeDetails";

const Map = ({ address, city, lat, lng, state }: IMapProps): JSX.Element => {
  // remove parens in address string
  const addressNoParens = address.replace(/\([^()]*\)/g, "");
  const noWPaddress = `${addressNoParens}, ${city}, ${state}`.replace(/(\r\n|\n|\r| )/gm, "+");

  const src =
    `https://maps.googleapis.com/maps/api/staticmap?center=${lat}, ${lng}` +
    `&size=460x200&zoom=15&scale=2&maptype=roadmap&key=${window.env.GOOGLE_MAPS}` +
    `&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C233+${noWPaddress}`;

  return (
    <div css={officeDetailsStyles.mapContainer}>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={`https://www.google.com/maps/place/${lat}, ${lng}/`}
      >
        <img css={officeDetailsStyles.imgWidth} src={src} alt="Google Map" />
      </a>
    </div>
  );
};

const select = (state: IState): IMapState => ({
  lat:
    state.pmp.officeDetails &&
    state.pmp.officeDetails.data.geolocation &&
    state.pmp.officeDetails.data.geolocation.lat,
  lng:
    state.pmp.officeDetails &&
    state.pmp.officeDetails.data.geolocation &&
    state.pmp.officeDetails.data.geolocation.lng,
});

export default connect(select)(Map);
