import {
  ICustomerDOB,
  ICustomerInfo,
  ICustomerInfoErrors,
  IFlatCustomerInfo,
} from "../../../../types";

// tslint:disable: max-line-length

const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateFormCompletion = (customerInfo: ICustomerInfo): boolean => {
  const flattened: IFlatCustomerInfo = {
    day: customerInfo.dob.date,
    email: customerInfo.email,
    firstName: customerInfo.firstName,
    lastName: customerInfo.lastName,
    month: customerInfo.dob.month,
    phone: customerInfo.phone,
    year: customerInfo.dob.year,
  };
  return Object.values(flattened).every((x: string) => x !== "");
};

const validatePhoneNumber = (email: string): boolean => {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
  return re.test(
    String(email)
      .replace(/\D/g, "")
      .toLowerCase(),
  );
};

export const validateDOBDate = (day: string): boolean => {
  const re = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/;
  return re.test(day);
};

export const validateDOBMonth = (month: string): boolean => {
  const re = /^(0[1-9]|1[012])$/;
  return re.test(month);
};

export const validateDOBYear = (year: string): boolean => {
  const today = new Date();
  const thisYear = today.getFullYear();
  return parseInt(year, 0) > 1900 && parseInt(year, 0) < thisYear;
};

const validateDOB = (dob: ICustomerDOB): boolean => {
  const custDay = validateDOBDate(dob.day);
  const custMonth = validateDOBMonth(dob.month);
  const custYear = validateDOBYear(dob.year);

  return custDay && custMonth && custYear;
};

const validateName = (name: string): boolean => {
  if (name.length >= 2) {
    const validName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
    return validName.test(name);
  }
  return false;
};

const errorObj = (customerInfo: ICustomerInfo): ICustomerInfoErrors => {
  const errors = {
    dob: !validateDOB(customerInfo.dob),
    email: !validateEmail(customerInfo.email),
    firstName: !validateName(customerInfo.firstName),
    lastName: !validateName(customerInfo.lastName),
    phone: !validatePhoneNumber(customerInfo.phone),
  };
  return errors;
};

const boolCheck = (currVal: boolean): boolean => !currVal;

const validateErrorsPresent = (errors: ICustomerInfoErrors): boolean =>
  Object.values(errors).every(boolCheck);

const validateAllFields = (customerInfo: ICustomerInfo): boolean => {
  const validEmail = validateEmail(customerInfo.email);
  const validPhoneNumber = validatePhoneNumber(customerInfo.phone);
  const validDOB = validateDOB(customerInfo.dob);
  const validFirstName = validateName(customerInfo.firstName);
  const validLastName = validateName(customerInfo.lastName);
  const allFieldsComplete = validateFormCompletion(customerInfo);
  return (
    validDOB &&
    validEmail &&
    validPhoneNumber &&
    validFirstName &&
    validLastName &&
    allFieldsComplete
  );
};

export const validators = {
  errorObj,
  validateAllFields,
  validateDOB,
  validateDOBDate,
  validateDOBMonth,
  validateDOBYear,
  validateEmail,
  validateErrorsPresent,
  validateFormCompletion,
  validateName,
  validatePhoneNumber,
};
