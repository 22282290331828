import { css } from "@emotion/core";

// reschedule
const reschedContainer = css`
  width: 100%;
  max-width: 1080px;
  padding: 0 70px 0 30px;
  color: #004a75;
  box-sizing: border-box;
  box-sizing: border-box;
  left: 0;
  width: 100%;
  bottom: 95px;
  margin: 0 auto;
  margin-top: auto;
  @media screen and (max-width: 900px) {
    padding: 0px 35px;
    bottom: 90px;
  }
`;

const phoneHighlight = css`
  color: #00a2e1;
  font-size: 18px;
  font-weight: 600;
`;

const phone = css`
  color: #676f78;
  font-size: 18px;
`;

// confirmation
const container = css`
  max-width: 1080px;
  margin: 0 auto;
  line-height: 1.4em;
  @media screen and (max-width: 900px) {
    padding: 0 15px;
  }
`;

const details = css`
  width: 340px;
  float: left;
  margin-right: 10px;
  @media screen and (max-width: 900px) {
    width: auto;
    margin-right: 0;
  }
`;

const detailsHeader = css`
  border-bottom: #d8d8d8 1px solid;
  display: inline-block;
  width: auto;
  font-size: 16px;
  color: #004a75;
  letter-spacing: 0.5px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 0;
`;

const detailsTextBold = css`
  color: #676f78;
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

const detailsText = css`
  color: #676f78;
  display: block;
  font-size: 16px;
  line-height: 24px;
`;

const detailsTextHighlight = css`
  color: #00a2e1;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: block;
`;

const detailContainer = css`
  padding: 20px 0;
  @media screen and (max-width: 900px) {
    padding: 15px 0;
  }
`;

const confirmationDetailsContainer = css`
  display: flex;
  flex-direction: row;
  padding: 20px 40px 10px;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px 25px;
    width: auto;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

const confirmationDetailsContainerContent = css`
  display: flex;
  width: 65%;
  flex-direction: row;
  padding: 20px 40px 100px;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px 50px;
    width: auto;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

const blueButtonContainer = css`
  display: flex;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px;

  a {
    background: #0089bf;
    border-radius: 1px;
    border: 1px solid #00a2e1;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    min-width: 232px;
    outline: 0;
    padding: 19px 0;
    text-align: center;
    transition: background-color 0.4s;
    vertical-align: middle;
    white-space: nowrap;
    width: 348px;
  }

  @media (max-width: 720px) {
    padding: 20px;
    a {
      width: 100%;
    }
  }
`;

const confirmationText = css`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

// index

const rescheduleContainer = css`
  position: absolute;
  bottom: 200px;
  align-items: center;
  width: 100%;
  @media (max-width: 893px) {
    bottom: 350px;
  }
  @media (max-width: 767px) {
    position: inherit;
  }
`;

export default {
  reschedContainer,
  phoneHighlight,
  phone,
  container,
  details,
  detailsHeader,
  detailsText,
  detailsTextBold,
  detailsTextHighlight,
  detailContainer,
  confirmationDetailsContainer,
  confirmationDetailsContainerContent,
  blueButtonContainer,
  confirmationText,
  rescheduleContainer,
};
