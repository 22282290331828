import { IQueries } from "../../../types";
const getUrlVars = (): IQueries => {
  const vars = {} as any;
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (_m: any, key: string, value: string): any => {
      vars[key] = value;
    },
  );
  return vars;
};

const getDay = (date: string): string => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const newDate = new Date(date);
  return days[newDate.getDay()];
};

export const format = {
  getDay,
  getUrlVars,
};
