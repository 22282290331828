/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import Disclaimer from "./Disclaimer";

const footer = css`
  position: absolute;
  align-items: center;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #004a75;
  margin-top: auto;
  color: white;
  flex: 0 0 50px;
  text-align: center;
  height: 150px;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: 315px;
    text-align: left;
    padding: 30px;
    justify-content: center;
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
  li {
    margin: 0 15px;
    :first-of-type {
      margin: 0 15px 0 0;
    }
    @media screen and (max-width: 900px) {
      padding: 10px 0;
      margin: 0;
      :first-of-type {
        padding: 10px 0;
      }
    }
  }
  a {
    color: white;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    @media screen and (max-width: 900px) {
      font-size: 18px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      font-size: 15px;
    }
  }
`;

const container = css`
  width: inherit;
  display: inline-block;
  max-width: 1015px;
  margin: 0 auto;
`;

const left = css`
  width: 100%;
  display: inline-block;
  @media screen and (max-width: 900px) {
    display: block;
  }
`;

const hr = css`
  border: 0;
  height: 0;
  border-top: 1px solid #005c8a;
  margin: 15px 0 0 0;
  @media screen and (max-width: 900px) {
    margin: 15px 0;
  }
`;

const Footer: React.FC = (): JSX.Element => (
  <footer css={footer}>
    <div css={container}>
      <div css={left}>
        <ul>
          <li>
            <a rel="noopener noreferrer" target="_blank" href={"/disclaimer/privacy-policy"}>
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={"/disclaimer/notice-of-privacy-practices"}
            >
              Notice of Privacy Practices
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href={"/disclaimer/terms-of-use"}>
              Terms of Use
            </a>
          </li>
        </ul>
      </div>
      <hr css={hr} />
      <Disclaimer />
    </div>
  </footer>
);

export default Footer;
