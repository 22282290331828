import { css } from "@emotion/core";

//disclaimer
const disclaimerText = css`
  font-size: 12px;
  line-height: 1.6em;
  margin: 20px 0 40px;
`;

const wpLinks = css`
  font-weight: 600;
  font-size: 12px;
  color: #0089bf;
`;

// form
const form = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
`;

const input = css`
  width: 100%;
  padding: 15px;
  background-color: #f6f8f8;
  border: 1px solid #d1dcda;
  font-size: 1em;
  box-sizing: border-box;
  &::placeholder {
    color: #414b56;
  }
`;

const inputError = css`
  width: 100%;
  padding: 15px;
  background-color: rgb(246, 128, 97, 0.1);
  border: 1px solid #f68061;
  font-size: 1em;
  box-sizing: border-box;
  &::placeholder {
    color: #414b56;
  }
`;

const textContainer = css`
  margin: 20px 0;
`;

const enabledButton = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  padding: 15px 5%;
  margin-right: 15px;
  font-size: 16px !important;
  :hover {
    border: 1px solid #004a75;
  }
  :active {
    background-color: #00a2e1;
    color: #fff;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    margin: 5px 0;
  }
`;

const disabledButton = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgba(0, 162, 225);
  padding: 15px 5%;
  margin-right: 15px;
  pointer-events: none;
  font-size: 16px !important;
  @media screen and (max-width: 900px) {
    width: 100%;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    margin: 5px 0;
  }
`;

const nonFormContainer = css`
  display: flex;
  flex-direction: column;
`;

const buttonContainer = css`
  width: 240px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const dateInputContainer = css`
  width: 100%;
  padding: 13px;
  background-color: #f6f8f8;
  border: 1px solid #d1dcda;
  font-size: 1em;
  box-sizing: border-box;
  input::placeholder {
    color: #414b56;
  }
`;

const dateInputContainerError = css`
  width: 100%;
  padding: 14px;
  background-color: rgb(246, 128, 97, 0.1);
  border: 1px solid #f68061;
  font-size: 1em;
  box-sizing: border-box;
  input::placeholder {
    color: #414b56;
  }
`;

const day = css`
  border: 0;
  outline: 0;
  text-align: center;
  width: 27px;
  margin: 0 5px;
  background-color: transparent;
  font-size: 1em;
`;
const month = css`
  border: 0;
  outline: 0;
  text-align: center;
  width: 27px;
  margin-right: 5px;
  background-color: transparent;
  font-size: 1em;
`;
const year = css`
  border: 0;
  outline: 0;
  text-align: center;
  width: 27px;
  width: 40px;
  background-color: transparent;
  font-size: 1em;
  margin-left: 5px;
`;

const errorText = css`
  margin: 0 0 0 10px;
  color: #f68061;
`;

const formContainer = css`
  width: 100%;
  max-width: 500px;
  display: block;
`;

const label = css`
  margin-top: 10px;
`;

export default {
  label,
  formContainer,
  errorText,
  year,
  month,
  day,
  dateInputContainer,
  dateInputContainerError,
  buttonContainer,
  form,
  input,
  inputError,
  textContainer,
  enabledButton,
  disabledButton,
  nonFormContainer,
  disclaimerText,
  wpLinks,
};
