/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Error from "shared-ui/Error";
import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";
import Spinner from "shared-ui/Spinner";
import { ICancelConfirmationProps, ICancelConfirmationState, IState } from "types";
import { format } from "../tools/formatters";

import cancelStyles from "styles/funnel/cancel";

const CancelConfirmationPage = ({
  appointmentCancelError,
  appointmentCancelPending,
}: ICancelConfirmationProps): JSX.Element => {
  const doctorName = format.getUrlVars()["doc" as string].replace("%20", " ");
  const shortName = format.getUrlVars()["shortName" as string];
  const appointmentDate = format
    .getUrlVars()["date" as string]
    .replace("%2C", ",")
    .split("%20")
    .join(" ");
  const appointmentTime = format
    .getUrlVars()["time" as string]
    .replace("%20", " ")
    .replace("%3A", ":");

  if (appointmentCancelPending) {
    return (
      <Fragment>
        <Header />
        <Spinner />
        <Footer />
      </Fragment>
    );
  }

  if (appointmentCancelError) {
    return <Error />;
  }

  return (
    <Fragment>
      <Header />
      <div id="component">
        <div className="funnel">
          <div css={{ padding: "0 20px" }}>
            <h1>Cancellation confirmed</h1>
            <div>
              <p css={cancelStyles.textContainer}>
                We have cancelled your appointment with Dr. {doctorName} for{" "}
                {format.getDay(appointmentDate)}, {appointmentDate} at {appointmentTime}.
              </p>
            </div>
            <div css={cancelStyles.buttonContainer}>
              <Link css={cancelStyles.button} to={`/${shortName}`}>
                Book a new appointment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

const select = (state: IState): ICancelConfirmationState => ({
  appointmentCancelData: state.customer.appointment.data,
  appointmentCancelError: state.customer.appointment.error,
  appointmentCancelPending: state.customer.appointment.pending,
});

export default connect(select)(CancelConfirmationPage);
