import { applyMiddleware, compose, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { stateActions } from "sessionStorage";
import { ReduxActions, ReduxState } from "types";

import { rootReducer } from "./redux/rootReducer";

const persistedState = stateActions.loadState();
const composeEnhancer: typeof compose =
  // tslint:disable: no-any
  // tslint:disable: no-unsafe-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancer(applyMiddleware(thunk as ThunkMiddleware<ReduxState, ReduxActions>)),
);

export default store;
