import { css } from "@emotion/core";

const timesContainer = css`
  padding: 20px 0;
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
  margin: 0 auto;
  justify-content: space-evenly;
  max-width: 768px;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const button = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  padding: 10px 0px;
  margin: 5px 0;
  max-width: 240px;
  width: 100%;
  font-size: 16px;
  text-align: center;
  :hover {
    border: 1px solid #004a75;
  }
  :active {
    background-color: #00a2e1;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    max-width: 160px;
    width: 100%;
    text-decoration: none;
    border: 1px solid #00a2e1;
    padding: 10px 30px;
    margin: 5px;
    width: 100px;
    text-align: center;
  }
`;

const buttonNoAppt = css`
  background-color: white;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  padding: 12px 70px;
  margin: 10px;
  text-align: center;
  :hover {
    border: 1px solid #004a75;
  }
  :active {
    background-color: #00a2e1;
    color: #fff;
  }
  @media screen and (max-width: 900px) {
    text-decoration: none;
    border: 1px solid #00a2e1;
    padding: 12px 40px;
    margin: 5px;
    text-align: center;
  }
`;

const moreDates = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 5px;
  }

  a {
    -webkit-text-decoration: none;
    text-decoration: none;
    border: 1px solid #00a2e1;
    padding: 12px 75px;
    margin: 10px;
    width: 50px;
    width: 95px;
    text-align: center;
  }
`;

export default {
  timesContainer,
  moreDates,
  buttonNoAppt,
  button,
};
