/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ISingleSelectQuestionProps, IIntakeFormOption } from "types";
import intakeFormStyles from "styles/intakeForm";
import { helpers } from "ui/intake-page/tools/helpers";

const SingleSelectQuestion = ({
  loggedResForQuestionType,
  updateResponses,
  responses,
  questionOptions,
}: ISingleSelectQuestionProps): JSX.Element => {
  return (
    <div css={intakeFormStyles.optionsContainer}>
      {questionOptions.map(
        (option: IIntakeFormOption): JSX.Element => (
          <div key={option.name} css={intakeFormStyles.optionContainer}>
            <button
              css={helpers.toggleOptionStyles(responses, loggedResForQuestionType, option.name)}
              type="button"
              onClick={() => updateResponses(option.name)}
              value={option.name}
            >
              {option.text}
            </button>
          </div>
        ),
      )}
    </div>
  );
};

export default SingleSelectQuestion;
