import { css } from "@emotion/core";

const examContainer = css`
  padding: 30px 0 0;
  @media screen and (max-width: 900px) {
    padding: 30px 0;
  }
`;

const h2 = css`
  line-height: 40px;
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0 0;
`;

const pricing = css`
  font-weight: 600;
  color: #414b56;;
  margin-top: 0;
`;

const buttonContainer = css`
  margin: 40px 0;
  @media screen and (max-width: 900px) {
    margin: 20px 0;
  }
`;

const textContainer = css`
  width: 100%;
  max-width: 500px;
`;

const button = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  padding: 15px 5%;
  margin-right: 15px;
  font-size: 16px;
  :hover {
    border: 1px solid #004a75;
  }
  :active {
    background-color: #00a2e1;
    color: #fff;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px 0;
  }
`;

export default {
  h2,
  pricing,
  buttonContainer,
  textContainer,
  button,
  examContainer,
};
