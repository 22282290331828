/** @jsx jsx */
import { jsx, SerializedStyles } from "@emotion/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import { IDatesProps, ITimeSlotBool, Target } from "types";

import { lineFormatter } from "./tools/formatters";

import datesStyles from "styles/funnel/dates";

const Dates = ({ dispatch, selectedDate, timeSlots }: IDatesProps): JSX.Element => {
  useEffect((): void => {
    const selected = document.querySelector(".times li.selected p#date");
    const target = document.querySelector(".target");
    if (selected && target) {
      lineFormatter(selected as HTMLElement, target as HTMLElement, false);
    }
  });

  const [isFirstLoad, toggleIfFirstLoad] = useState(true);

  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    const targetDate = (e.target as Target).className.split(" ")[0];
    dispatch({ payload: targetDate, type: "date" });
    if (isFirstLoad) {
      toggleIfFirstLoad(false);
    }
  };

  const debounce = (func: () => void, timeParam: number): any => {
    const time = timeParam || 100;
    let timer: NodeJS.Timeout;
    return (event: () => void): void => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(func, time, event);
    };
  };

  const resizeContent = (): void => {
    const selected = document.querySelector(".times li.selected p#date");
    const target = document.querySelector(".target");
    lineFormatter(selected as HTMLElement, target as HTMLElement, false);
  };

  const resizeFunc = (): void => {
    const selected = document.querySelector(".times li.selected p#date");
    const target = document.querySelector(".target");
    if (selected) {
      lineFormatter(selected as HTMLElement, target as HTMLElement, true);
    }
  };
  window.addEventListener("resize", resizeFunc);
  window.addEventListener("resize", debounce(resizeContent, 150));

  const handleSelectedLine = (e: React.MouseEvent<HTMLElement>): void => {
    const target = document.querySelector(".target");
    lineFormatter(e.target as HTMLElement, target as HTMLElement, false);
  };

  const handleColor = (key: string, i: number): SerializedStyles => {
    if (selectedDate === key || (isFirstLoad && i === 0)) {
      return datesStyles.selectedDateStyle;
    }
    if (isFirstLoad) {
      toggleIfFirstLoad(false);
    }
    return datesStyles.date;
  };

  return (
    <div css={datesStyles.container}>
      <ul className="times">
        {(timeSlots || []).map((slot: ITimeSlotBool, i: number) => (
          <li
            key={i}
            className={
              selectedDate === slot.date || (isFirstLoad && i === 0)
                ? `${slot.date} selected`
                : `${slot.date}`
            }
            css={datesStyles.ulLi}
            onClick={(e: React.MouseEvent<HTMLLIElement>): void => {
              handleClick(e);
            }}
          >
            <div css={datesStyles.aTag} className={slot.date}>
              <p id="day" className={slot.date} css={datesStyles.day}>
                {/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent,
                )
                  ? format(slot.date, "ddd")
                  : format(slot.date, "dddd")}
              </p>
              <p
                id="date"
                className={slot.date}
                css={handleColor(slot.date, i)}
                onClick={(e: React.MouseEvent<HTMLElement>): void => {
                  handleSelectedLine(e);
                }}
              >
                {format(slot.date, "M/DD")}
              </p>
            </div>
          </li>
        ))}
        <div css={datesStyles.hrContainer}>
          <span className="target" />
        </div>
      </ul>
    </div>
  );
};

export default Dates;
