/** @jsx jsx */
import { jsx } from "@emotion/core";

import docImg from "shared-ui/icons/document-img.svg";
import { IAcknowledgementQuestionProps } from "types";
import intakeFormStyles from "styles/intakeForm";

const AcknowledgementQuestion = ({
  documentMouseOverText,
  documentUrl,
  footerText,
}: IAcknowledgementQuestionProps): JSX.Element => {
  return (
    <div css={intakeFormStyles.consentContainer}>
      <div css={intakeFormStyles.pillButtonContainer}>
        <img alt="consent-icon" css={intakeFormStyles.consentIcon} src={docImg} />
        <button>
          <a
            css={intakeFormStyles.link}
            rel="noopener noreferrer"
            href={documentUrl}
            target="_blank"
          >
            {documentMouseOverText}
          </a>
        </button>
      </div>
      <div css={intakeFormStyles.footerTextCss}>{footerText}</div>
    </div>
  );
};

export default AcknowledgementQuestion;
