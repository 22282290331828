/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import Logo from "./icons/logo.svg";

const logoContainer = css`
  height: 70px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 900px) {
    height: 55px;
  }

  img {
    width: 260px;
    display: block;
  }

  img {
    @media screen and (max-width: 900px) {
      width: 230px;
      text-align: center;
    }
  }
`;

const IntakeFormHeader: React.FC = (): JSX.Element => (
  <div css={logoContainer}>
    <img src={Logo} alt="logo" />
  </div>
);

export default IntakeFormHeader;
