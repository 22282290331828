/** @jsx jsx */
import { jsx } from "@emotion/core";

import { IIntakeFormCancelledAppointmentPageProps } from "types";
import { Fragment } from "react";
import Footer from "shared-ui/Footer";
import Header from "shared-ui/Header";
import { Link } from "react-router-dom";

import cancelStyles from "styles/funnel/cancel";
import { format } from "../tools/formatters";

const IntakeFormCancelledAppointmentPage = ({message}: IIntakeFormCancelledAppointmentPageProps): JSX.Element => {
  const officeShortName = format.getUrlVars()["shortName" as string];
  const bookAppointmentLink = officeShortName ? `/${officeShortName}/` : `/`;

  return (
    <Fragment>
      <Header />
      <div id="component">
        <div className="funnel">
          <div css={{ padding: "0 20px" }}>
            <h1>This appointment was cancelled</h1>
            <div>
              <p css={cancelStyles.textContainer}>
                {message}
              </p>
            </div>
            <div css={cancelStyles.buttonContainer}>
              <Link css={cancelStyles.button} to={bookAppointmentLink}>
                Book a new appointment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default IntakeFormCancelledAppointmentPage;
