import { combineReducers } from "redux";

import { customerReducer } from "./reducers/customerReducer";
import { funnelReducer } from "./reducers/funnelReducer";
import { pmpReducer } from "./reducers/pmpReducer";
import { intakeFormReducer } from "./reducers/intakeFormReducer";

export const rootReducer = combineReducers({
  customer: customerReducer,
  funnel: funnelReducer,
  intakeForm: intakeFormReducer,
  pmp: pmpReducer,
});
