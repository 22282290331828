import parseResponse from "./response-parser";
import { IIntakeFormSubmitPayload, IIntakeFormQueries } from "types";

const API = {
  async getAllPaymentMethods(): Promise<any> {
    const endpoint = `${window.env.PMP_API}/payment-methods`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getAllInsurancePlans(): Promise<any> {
    const endpoint = `${window.env.PMP_API}/insurance-plans`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getAllOffices(): Promise<any> {
    const endpoint = `${window.env.PMP_API}/offices`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getDoctors({ officeID }: any): Promise<any> {
    const endpoint = `${window.env.PMP_API}/offices/${officeID}/doctors`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getOfficeDetails({ officeShortName }: any): Promise<any> {
    const endpoint = `${window.env.PMP_API}/offices/${officeShortName}`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getPracticeDetails({ practiceGroupID }: any): Promise<any> {
    const endpoint = `${window.env.PMP_API}/practice-groups/${practiceGroupID}?show_insurance_plans&show_payment_methods`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getAppointmentProfiles({ practiceGroupID }: any): Promise<any> {
    const endpoint = `${window.env.PMP_API}/practice-groups/${practiceGroupID}?show_all_appointment_profiles`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getTimeSlots({ officeID, selectedDate, examDuration, dateLimit }: any): Promise<any> {
    const query = `?from=${selectedDate}&days=${dateLimit}&duration=${examDuration}`;
    const endpoint = `${window.env.PMP_API}/offices/${officeID}/open-slots${query}`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async getTimeSlotsBool({ officeID, examDuration, selectedDate, dateLimit }: any): Promise<any> {
    const query = `?from=${selectedDate}&days=${dateLimit}&duration=${examDuration}&no-time-slots`;
    const endpoint = `${window.env.PMP_API}/offices/${officeID}/open-slots${query}`;
    const response = await fetch(endpoint, {
      headers: {},
      method: "GET",
    });
    return parseResponse(response);
  },
  async createAppointment({ officeID, doctorID, postPayload }: any): Promise<Response | object> {
    const endpoint = `${window.env.PMP_API}/offices/${officeID}/doctors/${doctorID}/appointments`;
    const response = await fetch(endpoint, {
      body: JSON.stringify(postPayload),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    const result = await parseResponse(response);
    return result;
  },
  async cancelAppointment({ doctorID, appointmentID, hash }: any): Promise<Response | object> {
    const endpoint = `${window.env.PMP_API}/doctors/${doctorID}/appointments/${appointmentID}`;
    const response = await fetch(endpoint, {
      body: JSON.stringify({ status: "Cancelled" }),
      headers: {
        "Content-Type": "application/json",
        "X-Signature": hash,
      },
      method: "PATCH",
    });

    const result = await parseResponse(response);
    return result;
  },
  async getIntakeForm({ docid, hash, apptid }: IIntakeFormQueries): Promise<Response | object> {
    const endpoint = `${window.env.PMP_API}/doctors/${docid}/appointments/${apptid}/intake-form`;
    const response = await fetch(endpoint, {
      headers: {
        "Content-Type": "application/json",
        "X-Signature": hash,
      },
      method: "GET",
    });
    return parseResponse(response);
  },
  async submitIntakeForm(
    queries: IIntakeFormQueries,
    payload: IIntakeFormSubmitPayload,
  ): Promise<Response | object> {
    const { docid, hash, apptid } = queries;
    const endpoint = `${window.env.PMP_API}/doctors/${docid}/appointments/${apptid}/intake-form-responses`;
    const response = await fetch(endpoint, {
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        "X-Signature": hash,
      },
      method: "POST",
    });
    return parseResponse(response);
  },
};

export default API;
