import { IState } from "./types";

const loadState = (): undefined | IState => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (!serializedState) {
      return undefined;
    }
    sessionStorage.removeItem("state");
    return JSON.parse(serializedState) as IState;
  } catch (err) {
    return undefined;
  }
};

const saveState = (state: IState): void => {
  sessionStorage.removeItem("state");
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

export const stateActions = {
  loadState,
  saveState,
};
