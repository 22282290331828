/** @jsx jsx */
import { jsx } from "@emotion/core";

import { IRescheduleProps } from "types";

import confirmationStyles from "styles/confirmation";

const Reschedule = ({ officePhoneNumber }: IRescheduleProps): JSX.Element => (
  <div css={confirmationStyles.reschedContainer}>
    <h1 style={{ margin: 0, lineHeight: "1em" }}>Need to reschedule?</h1>
    <p style={{ marginBottom: 0 }}>
      Give us a call at{" "}
      {!/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? (
        <span css={confirmationStyles.phone}>{officePhoneNumber}</span>
      ) : (
        <a css={confirmationStyles.phoneHighlight} href={`tel:+${officePhoneNumber}`}>
          {officePhoneNumber}
        </a>
      )}{" "}
      and we’ll happily assist you with any changes.
    </p>
  </div>
);

export default Reschedule;
