/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { IDoctorDetailsProps, IDoctorDetailsState, IState } from "types";
import officeDetailsStyles from "styles/officeDetails";

import Map from "./Map";

const DoctorDetails = ({
  appointmentProfiles,
  doctorCount,
  doctorSpecialty,
  officeAddress,
  officeCity,
  officeState,
  practiceGroupName,
}: IDoctorDetailsProps): JSX.Element => (
  <div css={officeDetailsStyles.doctorDetailsContainer}>
    <div css={officeDetailsStyles.details}>
      <h1 css={officeDetailsStyles.h1}>
        {practiceGroupName}
      </h1>
      {doctorCount > 1 ? (
        "Independent Optometry Practice"
      ) : (
        <p css={officeDetailsStyles.docDetails}>{doctorSpecialty}</p>
      )}
      <p css={officeDetailsStyles.docDetails}>
        {officeState ? `${officeCity}, ${officeState}` : `${officeCity}`}
      </p>
      <div
        css={
          appointmentProfiles.length <= 0
            ? officeDetailsStyles.noApptContainer
            : officeDetailsStyles.buttonContainer
        }
      >
        {appointmentProfiles.length <= 0 ? (
          <div css={officeDetailsStyles.noAppt}>
            <span>This clinic is not taking any appointments</span>
          </div>
        ) : (
          <Link to={`/${window.location.pathname.split("/")[1]}/exams-type-page`}>
            <button css={officeDetailsStyles.button}>Book Exam</button>
          </Link>
        )}
      </div>
    </div>
    <div css={officeDetailsStyles.map}>
      <Map address={officeAddress} city={officeCity} state={officeState} />
    </div>
  </div>
);
const select = (state: IState): IDoctorDetailsState => {
  const doctors = state.pmp.doctors.data;
  const doctor = doctors && doctors[0];
  const officeDetails = state.pmp.officeDetails;
  return {
    appointmentProfiles: state.pmp.appointmentProfiles.data,
    doctorCount: doctors.length,
    doctorSpecialty: doctor && doctor.specialty,
    officeAddress: officeDetails.data.address,
    officeCity: officeDetails.data.city,
    officeState: officeDetails.data.state,
    practiceGroupName: officeDetails.data.practice_group_name,
  };
};

export default connect(select)(DoctorDetails);
