import { IAction, IFunnelState } from "../../types";
import { CLEAR_OFFICE_DETAILS_FETCHES } from "../actions/action-creators";

const initState = {
  calendarHidden: true,
  error: undefined,
  slotIndex: 0,
  slotUpdate: false,
  step: "",
};

export const funnelReducer = (state: IFunnelState = initState, action: IAction): IFunnelState => {
  const { type, payload } = action;
  switch (type) {
    case "calendar-hidden":
      return { ...state, calendarHidden: payload as boolean };
    case "slot-update":
      return { ...state, slotUpdate: payload as boolean };
    case "slot-index-update":
      return { ...state, slotIndex: payload as number };
    case "step":
      return { ...state, step: payload as string };
    case CLEAR_OFFICE_DETAILS_FETCHES:
      return initState;
    default:
      return state;
  }
};
