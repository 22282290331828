/** @jsx jsx */
import { css } from "@emotion/core";

// terms of use / notice of privacy
const container = css`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 900px) {
    padding: 20px;
  }
`;

const unordered = css`
  list-style-type: disc;
  li {
    margin-left: 5%;
  }
`;

const h2 = css`
  margin-top: 40px;
`;

const section = css`
  margin-bottom: 5px;
  &::before {
    width: 1.2em;
    text-align: center;
    display: inline-block;
  }
`;

const intro = css`
  margin-top: 50px;
  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }
`;

// privacy policy
const numbered = css`
  counter-reset: item;
`;

const italicizedSubSection = css`
  h3 {
    font-weight: 600;
    font-style: italic;
  }
`;

const subSection = css`
  h3 {
    font-weight: 600;
  }
`;

export default {
  container,
  unordered,
  h2,
  section,
  intro,
  numbered,
  italicizedSubSection,
  subSection,
};
