import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actionCreators } from "redux/actions/action-creators";

import { actions } from "redux/actions/actions";
import Error from "shared-ui/Error";
import Footer from "shared-ui/Footer";
import FunnelHeader from "shared-ui/FunnelHeader";
import ProgressBar from "shared-ui/ProgressBar";
import Spinner from "shared-ui/Spinner";
import { IExamTimeProps, IExamTimeState, IOpenSlotsBoolArr, IState } from "types";

import Calendar from "./components/Calendar";
import Dates from "./components/Dates";
import Times from "./components/Times";

// tslint:disable: align
// tslint:disable: strict-comparisons

const ExamsTimePage = ({
  calendarHidden,
  dispatch,
  error,
  examDuration,
  pending,
  selectedDate,
  step,
  timeSlots,
  officeID,
  openSlots,
  slotIndex,
}: IExamTimeProps): JSX.Element => {
  useEffect(() => {
    dispatch({ payload: "4 - exams time", type: "step" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    // only fetch time slots if there are none!    
    if (openSlots.length <= 0) {
      const dateLimit = 60;
      actions
        .fetchTimeSlots(dispatch, selectedDate, examDuration, officeID, dateLimit)
        .catch((e: Error): void => {
          dispatch(actionCreators.fetchTimeSlotsError(e.message));
        });
      actions
        .fetchTimeSlotsBool(dispatch, selectedDate, examDuration, officeID, dateLimit)
        .catch((e: Error): void => {
          dispatch(actionCreators.fetchTimeSlotsBoolError(e.message));
        });
    }
    // tslint:disable-next-line: align
  }, []);

  const toggleCal = (): void => {
    dispatch({ payload: !calendarHidden, type: "calendar-hidden" });
  };


  const getActiveAppointmentSubarray = (
    appointments: IOpenSlotsBoolArr,
    activeDate: string,
    activeDateIndex: number = 0,
    daysLimit = 5,
  ) => {

    const nextFiveDaysFromActiveDate = appointments.slice(
      activeDateIndex,
      activeDateIndex + daysLimit
    );
    const isDateWithinFirstFive = nextFiveDaysFromActiveDate.some(
      appointment => activeDate === appointment.date
    );

    if (isDateWithinFirstFive) {
      return nextFiveDaysFromActiveDate;
    }

    const currentActiveDateIndex = appointments.findIndex(
      appointment => appointment.date === activeDate
    );

    dispatch({ payload: currentActiveDateIndex, type: "slot-index-update" });

    return appointments.slice(
      currentActiveDateIndex,
      currentActiveDateIndex + daysLimit
    );
  }

  if (error || !Boolean(officeID && officeID !== 0 && (examDuration && examDuration !== 0))) {
    return <Error />;
  }

  return (
    <>
      <ProgressBar progress={"60%"} />
      <FunnelHeader step={step} />
      <div id="component">
        <div className="funnel">
          <h1 style={{ marginBottom: 0 }}>Select an exam time</h1>
          {!pending && !error && (
            <p id="cal-text" onClick={toggleCal}>
              {!calendarHidden ? "Close calendar" : "View full calendar"}
            </p>
          )}
          {!calendarHidden && (
            <Calendar
              openSlots={openSlots}
              calendarHidden={calendarHidden}
              dispatch={dispatch}
              selectedDate={selectedDate}
            />
          )}
          <div>
            {!pending && !error ? (
              <>
                <Dates
                  dispatch={dispatch}
                  pending={pending}
                  selectedDate={selectedDate}
                  timeSlots={getActiveAppointmentSubarray(openSlots, selectedDate, slotIndex, 5)}
                />
                <Times
                  calendarHidden={calendarHidden}
                  dispatch={dispatch}
                  pending={pending}
                  selectedDate={selectedDate}
                  timeSlots={timeSlots}
                  openSlots={openSlots}
                />
              </>
            ) : (
              <>
                <Spinner page="exams-time-page" />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const select = (state: IState): IExamTimeState => ({
  calendarHidden: state.funnel.calendarHidden,
  error: state.pmp.timeSlots.error || state.pmp.openSlots.error ? true : false,
  examDuration: state.customer.exam.examDuration,
  officeID: state.pmp.officeDetails.data.id,
  openSlots: state.pmp.openSlots.data,
  pending: state.pmp.timeSlots.pending,
  selectedDate: state.customer.examTime.date,
  slotIndex: state.funnel.slotIndex,
  step: state.funnel.step,
  timeSlots: state.pmp.timeSlots.data,
  timeSlotsUpdate: state.funnel.slotUpdate,
});
export default connect(select)(ExamsTimePage);
