/** @jsx jsx */
import { jsx } from "@emotion/core";
import { format } from "date-fns";

import { useEffect } from "react";
import { IConfirmationPageProps } from "types";
import Footer from "shared-ui/Footer";
import Spinner from "shared-ui/Spinner";
import Header from "shared-ui/Header";
import Reschedule from "ui/confirmation-page/components/Reschedule";
import { actionCreators } from "redux/actions/action-creators";
import { actions } from "redux/actions/actions";

import intakeFormStyles from "styles/intakeForm";

const convertToRegularTime = (time: string): string => {
  let timeValue = "";
  // Time comes in as "17:00:00"
  const splitTime = time.split(":");
  const hours = Number(splitTime[0]);
  const minutes = Number(splitTime[1]);

  if (hours > 0 && hours <= 12) {
    timeValue = `${hours}`;
  } else if (hours > 12) {
    timeValue = `${hours - 12}`;
  } else if (hours === 0) {
    timeValue = "12";
  }
  timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`; // get minutes
  timeValue += hours >= 12 ? "pm" : "am"; // get AM/PM
  return timeValue;
};

// timestampDate example: '2020-05-26T12:00:00'
const getStringApptDateTime = (timestampDate: string): string => {
  if (!timestampDate) return "";
  const date = timestampDate.split("T")[0];
  const time = timestampDate.split("T")[1];
  const examDateString = format(date, "MMMM D, YYYY");
  return `${examDateString} at ${convertToRegularTime(time)}`;
};

const ConfirmationPage = ({
  dispatch,
  successResponse,
  isLoading,
  error,
  payload,
  queries,
  intakeFormSubmitted,
}: IConfirmationPageProps): JSX.Element => {
  useEffect(() => {
    if (!intakeFormSubmitted) {
      actions.submitIntakeForm(dispatch, queries, payload).catch((e: Error): void => {
        dispatch(actionCreators.submitIntakeFormError(e.message));
      });
    }
  }, []);

  if (intakeFormSubmitted && !isLoading) {
    const { exam_location, exam_datetime } = successResponse;
    const cxNumber = "888.492.7297";
    return (
      <div css={intakeFormStyles.block}>
        <Header />
        <div css={intakeFormStyles.container}>
          <h1 css={intakeFormStyles.containerH1}>Thank you! You&#39;re all set.</h1>
          <div css={intakeFormStyles.textContainer}>
            <p css={intakeFormStyles.containerText}>
              {`We look forward to seeing you at ${exam_location} on ${getStringApptDateTime(
                exam_datetime,
              )}`}
              <br />
              <br />
              {`If you have any other questions, please reach out to us at ${cxNumber}.`}
            </p>
          </div>
        </div>
        <div css={intakeFormStyles.rescheduleContainer}>
          <Reschedule officePhoneNumber={cxNumber} />
        </div>
        <Footer />
      </div>
    );
  }
  if (error && !isLoading) {
    return (
      <div css={intakeFormStyles.block}>
        <Header />
        <div css={intakeFormStyles.container}>
          <h1>Oops! Something went wrong ...</h1>
          <div css={intakeFormStyles.textContainer}>
            <p>{error}</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div css={intakeFormStyles.block}>
      <Header />
      <Spinner />
      <Footer />
    </div>
  );
};

export default ConfirmationPage;
