// tslint:disable: ordered-imports
// tslint:disable: no-import-side-effect
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";

import "react-day-picker/lib/style.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { stateActions } from "sessionStorage";
import CancelConfirmationPage from "ui/cancel-page/cancel-confirmation-page";
import CancelPromptPage from "ui/cancel-page/cancel-prompt-page";
import ClinicPage from "ui/clinic-page";
import ConfirmationPage from "ui/confirmation-page";
import ExamsTimePage from "ui/funnel/exams-time-page/ExamsTimePage";
import ExamsTypePage from "ui/funnel/exams-type-page/ExamsTypePage";
import PersonalInfoPage from "ui/funnel/personal-info-page/PersonalInfoPage";
import NoticeOfPrivacy from "ui/disclaimers-page/notice-of-privacy-page";
import TermsOfUse from "ui/disclaimers-page/terms-of-use-page";
import PrivacyPolicy from "ui/disclaimers-page/privacy-policy-page";
import IntakeFormPage from "ui/intake-page";

import App from "./App";
import { browserHistory } from "./browserHistory";
import store from "./configureStore";
import "./index.css";

store.subscribe(() => {
  stateActions.saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route exact path="/" component={App} />
      <Route exact path="/disclaimer/notice-of-privacy-practices" component={NoticeOfPrivacy} />
      <Route exact path="/disclaimer/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/disclaimer/terms-of-use" component={TermsOfUse} />
      <Route exact path="/:shortName" component={ClinicPage} />
      <Switch>
        <Route path="/:shortName/exams-type-page" component={ExamsTypePage} />
        <Route path="/:shortName/exams-time-page" component={ExamsTimePage} />
        <Route path="/:shortName/personal-info-page" component={PersonalInfoPage} />
        <Route path="/:shortName/confirmation-page" component={ConfirmationPage} />
      </Switch>
      <Route exact path="/intake-form/start" component={IntakeFormPage} />
      <Route exact path="/cancel/:appointmentID" component={CancelPromptPage} />
      <Route exact path="/cancel/:appointmentID/confirm" component={CancelConfirmationPage} />
    </Router>
  </Provider>,
  document.getElementById("root"),
);
