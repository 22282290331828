import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actionCreators } from "redux/actions/action-creators";
import { actions } from "redux/actions/actions";
import IntakeIntroPage from "ui/intake-page/intake-intro-page/IntakeIntroPage";
import IntakeConfirmationPage from "ui/intake-page/intake-confirmation-page/IntakeConfirmationPage";
import IntakeFormCancelledAppointmentPage from "./cancelled-appointment/IntakeFormCancelledAppointmentPage";

import IntakeQuestionPage from "ui/intake-page/intake-question-page/IntakeQuestionPage";
import Error from "shared-ui/Error";

import { format } from "./tools/formatters";

import { IState, IIntakeFormSelectState, IIntakeFormPageProps } from "types";

const IntakeFormPage = ({ dispatch, intakeForm }: IIntakeFormPageProps): any => {
  const docid = format.getUrlVars()["docid" as string];
  const apptid = format.getUrlVars()["apptid" as string];
  const hash = format.getUrlVars()["hash" as string];

  useEffect(() => {
    actions.fetchIntakeForm(dispatch, docid, apptid, hash).catch((e: Error): void => {
      dispatch(actionCreators.fetchIntakeFormError(e.message));
    });
  }, []);

  const {
    intakeFormComplete,
    isLoading,
    cancelledMessage,
    currentIndex,
    currentStep,
    error,
    showPrompt,
    responses,
    prevMultiSelectState,
    steps,
    intakeFormSubmitted,
    successResponse,
    start,
    end,
    duration,
    parameters,
  } = intakeForm;
  const showIntro = currentIndex === 0;
  const showFormQuestions = !intakeFormComplete && !showIntro;
  const userFirstName = parameters ? parameters.first_name : "";
  const numberOfQuestions = (steps && steps.length) || 0;
  const payload = { start, end, duration, responses };

  if (!docid || !apptid || !hash) {
    return <Error />;
  }

  if (cancelledMessage) {
    return (<IntakeFormCancelledAppointmentPage message={cancelledMessage}/>);
  }

  if (showIntro) {
    return (
      <IntakeIntroPage
        dispatch={dispatch}
        userFirstName={userFirstName}
        isLoading={isLoading}
        intro={currentStep}
        error={error}
      />
    );
  }

  if (showFormQuestions) {
    return (
      <IntakeQuestionPage
        dispatch={dispatch}
        question={currentStep}
        loggedResponses={responses}
        prevMultiSelectState={prevMultiSelectState}
        showPrevPrompt={showPrompt}
        currentIndex={currentIndex}
        numberOfQuestions={numberOfQuestions}
      />
    );
  }

  if (intakeFormComplete) {
    return (
      <IntakeConfirmationPage
        dispatch={dispatch}
        isLoading={isLoading}
        intakeFormSubmitted={intakeFormSubmitted}
        payload={payload}
        queries={{ docid, apptid, hash }}
        successResponse={successResponse}
        error={error}
      />
    );
  }
};

const select = (state: IState): IIntakeFormSelectState => ({
  intakeForm: state.intakeForm,
});

export default connect(select)(IntakeFormPage);
