import { css } from "@emotion/core";

// office details
const officeDetailsContainer = css`
  padding: 0 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  p {
    line-height: 2em;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    position: relative;
    padding: 0 0 0 20px;
  }
`;

const detailsHeader = css`
  margin: 40px 0 20px 0;
  border-bottom: #d8d8d8 1px solid;
  display: inline-block;
  width: auto;
  font-size: 16px;
  color: #004a75;
  letter-spacing: 0.5px;
  font-weight: 400;
  @media screen and (max-width: 900px) {
    margin: 0 0 20px 0;
  }
`;

const officeDetails = css`
  overflow: hidden;
  width: 50%;
  &:first-of-type {
    margin: 0 30px 0 0;
  }
  @media screen and (max-width: 900px) {
    &:first-of-type {
      float: left;
    }
    width: 100%;
    padding: 0;
  }
`;

const address = css`
  padding: 0;
  margin: 0;
  color: #00a2e1;
  &:nth-of-type(2) {
    margin: 0;
  }
`;

const phone = css`
  margin: 0;
  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

const phoneMobile = css`
  margin: 10px 0 25px 0;
  color: #00a2e1;
  font-size: 18px;
  font-weight: 600;
  display: block;
  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

const item = css`
  margin: 0 40px 0 0;
`;

const section = css`
  @media screen and (max-width: 900px) {
    padding: 15px 0;
  }
`;

// doctor details
const doctorDetailsContainer = css`
  padding: 40px 30px 30px 30px;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    position: relative;
    padding: 0;
    margin-top: 40px;
  }
`;

const details = css`
  overflow: hidden;
  width: 50%;
  margin: auto;
  display: table-cell;
  vertical-align: middle;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding-bottom: 20px;
    margin: 0;
  }
`;

const map = css`
  overflow: hidden;
  width: 50%;
  @media screen and (max-width: 900px) {
    width: auto;
  }
`;

const docDetails = css`
  margin: 0;
  @media screen and (max-width: 900px) {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
`;

const buttonContainer = css`
  margin: 20px 0 0 0;
  width: 240px;
  @media screen and (max-width: 900px) {
    margin: 0 0 20px 0;
    width: 90%;
  }
`;

const noApptContainer = css`
  margin: 20px 0 0 0;
  width: 320px;
  @media screen and (max-width: 900px) {
    margin: 0 0 20px 0;
    width: 90%;
  }
`;

const button = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  margin-right: 15px;
  width: 100%;
  padding: 15px 0;
  font-size: 16px;
  :hover {
    border: 1px solid #004a75;
  }
  :active {
    background-color: #00a2e1;
    color: #fff;
  }
  @media screen and (max-width: 900px) {
    padding: 12px 5%;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    margin: 5px 0 0 0;
  }
`;

const noAppt = css`
  margin: 10px 0;
  width: 100%;
  padding: 9px;
  background-color: rgb(246, 128, 97, 0.1);
  border: 1px solid #f68061;
  font-size: 1em;
  box-sizing: border-box;
  text-align: center;
  span {
    color: #f68061;
  }
`;

const h1 = css`
  margin-top: 0;
  line-height: 0.8em;
`;

// map
const mapContainer = css`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-evenly;
  overflow: hidden;
  height: 200px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    position: relative;
  }
`;

const imgWidth = css`
  width: 460px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

//footer
const footer = css`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #476d57;
  padding: 20px;
  color: white;
  text-align: center;
  z-index: 100;
`;

export default {
  footer,
  imgWidth,
  mapContainer,
  officeDetailsContainer,
  detailsHeader,
  officeDetails,
  address,
  phone,
  phoneMobile,
  item,
  section,
  doctorDetailsContainer,
  details,
  map,
  docDetails,
  buttonContainer,
  noApptContainer,
  button,
  noAppt,
  h1,
};
