/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Link } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";

const errorContainer = css`
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  @media screen and (max-width: 900px) {
    h1 {
      font-size: 28px;
      margin: 40px;
    }
  }
`;

const button = css`
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #00a2e1;
  padding: 12px;
  margin: 20px;
`;

const ErrorBooking = (): JSX.Element => (
  <div>
    <Header />
    <div css={errorContainer}>
      <h1>Someone has already selected your appointment time.</h1>
      <Link css={button} to={`/${window.location.pathname.split("/")[1]}/exams-time-page`}>
        Please select a different time slot
      </Link>
    </div>
    <Footer />
  </div>
);

export default ErrorBooking;
