import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actionCreators, CLEAR_OFFICE_DETAILS_FETCHES } from "redux/actions/action-creators";
import { actions } from "redux/actions/actions";

import FooterBlank from "shared-ui/FooterBlank";
import Spinner from "shared-ui/Spinner";
import { IAppProps, IAppState, IState } from "types";
import Homepage from "ui/homepage";

const App = ({ dispatch, officesFetched }: IAppProps): JSX.Element => {
  useEffect(() => {
    if (!officesFetched) {
      actions.fetchAllOffices(dispatch).catch((e: Error): void => {
        dispatch(actionCreators.fetchAllOfficesError(e.message));
      });
    }
    dispatch({ type: CLEAR_OFFICE_DETAILS_FETCHES });
    // tslint:disable-next-line: align
  }, []);

  return (
    <div>
      {officesFetched ? (
        <Homepage />
      ) : (
        <>
          <Spinner />
          <FooterBlank />
        </>
      )}
    </div>
  );
};

const select = (state: IState): IAppState => ({
  officesFetched: state.pmp.allOffices.hasFetched,
});

export default connect(select)(App);
