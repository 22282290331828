/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import { ISpinnerProps } from "../types";

const spinnerContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Spinner = ({ page }: ISpinnerProps): JSX.Element => {
  const nonTimesPage = "35vh auto 20vh";
  const timesPage = "20vh auto";

  const spinnerCSS = css`
    font-size: 10px;
    margin: ${page === "exams-time-page" ? timesPage : nonTimesPage};
    text-indent: -9999em;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #004a75;
    background: -moz-linear-gradient(left, #004a75 10%, rgba(0, 101, 0, 0) 42%);
    background: -webkit-linear-gradient(left, #004a75 10%, rgba(0, 101, 0, 0) 42%);
    background: -o-linear-gradient(left, #004a75 10%, rgba(0, 101, 0, 0) 42%);
    background: -ms-linear-gradient(left, #004a75 10%, rgba(0, 101, 0, 0) 42%);
    background: linear-gradient(to right, #004a75 10%, rgba(0, 101, 0, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    &:before {
      width: 50%;
      height: 50%;
      background: #004a75;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
    }
    &:after {
      background: #fff;
      width: 85%;
      height: 85%;
      border-radius: 55%;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    @-webkit-keyframes load3 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load3 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  `;
  return (
    <div css={spinnerContainer}>
      <span css={spinnerCSS} />
    </div>
  );
};

export default Spinner;
