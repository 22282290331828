/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import { IYesNoQuestionProps, IIntakeFormOption } from "types";
import { PUNCTUATION } from "redux/reducers/intakeFormReducer";
import intakeFormStyles from "styles/intakeForm";
import { helpers } from "ui/intake-page/tools/helpers";

const YesNoQuestion = ({
  prompt,
  updateResponses,
  responses,
  showPrompt,
  togglePrompt,
  loggedResForQuestionType,
}: IYesNoQuestionProps): JSX.Element => {
  const YES = "YES";
  const NO = "NO";
  const yesNoPrompt = prompt ? prompt.prompt : null;
  const [currentOption, setCurrentOption] = useState<IIntakeFormOption | null>(null);
  const yesNoOptions = [{ name: YES, text: "Yes" }, { name: NO, text: "No" }];
  return (
    <div css={intakeFormStyles.optionsContainer}>
      {yesNoOptions.map(option => (
        <div key={option.name} css={intakeFormStyles.optionContainer}>
          <button
            css={helpers.toggleOptionStyles(responses, loggedResForQuestionType, option.name)}
            type="button"
            onClick={() => {
              updateResponses(option.name);
              if (option.name === YES && yesNoPrompt) {
                togglePrompt(true);
                setCurrentOption(option);
              } else {
                togglePrompt(false);
              }
            }}
            value={option.name}
          >
            {option.text}
          </button>
        </div>
      ))}
      {showPrompt && (
        <div css={intakeFormStyles.optionTextContainer}>
          <span>{yesNoPrompt}</span>
          <textarea
            onChange={e =>
              updateResponses(`${(currentOption as IIntakeFormOption).name}: ${e.target.value}`)
            }
          >
            {loggedResForQuestionType ? loggedResForQuestionType.split(PUNCTUATION)[1] : ""}
          </textarea>
        </div>
      )}
    </div>
  );
};

export default YesNoQuestion;
