import React from "react";
import { Link } from "react-router-dom";

import { ICloseButtonProps } from "../types";

const BackButton = ({ src }: ICloseButtonProps): JSX.Element => (
  <Link to={`/${window.location.pathname.split("/")[1]}`}>
    <button>
      <img src={src} alt={src} />
    </button>
  </Link>
);

export default BackButton;
