import { css } from "@emotion/core";

const date = css`
  padding: 5px 0;
  color: #00a2e1;
  font-weight: 600;
  margin: 0 auto;
`;

const selectedDateStyle = css`
  padding: 5px 0;
  color: #004a75;
  font-weight: 600;
  margin: 0 auto;
`;

const day = css`
  margin: 0;
`;

const container = css`
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  max-width: 768px;
`;

const ulLi = css`
  display: inline;
  text-align: center;
  padding: 0 15px;
  :first-of-type {
    padding-left: 0;
  }
  :last-of-type {
    padding-right: 0;
  }
`;

const aTag = css`
  display: inline-block;
  width: 14.5%;
  padding: 0.75rem 0;
  margin: 0;
  text-decoration: none;
  color: #333;
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    width: 10%;
  }
`;

const hrContainer = css`
  margin: 0 auto;
  width: 100%;
`;

export default {
  date,
  selectedDateStyle,
  day,
  container,
  ulLi,
  aTag,
  hrContainer,
};
